import React from "react";
import PieChart from "./PieChart";
import "./index.scss";

export default function ThirdSection(props) {
  return (
    <div className="second-main">
      <div className="second-section">
        <div className="second-section__right second-section-card">
          <div className="second-section-cart">
            <PieChart data={props?.data}/>
          </div>
        </div>
      </div>
    </div>
  );
}
