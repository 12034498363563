import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./index.css";
import TablePagination from "@mui/material/TablePagination";
import AxiosBase from "../../../../networkRequest/axiosBase";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";

// main function

const TransactionList = (props) => {
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [spinner, setSpinner] = React.useState(false);
  const [state, setState] = React.useState({
    right: false,
    data: {},
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    setSpinner(true);
    AxiosBase.get(
      `/admin/payment/transaction?pageNo=` +
        page +
        `&rowsPerPage=` +
        rowsPerPage
    ).then((response) => {
      setData(response.data.data?.items || []);
      if (response.data.data?.items.length == response.data.data?.count) {
        setCount(page * rowsPerPage + response.data?.data.count + 1);
      } else {
        setCount(page * rowsPerPage + response.data?.data.count);
      }
      setSpinner(false);
    });
  }, [page, rowsPerPage]);

  if (spinner) {
    return (
      <div className="no_data_found loader_data_tbl">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div style={{ width: "100%" }}>
        <div className="">
          <TableContainer className="table_container order-table">
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row">
                <TableRow style={{ background: "none" }}>
                  <TableCell>ID</TableCell>
                  <TableCell>Order ID</TableCell>
                  <TableCell>method</TableCell>
                  <TableCell>Fees</TableCell>
                  <TableCell>Tax</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>VPA</TableCell>
                  <TableCell>Status</TableCell>
                  {/* <TableCell>Description</TableCell> */}
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="data_tbl_body_row">
                {data.map((row, index) => (
                  <TableRow key={row?.id} style={{ background: "none" }}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>
                      {row?.notes?.order_id
                        ? row?.notes?.order_id
                        : row?.description &&
                          row?.description.includes(
                            "payment againt ClothiQ order id :"
                          )
                        ? row?.description.replace(
                            "payment againt ClothiQ order id :",
                            ""
                          )
                        : "NA"}
                    </TableCell>
                    <TableCell>{row.method || "NA"}</TableCell>
                    <TableCell>{(row.fees / 100 || 0).toFixed(2)}</TableCell>
                    <TableCell>{(row.tax / 100 || 0).toFixed(2)}</TableCell>
                    <TableCell>{(row.amount / 100 || 0).toFixed(2)}</TableCell>
                    <TableCell>{row.vpa || "NA"}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    {/* <TableCell>{row.description}</TableCell> */}
                    <TableCell>
                      {moment(new Date(row.created_at * 1000)).format(
                        "DD MMM YYYY hh:mm A"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className="table_pagination"
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default TransactionList;
