import React from "react";
import CustomerCount from "./CustomerCount";
import "./index.scss";

export default function SecondSection(props) {
  return (
    <div className="second-main">
      <div className="second-section">
        <div className="second-section__full second-section-card">
          <h6>
            Customer Vs Month
            <span className="second-section-card__hint">(Qty)</span>
          </h6>
          <div className="second-section-cart">
            <CustomerCount data={props?.data} filter={props.filter} />
          </div>
        </div>
        {/* <div className="second-section__right second-section-card"></div> */}
      </div>
    </div>
  );
}
