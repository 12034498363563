import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import * as getLabels from "../utils";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import "./index.scss";

const getOptions = (times = null, width = "100%") => {
  let timesData = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ];
  if (times) {
    timesData = times;
  }

  let data = {
    chart: {
      type: "bar",
      height: 350,
    },
    colors: ["#D14DFF", "#38EDCC", "#0F79DB"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: timesData,
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
    markers: {
      size: 4,
      strokeColor: "#fff",
      strokeWidth: 3,
      strokeOpacity: 1,
      fillOpacity: 1,
      hover: {
        size: 6,
      },
    },
  };

  if (times == "Custom") {
    data = {
      ...data,
      xaxis: {
        type: "datetime",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          offsetX: 14,
          offsetY: -5,
        },
        tooltip: {
          enabled: true,
        },
      },
      grid: {
        padding: {
          left: -5,
          right: 5,
        },
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
    };
  }
  return data;
};

export default function OrderAmount(props) {
  const [options, setOptions] = useState(getOptions());
  const [series, setSeries] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let data;
    setLoading(true);
    let width = "100%";
    if (props.filter.dateRange == "Today") {
      data = getToday();
    }
    if (props.filter.dateRange == "Yesterday") {
      data = getYesterday();
    }
    if (props.filter.dateRange == "This Week") {
      data = getThisWeek();
    }
    if (props.filter.dateRange == "This Month") {
      data = getThisMonth();
    }
    if (props.filter.dateRange == "This Year") {
      data = getThisYear();
    }
    if (props.filter.dateRange == "Custom") {
      data = getCustom();
    }
    setOptions(getOptions(data.times, width));
    setSeries([{ name: "Amount", data: data.total_amount || [] }]);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [props.data]);

  const getCustom = () => {
    const credit_card = [];
    const debit_card = [];
    const total_amount = [];
    props.data?.map((res) => {
      credit_card.push([new Date(res._id).getTime(), res.credit_card]);
      debit_card.push([new Date(res._id).getTime(), res.debit_card]);
      total_amount.push([new Date(res._id).getTime(), res.amount]);
    });
    return {
      credit_card,
      debit_card,
      total_amount,
      times: "Custom",
    };
  };

  const getToday = () => {
    const total_amount = new Array(Number(24)).fill(0);
    props.data?.map((res) => {
      const title = res._id.split("-")[2].split(":")[0];
      total_amount[Number(title)] += res.amount;
    });
    return {
      total_amount,
      times: getLabels.getDayLabel(),
    };
  };

  const getYesterday = () => {
    const total_amount = new Array(Number(24)).fill(0);
    props.data?.map((res) => {
      const title = res._id.split("-")[2].split(":")[0];
      total_amount[Number(title)] += res.amount;
    });
    return {
      total_amount,
      times: getLabels.getDayLabel(),
    };
  };

  const getThisWeek = () => {
    const total_amount = [0, 0, 0, 0, 0, 0, 0];
    props.data?.map((res) => {
      total_amount[moment(res._id).isoWeekday() - 1] += res.amount;
    });
    return {
      total_amount,
      times: getLabels.getWeeklyLabel(),
    };
  };

  const getThisMonth = () => {
    const total_amount = new Array(
      Number(moment().endOf("month").format("DD"))
    ).fill(0);
    props.data?.map((res) => {
      total_amount[Number(moment(res._id).format("DD")) - 1] += res.amount;
    });
    return {
      total_amount,
      times: getLabels.getMonthlyLabel(),
    };
  };

  const getThisYear = () => {
    const total_amount = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    props.data?.map((res) => {
      total_amount[Number(res._id.split("-")[1]) - 1] += res.amount;
    });
    return {
      total_amount,
      times: getLabels.getYearlyLabel(),
    };
  };

  if (!props.data || !series) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div id="chart">
      {!loading && (
        <Chart options={options} series={series} type="area" height={350} />
      )}
    </div>
  );
}
