import React from "react";
import { useState, useEffect } from "react";
import "./index.css";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import AxiosBase from "../../../../networkRequest/axiosBase";
import UploadDoc from "./upload-doc/UploadDoc";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    // width: "520px",
    height: "4px",
  },
});

const CssTextArea = styled(TextareaAutosize)({
  "input#comment-text": {
    width: "100%",
    height: "4.3rem",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Detail = (props) => {
  const { SelectedLaundryServices } = props;
  const [laundryServices, setLaundryServices] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [openUpload, setOpenUpload] = React.useState(false);
  const [uploadDoc, setUploadDoc] = React.useState(false);
  const [fileType, setFileType] = React.useState("");
  const [recommendedIndex, setRecommendedIndex] = React.useState("");
  const [recommendedNotIndex, setRecommendedNotIndex] = React.useState("");

  useEffect(() => {
    setLaundryServices(SelectedLaundryServices);
  }, [props.SelectedLaundryServices]);

  const hiddenFileInput = React.useRef(null);

  const addLaundryServices = async () => {
    if (!laundryServices?.name || laundryServices?.name == "") {
      return toast("name is required", {
        type: "error",
        autoClose: 2000,
      });
    }
    if (laundryServices?.file) {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const formData = new FormData();
      formData.append("file", laundryServices?.file);
      const res = await AxiosBase.post(`/admin/uploadImages`, formData, config);
      laundryServices.file = null;
      laundryServices.image_url = res?.data?.file_name;
    }
    if (laundryServices?.recommended) {
      await Promise.all(
        laundryServices?.recommended.map(async (item, index) => {
          if (item?.file) {
            const config = {
              headers: { "content-type": "multipart/form-data" },
            };
            const formData = new FormData();
            formData.append("file", item?.file);
            const res = await AxiosBase.post(
              `/admin/uploadImages`,
              formData,
              config
            );
            delete laundryServices.recommended[index].file;
            laundryServices.recommended[index].url = res?.data?.file_name;
          }
        })
      );
    }
    if (laundryServices?.recommended_not) {
      await Promise.all(
        laundryServices?.recommended_not.map(async (item, index) => {
          if (item?.file) {
            const config = {
              headers: { "content-type": "multipart/form-data" },
            };
            const formData = new FormData();
            formData.append("file", item?.file);
            const res = await AxiosBase.post(
              `/admin/uploadImages`,
              formData,
              config
            );
            delete laundryServices.recommended_not[index].file;
            laundryServices.recommended_not[index].url = res?.data?.file_name;
          }
        })
      );
    }
    props.onSubmitLaundryServices(laundryServices);
  };

  const onChangeInput = (e, key, type = "String") => {
    let val = e.target.value;
    if (type == "Int") {
      val = val.toString().replaceAll("+", "");
      let val1 = val.match(/[0-9]/);
      if (val1) {
        val = isNaN(parseInt(val)) ? "" : parseInt(val);
      } else if (!val) {
        val = "";
      } else {
        return;
      }
    } else {
      val = val.replace(/^\s+/, "").replace("  ", " ");
    }
    laundryServices[key] = val;
    setLaundryServices({
      ...laundryServices,
    });
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const handleFileClick = (t) => {
    setUploadDoc(true);
    setOpenUpload(true);
    setFileType(t);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenUpload(false);
    setFileType("");
  };

  const handleUploadDocClose = () => {
    setOpenUpload(false);
    setUploadDoc(false);
  };

  const handleData = (file) => {
    setOpenUpload(false);
    setUploadDoc(false);
    if (fileType == "image") {
      laundryServices.file = file;
      laundryServices.image_url = URL.createObjectURL(file);
    } else if (fileType == "recommended") {
      if (recommendedIndex > -1) {
        laundryServices.recommended[recommendedIndex].file = file;
        laundryServices.recommended[recommendedIndex].url =
          URL.createObjectURL(file);
      }
    } else if (fileType == "recommended_not") {
      if (recommendedIndex > -1) {
        laundryServices.recommended_not[recommendedNotIndex].file = file;
        laundryServices.recommended_not[recommendedNotIndex].url =
          URL.createObjectURL(file);
      }
    }

    setLaundryServices({
      ...laundryServices,
    });
  };

  return (
    <>
      <div style={{ width: "100%" }} className="kyc_detail_ctrl">
        <div className="">
          <div>
            <div className="add_menu_role_star">
              <p>Name:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec laundryServices-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"name_"}
                  placeholder="name"
                  variant="outlined"
                  aria-readonly
                  value={laundryServices.name}
                  onChange={(e) => {
                    onChangeInput(e, "name");
                  }}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>Background Color:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec laundryServices-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"background_color_"}
                  placeholder="background color"
                  variant="outlined"
                  aria-readonly
                  value={laundryServices.background_color}
                  onChange={(e) => {
                    onChangeInput(e, "background_color");
                  }}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>Code:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec laundryServices-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"code_"}
                  placeholder="code"
                  variant="outlined"
                  aria-readonly
                  value={laundryServices.code}
                  onChange={(e) => {
                    onChangeInput(e, "code");
                  }}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>Description:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec laundryServices-field"
              >
                <CssTextArea
                  id="text"
                  variant="outlined"
                  sx={{
                    width: "96%",
                    borderColor: "#c4c4c4",
                    p: "10px",
                    borderRadius: "5px",
                    fontSize: "14px",
                  }}
                  placeholder="description"
                  value={laundryServices?.description}
                  onChange={(e) => onChangeInput(e, "description", "String")}
                  minRows={3}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>Amount(Per Kg):</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec laundryServices-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"amount_"}
                  placeholder="amount"
                  variant="outlined"
                  aria-readonly
                  value={laundryServices.amount}
                  onChange={(e) => {
                    onChangeInput(e, "amount");
                  }}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>Amount(Per Piece):</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec laundryServices-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"piece_amount_"}
                  placeholder="piece amount"
                  variant="outlined"
                  aria-readonly
                  value={laundryServices.piece_amount}
                  onChange={(e) => {
                    onChangeInput(e, "piece_amount");
                  }}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>Discount(%):</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec laundryServices-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"amount_"}
                  placeholder="amount"
                  variant="outlined"
                  aria-readonly
                  value={laundryServices.discount}
                  onChange={(e) => {
                    onChangeInput(e, "discount");
                  }}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec laundryServices-field"
              >
                <div className="">
                  <Button
                    className="laundryServices_upload_btn"
                    onClick={() => {
                      handleFileClick("image");
                    }}
                  >
                    <i className="fa fa-upload"></i>&nbsp;&nbsp; Upload Image
                  </Button>
                </div>
                {laundryServices.image_url && (
                  <img
                    src={
                      laundryServices?.file
                        ? laundryServices.image_url
                        : `${process.env.REACT_APP_API_URL}/api/image/show/${laundryServices.image_url}`
                    }
                    style={{ width: "25%", "margin-top": "15px" }}
                  />
                )}
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec laundryServices-field"
              >
                <div className="">
                  <label>Best Recommended For:</label>
                  <Button
                    className="laundryServices_upload_btn"
                    style={{ float: "right" }}
                    onClick={() => {
                      // handleFileClick("thumbnail");
                      laundryServices.recommended.push({
                        title: "",
                        url: "",
                        file: null,
                      });
                      setLaundryServices({
                        ...laundryServices,
                      });
                    }}
                  >
                    <i className="fa fa-plus"></i>&nbsp;&nbsp; Add New
                  </Button>
                </div>
                <div>
                  <table
                    className="table table-responsive"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Image</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {laundryServices?.recommended &&
                        laundryServices.recommended.map((item, index) => {
                          return (
                            <tr>
                              <td>
                                <CssTextField
                                  className=""
                                  id="new-menu"
                                  name={"Title"}
                                  placeholder="Title"
                                  variant="outlined"
                                  aria-readonly
                                  fullWidth
                                  value={item.title}
                                  onChange={(e) => {
                                    laundryServices.recommended[index].title =
                                      e.target.value;
                                    setLaundryServices({
                                      ...laundryServices,
                                    });
                                  }}
                                />
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {item.url ? (
                                  <img
                                    src={
                                      item?.file
                                        ? item.url
                                        : `${process.env.REACT_APP_API_URL}/api/image/show/${item.url}`
                                    }
                                    style={{
                                      width: "100px",
                                      "margin-left": "15px",
                                    }}
                                  />
                                ) : (
                                  <Button
                                    className="laundryServices_upload_btn"
                                    onClick={() => {
                                      setRecommendedIndex(index);
                                      handleFileClick("recommended");
                                    }}
                                  >
                                    <i className="fa fa-upload"></i>&nbsp;&nbsp;
                                    Upload Image
                                  </Button>
                                )}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <Button
                                  className="laundryServices_upload_btn"
                                  onClick={() => {
                                    // handleFileClick("thumbnail");
                                    laundryServices.recommended.splice(
                                      index,
                                      1
                                    );
                                    setLaundryServices({
                                      ...laundryServices,
                                    });
                                  }}
                                >
                                  <i className="fa fa-minus"></i>
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec laundryServices-field"
              >
                <div className="">
                  <label>Not For Recommended:</label>
                  <Button
                    className="laundryServices_upload_btn"
                    style={{ float: "right" }}
                    onClick={() => {
                      // handleFileClick("thumbnail");
                      laundryServices.recommended_not.push({
                        title: "",
                        url: "",
                        file: null,
                      });
                      setLaundryServices({
                        ...laundryServices,
                      });
                    }}
                  >
                    <i className="fa fa-plus"></i>&nbsp;&nbsp; Add New
                  </Button>
                </div>
                <div>
                  <table
                    className="table table-responsive"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Image</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {laundryServices?.recommended_not &&
                        laundryServices.recommended_not.map((item, index) => {
                          return (
                            <tr>
                              <td>
                                <CssTextField
                                  className=""
                                  id="new-menu"
                                  name={"Title"}
                                  placeholder="Title"
                                  variant="outlined"
                                  aria-readonly
                                  fullWidth
                                  value={item.title}
                                  onChange={(e) => {
                                    laundryServices.recommended_not[
                                      index
                                    ].title = e.target.value;
                                    setLaundryServices({
                                      ...laundryServices,
                                    });
                                  }}
                                />
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {item.url ? (
                                  <img
                                    src={
                                      item?.file
                                        ? item.url
                                        : `${process.env.REACT_APP_API_URL}/api/image/show/${item.url}`
                                    }
                                    style={{
                                      width: "100px",
                                      "margin-left": "15px",
                                    }}
                                  />
                                ) : (
                                  <Button
                                    className="laundryServices_upload_btn"
                                    onClick={() => {
                                      setRecommendedNotIndex(index);
                                      handleFileClick("recommended_not");
                                    }}
                                  >
                                    <i className="fa fa-upload"></i>&nbsp;&nbsp;
                                    Upload Image
                                  </Button>
                                )}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <Button
                                  className="laundryServices_upload_btn"
                                  onClick={() => {
                                    // handleFileClick("thumbnail");
                                    laundryServices.recommended_not.splice(
                                      index,
                                      1
                                    );
                                    setLaundryServices({
                                      ...laundryServices,
                                    });
                                  }}
                                >
                                  <i className="fa fa-minus"></i>
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </Grid>
            </div>
          </div>
        </div>
        <div>
          <div className="laundryServices_edit_drawer_btn_sec">
            <Button
              className="drawer_merchant_cancel_btn"
              variant="contained"
              onClick={props.toggleDrawer("right", false)}
            >
              Cancel
            </Button>

            <Button
              className="drawer_merchant_save_btn"
              variant="contained"
              style={{ marginLeft: "5px" }}
              onClick={() => {
                addLaundryServices();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>

      <div>
        <BootstrapDialog
          className="order_detail_refund_popup"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openUpload}
        >
          <DialogContent>
            <Typography
              className="order_detail_refund_popup_title"
              gutterBottom
            >
              {
                <>
                  <UploadDoc
                    open={true}
                    handleDocumentClose={handleUploadDocClose}
                    handleData={handleData}
                    type={laundryServices.type}
                  />
                </>
              }
            </Typography>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default Detail;
