import React, { useEffect } from "react";
import "./index.scss";
import { Box, Button } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";
import AxiosBase from "../../../networkRequest/axiosBase";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "0px solid #fff",
  boxShadow: 24,
  pt: 1,
  px: 4,
  pb: 3,
  width: 800,
  height: 450,
  overflow: "auto",
};

const MenuDetail = (props) => {
  const { selectedRole, menu, setMenu, handleClose } = props;
  const [loading1, setLoading1] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);

  const getMenuByRole = async () => {
    try {
      setLoading1(true);
      console.log("sfsdsdsgs");
      const res = await AxiosBase.get(
        "/admin/menu/get-permission/" + selectedRole
      );
      menu.map((item, index) => {
        if (!menu[index]?.menu_permissions) {
          menu[index].menu_permissions = {};
        }
        menu[index].menu_permissions =
          res?.data?.data?.menu_permissions[item?.name] || {};
      });
      setMenu([...menu]);
      setLoading1(false);
    } catch (err) {
      console.log(err);
      setLoading1(false);
      toast(err?.response?.data?.message || "Something went wrong", {
        type: "error",
        autoClose: 5000,
      });
    }
  };
  useEffect(() => {
    console.log(menu, selectedRole);
    getMenuByRole();
  }, []);

  const handleMenuAllClick = (val, index, permissions) => {
    permissions.map((x) => {
      menu[index].menu_permissions[x] = val;
    });
    setMenu([...menu]);
  };

  const handleMenuClick = (val, index, type) => {
    if (!menu[index]?.menu_permissions) {
      menu[index].menu_permissions = {};
    }
    menu[index].menu_permissions[type] = val;
    setMenu([...menu]);
  };

  const onSubmitPermission = async () => {
    try {
      if (!selectedRole) {
        return toast("Invalid role selected", {
          type: "error",
          autoClose: 5000,
        });
      }
      setSpinner(true);
      const res = await AxiosBase.post("/admin/menu/save-permission", {
        role_id: selectedRole,
        menu,
      });
      toast("Menu permission saved successfully!", {
        type: "success",
        autoClose: 5000,
      });
      handleClose();
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
      toast(err?.response?.data?.message || "Something went wrong", {
        type: "error",
        autoClose: 5000,
      });
    }
  };

  return (
    <Box sx={{ ...style }}>
      <h2 id="parent-modal-title">Menu Permissions</h2>
      <TableContainer
        className={
          !loading1
            ? "table_container"
            : "table_container table_container_nodata"
        }
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead className="">
            <TableRow>
              <TableCell>Menu</TableCell>
              <TableCell>View</TableCell>
              <TableCell>Add</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="data_tbl_body_row ">
            {!loading1 &&
              menu.map((item, index) => {
                return (
                  <TableRow
                    style={{ background: "none" }}
                    className="role_list_data_body"
                  >
                    <TableCell>
                      <Checkbox
                        checked={
                          item?.menu_permissions?.VIEW ||
                          item?.menu_permissions?.ADD ||
                          item?.menu_permissions?.EDIT ||
                          item?.menu_permissions?.DELETE
                        }
                        onClick={(e) => {
                          handleMenuAllClick(
                            e.target.checked,
                            index,
                            item?.permissions
                          );
                        }}
                        style={{ marginTop: "-4px" }}
                      />
                      {item?.name}
                    </TableCell>
                    <TableCell>
                      {item?.permissions.includes("VIEW") && (
                        <Checkbox
                          checked={item?.menu_permissions?.VIEW || false}
                          onClick={(e) => {
                            handleMenuClick(e.target.checked, index, "VIEW");
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {item?.permissions.includes("ADD") && (
                        <Checkbox
                          checked={item?.menu_permissions?.ADD || false}
                          onClick={(e) => {
                            handleMenuClick(e.target.checked, index, "ADD");
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {item?.permissions.includes("EDIT") && (
                        <Checkbox
                          checked={item?.menu_permissions?.EDIT || false}
                          onClick={(e) => {
                            handleMenuClick(e.target.checked, index, "EDIT");
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {item?.permissions.includes("DELETE") && (
                        <Checkbox
                          checked={item?.menu_permissions?.DELETE || false}
                          onClick={(e) => {
                            handleMenuClick(e.target.checked, index, "DELETE");
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      {loading1 ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress />
        </div>
      ) : (
        <Grid container>
          <Button
            variant="contained"
            className="menu-save-btn"
            onClick={onSubmitPermission}
            disabled={spinner}
            style={{
              marginLeft: "auto",
              marginTop: "10px"
            }}
          >
            Save Permissions
          </Button>
        </Grid>
      )}
    </Box>
  );
};

export default MenuDetail;
