import React, { useState, useEffect } from "react";
import { Box, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import "./index.css";
import TablePagination from "@mui/material/TablePagination";
import AxiosBase from "../../../../networkRequest/axiosBase";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import OrderDetails from "../../../orderDetail";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import moment from "moment";

const Div = styled("div")(unstable_styleFunctionSx);

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "2px",
    backgroundColor: "#FEFEFE",
  },
});

// main function

const SettlementList = (props) => {
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [search, setSearch] = React.useState();
  const [state, setState] = React.useState({
    right: false,
    data: {},
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    AxiosBase.get(`/admin/payment/settlement`).then((response) => {
      setData(response.data?.data?.data || []);
      setCount(response.data?.data?.data.length);
      props.setSettlementAmount(response.data?.data?.totalAmount/100 || 0);
    });
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const toggleDrawer = (anchor, open, row) => (event) => {
    setState({ ...state, [anchor]: open, data: row });
  };

  const list = (anchor) => (
    <OrderDetails
      anchor={anchor}
      data={state?.data}
      menu_permissions={props?.menu_permissions}
    />
  );

  return (
    <>
      <div style={{ width: "100%" }}>
        <div className="">
          <TableContainer className="table_container order-table">
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row">
                <TableRow style={{ background: "none" }}>
                  <TableCell>Settlement ID</TableCell>
                  <TableCell>Entity</TableCell>
                  <TableCell>UTR</TableCell>
                  <TableCell>Fees</TableCell>
                  <TableCell>Tax</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="data_tbl_body_row">
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={row?._id} style={{ background: "none" }}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.entity || "NA"}</TableCell>
                      <TableCell>{row.utr || "NA"}</TableCell>
                      <TableCell>{row.fees / 100}</TableCell>
                      <TableCell>{row.tax / 100}</TableCell>
                      <TableCell>{row.amount / 100}</TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>
                        {moment(new Date(row.created_at * 1000)).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className="table_pagination"
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>

      {["right"].map((anchor) => (
        <SwipeableDrawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
          onOpen={toggleDrawer(anchor, true)}
        >
          {list(anchor)}
        </SwipeableDrawer>
      ))}
    </>
  );
};

export default SettlementList;
