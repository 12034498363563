import React from "react";
import Topheader from "../../universal-components/header/topHeader";
import { Route, Switch } from "react-router-dom";
import ConfigTabMain from "../../Components/config";

const ConfigurationMain = (props) => {
  if (!props?.menu_permissions?.VIEW) {
    return (
      <>
        <Topheader />
        <div className="no-access-section">
          <div style={{ height: "25vh" }}></div>
          <img
            src="/images/icon/access-denied.jpg"
            alt=""
            style={{ width: "20%" }}
          />
          <br />
          <label className="gr">
            You currently does not have access to this page
          </label>
          <br />
          <label className="gr">Please try again latter</label>
        </div>
      </>
    );
  }
  return (
    <>
      <div style={{ padding: "15px", borderRadius: "5rem", width: "98%" }}>
        <Topheader />
        <Switch>
          <Route
            exact
            path="/config"
            component={(e) => {
              return <ConfigTabMain {...props} />;
            }}
          />
        </Switch>
      </div>
    </>
  );
};

export default ConfigurationMain;
