import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import * as getLabels from "../utils";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import "./index.scss";

const getOptions = (times = null) => {
  return {
    chart: {
      type: "pie",
    },
    labels: ["Cash", "DEDUCTED_FROM_WALLET", "ONLINE"], // Labels for each slice
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
};

export default function PieChart(props) {
  const [options, setOptions] = useState(getOptions());
  const [series, setSeries] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOptions(getOptions());
    setSeries(props.data);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [props.data]);

  if (!props.data || !series) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div id="chart">
      <h6>Collected Amount</h6>
      {!loading && (
        <Chart options={options} series={series} type="pie" height={350} />
      )}
    </div>
  );
}
