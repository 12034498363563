import React, { useState, useEffect } from "react";
import { Box, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import "./index.css";
import TablePagination from "@mui/material/TablePagination";
import AxiosBase from "../../../networkRequest/axiosBase";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import domtoimage from "dom-to-image";
import Barcode from "react-barcode";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

let options = {
  width: 1,
  height: 60,
  format: "CODE128",
  displayValue: true,
  font: "monospace",
};

const Div = styled("div")(unstable_styleFunctionSx);

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "2px",
    backgroundColor: "#FEFEFE",
  },
});

const CssTextArea = styled(TextareaAutosize)({
  "input#comment-text": {
    width: "100%",
    height: "4.3rem",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// main function

const TagList = (props) => {
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [search, setSearch] = React.useState("");
  const [spinner, setSpinner] = useState(false);
  const [warehouse, setWarehouse] = useState([]);
  const [selected, setSelected] = useState({ qr: "", code: "" });
  const [state, setState] = React.useState({
    right: false,
  });
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const [formData, setFormData] = React.useState({
    warehouse_id: "",
    quantity: "",
  });

  useEffect(() => {
    AxiosBase.get(`/admin/warehouse`).then((response) => {
      setWarehouse(response.data.data);
    });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const getData = () => {
    setSpinner(true);
    AxiosBase.get(
      `/admin/tag?pageNo=` +
        page +
        `&rowsPerPage=` +
        rowsPerPage +
        `&sortBy=number&sortOrder=asc&search=` +
        search
    ).then((response) => {
      setData(response.data.data);
      setCount(response.data.count);
      setSpinner(false);
    });
  };

  useEffect(() => {
    getData();
  }, [page, rowsPerPage, count, search]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const toggleDrawer =
    (anchor, open, row = null) =>
    (event) => {
      setState({ ...state, [anchor]: open });
    };

  const addQRCode = () => {
    if (!formData?.warehouse_id) {
      return toast("Please select warehouse", {
        type: "error",
        autoClose: 2000,
      });
    }
    if (!formData?.quantity || formData?.quantity == 0) {
      return toast("Please select warehouse", {
        type: "error",
        autoClose: 2000,
      });
    }
    AxiosBase.post("/admin/tag", formData)
      .then((res) => {
        if (res?.status == 200) {
          setSpinner(false);
          setFormData({
            warehouse_id: "",
            quantity: "",
          });
          setState({ ...state, ["right"]: false });
          getData();
          return toast("QR Code added successfully.", {
            type: "success",
            autoClose: 2000,
          });
        } else {
          setSpinner(false);
          return toast(JSON.stringify(res?.response?.data), {
            type: "error",
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        setSpinner(false);
        return toast(
          error?.response?.data?.message || "Something went wrong!",
          {
            type: "error",
            autoClose: 2000,
          }
        );
      });
  };

  const getQRCode = (code) => {
    if (!code) {
      return toast("Invalid code", {
        type: "error",
        autoClose: 2000,
      });
    }
    AxiosBase.get(`/admin/tag/${code}/qr-code`)
      .then((res) => {
        if (res?.status == 200) {
          setSelected({
            qr: res?.data?.data,
            code,
          });
          var node = document.getElementById("my-node");

          domtoimage
            .toPng(node)
            .then(function (dataUrl) {
              var link = document.createElement("a");
              link.download = `${code}-QRCODE.png`;
              link.href = dataUrl;
              link.click();
            })
            .catch(function (error) {
              console.error("oops, something went wrong!", error);
            });
        } else {
          return toast(JSON.stringify(res?.response?.data), {
            type: "error",
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        setSpinner(false);
        return toast(
          error?.response?.data?.message || "Something went wrong!",
          {
            type: "error",
            autoClose: 2000,
          }
        );
      });
  };

  const deleteRecord = (row) => {
    setSelectedRecord(row);
    setShowConfirm(true);
  };

  const handleClose = () => {
    setShowConfirm(false);
  };

  const handleDelete = () => {
    setShowConfirm(false);
    setSpinner(true);
    AxiosBase.delete(`/admin/tag/${selectedRecord?._id}`)
      .then((res) => {
        toast("Record deleted successfully", {
          type: "success",
          autoClose: 2000,
        });
        getData();
      })
      .catch((error) => {
        setSpinner(false);
        return toast(
          error?.response?.data?.message || "Something went wrong!",
          {
            type: "error",
            autoClose: 2000,
          }
        );
      });
  };

  const list = (anchor) => (
    <Box
      className="kyc_detail_box"
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 600,
        overflowX: "hidden",
      }}
      role="presentation"
    >
      <List>
        <ListItem className="banner_drower">
          <p>Generate Tags</p>
        </ListItem>
      </List>
      <Divider />
      {
        <>
          <div className="add_menu_role_star" style={{ margin: "10px" }}>
            <p>Warehouse:</p>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="gatway_second_grid_sec offer-field"
            >
              <Select
                name="key1"
                labelId="country-lbl"
                label=""
                id="Warehouse_id"
                value={formData?.warehouse_id || ""}
                style={{ width: "100%" }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    warehouse_id: e.target.value,
                  });
                }}
              >
                {warehouse &&
                  warehouse.map((c) => {
                    return (
                      <MenuItem key={c._id} value={c._id}>
                        {c.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </Grid>

            <p>Quantity:</p>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="gatway_second_grid_sec offer-field"
            >
              <CssTextField
                id="new-menu"
                type="number"
                className="create_merchant_input_feild"
                variant="outlined"
                placeholder="Quantity"
                fullWidth
                inputProps={{
                  maxLength: 15,
                }}
                value={formData?.quantity}
                required
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    quantity: e.target.value,
                  });
                }}
              />
            </Grid>
            <div>
              <div className="offer_edit_drawer_btn_sec">
                <Button
                  className="drawer_merchant_cancel_btn"
                  variant="contained"
                  onClick={toggleDrawer("right", false)}
                >
                  Cancel
                </Button>

                <Button
                  className="drawer_merchant_save_btn"
                  variant="contained"
                  style={{ marginLeft: "5px" }}
                  onClick={() => {
                    addQRCode();
                  }}
                >
                  Add QR Code
                </Button>
              </div>
            </div>
          </div>
        </>
      }
    </Box>
  );

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box>
          <h1 className="merchant_title">Tags</h1>
        </Box>

        <div className="merchant_list_main_div">
          <div className="merchant_list_inner_title_div">
            <div className="merchant_list_search_filter">
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                className="merchant_grid_sec"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  placeholder="search"
                  variant="outlined"
                  fullWidth
                  onKeyUp={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <IconButton className="merchant_search_icon">
                          <img src="/images/icon/search.svg" alt="" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <div className="merchant_list_filter_div">
                <img src="/Filter.svg" alt="" />
              </div>
              {props?.menu_permissions?.ADD && ["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <Link
                    className="margin-auto"
                    onClick={toggleDrawer(anchor, true)}
                  >
                    <Button
                      className="merchant_list_add_new_merchant"
                      variant="contained"
                      startIcon={<AddCircleOutlineOutlinedIcon />}
                    >
                      Add Bag
                    </Button>
                  </Link>
                </React.Fragment>
              ))}
            </div>
            <h3 className="merchant_list_inner_title">Tag List</h3>
          </div>
          {spinner ? (
            <div className="no_data_found loader_data_tbl">
              <CircularProgress style={{ color: "#1058ff" }} />
            </div>
          ) : (
            <>
              <TableContainer className="table_container">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className="data_tbl_head_row">
                    <TableRow style={{ background: "none" }}>
                      <TableCell>Number</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Created At</TableCell>
                      <TableCell>Download</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="data_tbl_body_row">
                    {data.map((row, index) => (
                      <TableRow
                        key={row?._id}
                        style={{ background: "none", height: "50px" }}
                      >
                        <TableCell>{row.code}</TableCell>
                        <TableCell>
                          {row.is_used ? (
                            <span className="pending-status">Used</span>
                          ) : (
                            <span className="reply-status">Free</span>
                          )}
                        </TableCell>
                        <TableCell>
                          {moment(row.createdAt).format("DD-MMM-YYYY HH:MM A")}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            className="merchant_tbl_edit_icon"
                            aria-label="more"
                            id="long-button"
                            aria-controls="long-menu"
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={() => {
                              getQRCode(row.code);
                            }}
                          >
                            <DownloadForOfflineIcon />
                          </IconButton>
                          {!row.is_used && props?.menu_permissions?.DELETE && (
                            <Button
                              className="list-delete-btn"
                              onClick={(e) => {
                                deleteRecord(row);
                              }}
                              variant="contained"
                              title="Detail"
                            >
                              <i className="fa fa-trash"></i>
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className="table_pagination"
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </div>
      </div>

      {["right"].map((anchor) => (
        <SwipeableDrawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
          onOpen={toggleDrawer(anchor, true)}
        >
          {list(anchor)}
        </SwipeableDrawer>
      ))}
      <div
        style={{
          opacity: 0,
          position: "absolute",
          width: "100%",
          top: 0,
          zIndex: -1,
        }}
      >
        <div className="table-qr-section" id="my-node">
          <h4 style={{ color: "#01b49e", fontWeight: "bold" }}>
            Your QR Code And BarCode
          </h4>
          <img className="qr-img" src={selected?.qr} />
          <Barcode {...options} value={selected?.code} />
          <h6 style={{ fontWeight: "bold", margin: 0 }}>Scan Here</h6>
          <p style={{ fontSize: "10px" }}>
            Scan the QR code with your mobile phone and scanner
          </p>
          <div className="footer">
            <span>Scan&nbsp;&#8226;&nbsp;Assign&nbsp;</span>
          </div>
        </div>
      </div>

      <BootstrapDialog
        className="order_detail_refund_popup"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showConfirm}
      >
        <DialogContent>
          <Typography className="order_detail_refund_popup_title" gutterBottom>
            {
              <>
                {" "}
                <p>Confirm Delete</p>
                <span>Are you sure want to delete this Record?</span>
                <div className="order_detail_refund_popup_btn">
                  <Button
                    className="refund_save_btn"
                    variant="contained"
                    autoFocus
                    fullWidth
                    onClick={handleDelete}
                  >
                    Confirm
                  </Button>
                  <Button
                    className="refund_cancel_btn"
                    variant="contained"
                    autoFocus
                    onClick={handleClose}
                    fullWidth
                  >
                    Cancel
                  </Button>
                </div>
              </>
            }
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default TagList;
