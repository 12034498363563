import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { toast } from "react-toastify";
import moment from "moment";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import AxiosBase from "../../../networkRequest/axiosBase";
import "./index.css";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import SalaryList from "./salary";
import Typography from "@mui/material/Typography";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    padding: 9,
  },
});
const CssTextArea = styled(TextareaAutosize)({
  "input#comment-text": {
    width: "100%",
    height: "4.3rem",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function PayrollDetail(props) {
  const { id } = useParams("id");
  const [data, setData] = React.useState({});
  const [salaryData, setSalaryData] = React.useState({});
  const [loading, setLoading] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [salaryDetailForm, setSalaryDetailForm] = useState({
    paid_leaves: "",
    unpaid_leaves: "",
    bonus_amount: "",
  });
  const [downloadLoader, setDownloadLoader] = useState(false);

  useEffect(() => {
    setLoading(true);
    AxiosBase.get(`/admin/user/${id}`).then((response) => {
      setData(response.data.data);
      setLoading(false);
    });
  }, []);

  const generateSalary = () => {
    if (
      salaryDetailForm?.paid_leaves != 0 &&
      (salaryDetailForm?.paid_leaves < 0 || salaryDetailForm?.paid_leaves > 31)
    ) {
      return;
    }
    if (
      salaryDetailForm?.unpaid_leaves != 0 &&
      (salaryDetailForm?.unpaid_leaves < 0 ||
        salaryDetailForm?.unpaid_leaves > 31)
    ) {
      return;
    }
    setSpinner(true);
    AxiosBase.post(`/admin/salary/${id}/generate-salary`, {
      selected_date: selectedDate,
      ...salaryDetailForm,
    })
      .then((response) => {
        toast(`Successfully Generated`, {
          type: "success",
          autoClose: 2000,
        });
        setSpinner(false);
        closeModal();
      })
      .catch((error) => {
        setSpinner(false);
        return toast(
          error?.response?.data?.message || "Something went wrong!",
          {
            type: "error",
            autoClose: 2000,
          }
        );
      });
  };

  const generatePaySlip = () => {
    setDownloadLoader(true);
    AxiosBase.post(`/admin/salary/${id}/generate-payslip`, {
      selected_date: selectedDate,
    })
      .then((response) => {
        const resultByte = response?.data?.pdf?.data;
        var bytes = new Uint8Array(resultByte);
        var blob = new Blob([bytes], { type: "application/pdf" });

        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${response?.data?.label}.pdf`;
        link.click();
        setDownloadLoader(false);
        return toast("Payslip downloaded!", {
          type: "success",
          autoClose: 2000,
        });
      })
      .catch((error) => {
        setDownloadLoader(false);
        return toast(
          error?.response?.data?.message || "Something went wrong!",
          {
            type: "error",
            autoClose: 2000,
          }
        );
      });
  };

  const payManually = () => {
    setSpinner(true);
    AxiosBase.post(`/admin/salary/${id}/pay-manually`, {
      selected_date: selectedDate,
    })
      .then((response) => {
        toast(`Successfully Paid`, {
          type: "success",
          autoClose: 2000,
        });
        handleConfirmClose();
        setSpinner(false);
      })
      .catch((error) => {
        setSpinner(false);
        return toast(
          error?.response?.data?.message || "Something went wrong!",
          {
            type: "error",
            autoClose: 2000,
          }
        );
      });
  };

  const closeModal = (type) => {
    setOpen(false);
  };

  const onChangeInput = (e, key, type = "String") => {
    let val = e.target.value;
    if (type == "Int") {
      val = val.toString().replaceAll("+", "");
      let val1 = val.match(/[0-9]/);
      if (val1) {
        val = isNaN(parseInt(val)) ? "" : parseInt(val);
      } else if (!val) {
        val = "";
      } else {
        return;
      }
    } else if (type == "String") {
      val = val.replace(/^\s+/, "").replace("  ", " ");
    }
    salaryDetailForm[key] = val;
    setSalaryDetailForm({
      ...salaryDetailForm,
    });
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  if (loading) {
    return (
      <div className="no_data_found loader_data_tbl">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <Box>
        <h1 className="merchant_title">Payroll Detail - {data?.name}</h1>
      </Box>
      <br />
      <div className="payroll_main_div">
        <div className="">
          <Grid container spacing={2} className="">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="">
                <List className="payroll_details_drawer_cards_sec">
                  <ListItem>
                    <div className="order_details_drawer_cards_title_div">
                      <p>Actions</p>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Select Month and Year"
                          views={["year", "month"]}
                          minDate={new Date("1900-01-01")}
                          maxDate={new Date()}
                          value={selectedDate}
                          onChange={(newValue) => {
                            setSelectedDate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth size="small" />
                          )}
                          size="small"
                          className="month-year-selector"
                        />
                      </LocalizationProvider>
                    </div>
                  </ListItem>
                  <ListItem>
                    <Button
                      className="crm-btn"
                      variant="contained"
                      startIcon={<CurrencyRupeeIcon />}
                      onClick={() => {
                        setSalaryDetailForm({
                          paid_leaves: "",
                          unpaid_leaves: "",
                        });
                        setOpen(true);
                      }}
                      disabled={spinner || downloadLoader}
                    >
                      Generate Salary
                    </Button>
                    <Button
                      className="crm-btn"
                      variant="contained"
                      startIcon={<HistoryEduIcon />}
                      onClick={() => generatePaySlip()}
                      disabled={spinner || downloadLoader}
                    >
                      {downloadLoader ? "Downloading..." : "Generate Payslip"}
                    </Button>
                    <Button
                      className="crm-btn"
                      variant="contained"
                      startIcon={<CurrencyRupeeIcon />}
                      onClick={() => {
                        setConfirmOpen(true);
                      }}
                      disabled={spinner || downloadLoader}
                    >
                      Pay Manually
                    </Button>
                  </ListItem>
                </List>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="">
                <List className="payroll_details_drawer_cards_sec">
                  <ListItem>
                    <div className="order_details_drawer_cards_title_div">
                      <p>Basic & Documents Details</p>
                    </div>
                  </ListItem>
                  <ListItem>
                    <div className="order_details_drawer_cards_body_inner payroll_details_drawer_cards_body_inner">
                      <div className="card_data">
                        <p>Name</p>
                        <span>{data?.name}</span>
                      </div>
                      <div className="card_data">
                        <p>Email</p>
                        <span>{data?.email_id}</span>
                      </div>
                      <div className="card_data">
                        <p>Mobile No</p>
                        <span>{data?.mobile_no}</span>
                      </div>
                    </div>
                  </ListItem>
                  <ListItem>
                    <div className="order_details_drawer_cards_body_inner payroll_details_drawer_cards_body_inner">
                      <div className="card_data">
                        <p>Joining Date</p>
                        <span>
                          {moment(data?.joining_date).format("YYYY/MM/DD")}
                        </span>
                      </div>
                      <div className="card_data" style={{ width: "300px" }}>
                        <p>Address</p>
                        <span>{data?.address}</span>
                      </div>
                    </div>
                  </ListItem>
                  <ListItem>
                    <div className="order_details_drawer_cards_body_inner payroll_details_drawer_cards_body_inner">
                      <div className="card_data">
                        <p>Aadhar Number</p>
                        <span>{data?.documents?.aadhar_number}</span>
                      </div>
                      <div className="card_data">
                        <p>PAN Number</p>
                        <span>{data?.documents?.pan_number}</span>
                      </div>
                      <div className="card_data">
                        <p>Licence</p>
                        <span>{data?.documents?.license_number}</span>
                      </div>
                    </div>
                  </ListItem>
                </List>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="">
                <List className="payroll_details_drawer_cards_sec">
                  <ListItem>
                    <div className="order_details_drawer_cards_title_div">
                      <p>Salary & Bank Details</p>
                    </div>
                  </ListItem>
                  <ListItem>
                    <div className="order_details_drawer_cards_body_inner payroll_details_drawer_cards_body_inner">
                      <div className="card_data">
                        <p>Basic Salary</p>
                        <span>{data?.salary_detail?.basic.toFixed(2)}</span>
                      </div>
                      <div className="card_data">
                        <p>HRA</p>
                        <span>{data?.salary_detail?.hra.toFixed(2)}</span>
                      </div>
                      <div className="card_data">
                        <p>PF</p>
                        <span>{data?.salary_detail?.pf.toFixed(2)}</span>
                      </div>
                    </div>
                  </ListItem>
                  <ListItem>
                    <div className="order_details_drawer_cards_body_inner payroll_details_drawer_cards_body_inner">
                      <div className="card_data">
                        <p>TDS</p>
                        <span>{data?.salary_detail?.tds.toFixed(2)}</span>
                      </div>
                      <div className="card_data">
                        <p>Total</p>
                        <span>
                          {(
                            Number(data?.salary_detail?.basic) +
                            Number(data?.salary_detail?.hra) +
                            Number(data?.salary_detail?.pf) +
                            Number(data?.salary_detail?.tds)
                          ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </ListItem>
                  <ListItem>
                    <div className="order_details_drawer_cards_body_inner payroll_details_drawer_cards_body_inner">
                      <div className="card_data">
                        <p>Bank</p>
                        <span>{data?.bank_detail?.bank_name}</span>
                      </div>
                      <div className="card_data">
                        <p>Account Number</p>
                        <span>{data?.bank_detail?.account_number}</span>
                      </div>
                      <div className="card_data">
                        <p>IFSC</p>
                        <span>{data?.bank_detail?.ifsc}</span>
                      </div>
                    </div>
                  </ListItem>
                </List>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="">
                <List className="payroll_details_drawer_cards_sec">
                  <ListItem>
                    <div className="order_details_drawer_cards_title_div">
                      <p>{new Date(selectedDate).getFullYear()} Salary List</p>
                    </div>
                  </ListItem>
                  <ListItem>
                    <SalaryList
                      id={id}
                      selectedDate={selectedDate}
                      spinner={spinner}
                    />
                  </ListItem>
                </List>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <BootstrapDialog
        className="payroll-modal"
        onClose={closeModal}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent>
          <div className="bulk-inner">
            <h3>Attendance Detail</h3>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="merchant_grid_sec"
            >
              <CssTextField
                className="payroll-input"
                id="new-menu"
                type="number"
                placeholder="enter paid leave days"
                variant="outlined"
                inputProps={{
                  min: 0,
                  max: 31,
                }}
                value={salaryDetailForm?.paid_leaves}
                onChange={(e) => onChangeInput(e, "paid_leaves", "String")}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="merchant_grid_sec"
            >
              <CssTextField
                className="payroll-input"
                id="new-menu"
                type="number"
                placeholder="enter unpaid leave days"
                variant="outlined"
                inputProps={{
                  min: 0,
                  max: 31,
                }}
                value={salaryDetailForm?.unpaid_leaves}
                onChange={(e) => onChangeInput(e, "unpaid_leaves", "String")}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="merchant_grid_sec"
            >
              <CssTextField
                className="payroll-input"
                id="new-menu"
                type="number"
                placeholder="enter bonus amount"
                variant="outlined"
                inputProps={{
                  min: 0,
                }}
                value={salaryDetailForm?.bonus_amount}
                onChange={(e) => onChangeInput(e, "bonus_amount", "String")}
              />
            </Grid>
            <div className="order_detail_refund_popup_btn">
              <Button
                className="refund_save_btn"
                variant="contained"
                autoFocus
                fullWidth
                onClick={generateSalary}
                disabled={spinner}
              >
                Submit
              </Button>
              <Button
                className="refund_cancel_btn"
                variant="contained"
                autoFocus
                onClick={closeModal}
                fullWidth
                disabled={spinner}
              >
                Cancel
              </Button>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        className="order_detail_refund_popup"
        onClose={handleConfirmClose}
        aria-labelledby="customized-dialog-title"
        open={confirmOpen}
      >
        <DialogContent>
          <Typography className="order_detail_refund_popup_title" gutterBottom>
            {
              <>
                {" "}
                <p>Confirm Payment</p>
                <span>
                  Are you sure want to pay manually salary for this user?
                </span>
                <div className="order_detail_refund_popup_btn">
                  <Button
                    className="refund_save_btn"
                    variant="contained"
                    autoFocus
                    fullWidth
                    onClick={payManually}
                  >
                    Confirm
                  </Button>
                  <Button
                    className="refund_cancel_btn"
                    variant="contained"
                    autoFocus
                    onClick={handleConfirmClose}
                    fullWidth
                  >
                    Cancel
                  </Button>
                </div>
              </>
            }
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export default PayrollDetail;
