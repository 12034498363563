import React, { useState, useEffect } from "react";
import { Box, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import "./index.css";
import TablePagination from "@mui/material/TablePagination";
import AxiosBase from "../../../networkRequest/axiosBase";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OrderDetails from "../../orderDetail";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import moment from "moment";
import Filter from "../../../utils/dateFilter/Filter";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

const allStatus = [
  {
    value: "INITIATE",
    label: "Initiate",
  },
  {
    value: "CANCELLED",
    label: "Cancelled",
  },
  {
    value: "RECEIVED_BY_RIDER_FROM_CUSTOMER",
    label: "Received By Rider From Customer",
  },
  {
    value: "WAITING_RECEIVED_BY_TRANSPORTER_FROM_RIDER",
    label: "Waiting Received By Rider From Customer",
  },
  {
    value: "RECEIVED_BY_TRANSPORTER_FROM_RIDER",
    label: "Received By Transporter From Rider",
  },
  {
    value: "WAITING_RECEIVED_BY_WAREHOUSE",
    label: "Waiting Received By Warehouse",
  },
  {
    value: "RECEIVED_BY_WAREHOUSE",
    label: "Received By Warehouse",
  },
  {
    value: "READY_FOR_SERVICE",
    label: "Ready For Service",
  },
  {
    value: "READY_FOR_RETURN",
    label: "Ready For Return",
  },
  {
    value: "RECEIVED_BY_TRANSPORTER_FROM_WAREHOUSE",
    label: "Received By Transporter From Warehouse",
  },
  {
    value: "WAITING_RECEIVED_BY_RIDER_FROM_TRANSPORTER",
    label: "Waiting Received By Rider From Transporter",
  },
  {
    value: "RECEIVE_BY_RIDER_FROM_TRANSPORTER",
    label: "Received By Rider From Transporter",
  },
  {
    value: "COMPLETED",
    label: "Completed",
  },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "2px",
    backgroundColor: "#FEFEFE",
  },
});

// main function

const OrderList = (props) => {
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [search, setSearch] = React.useState("");
  const [spinner, setSpinner] = useState(false);
  const [warehouse, setWarehouse] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState("all");
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [filter, setFilter] = useState({
    dateRange: "Today",
    from_date: moment().format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    currency: "INR",
    country: "IND",
  });
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });

  const [openModal, setOpen] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const getData = (dateRange, from_date, to_date, currency, country, from = "") => {
    setSpinner(true);
    let ft = [];
    if (selectedWarehouse && selectedWarehouse != "all") {
      ft.push({
        key: "warehouse_id",
        value: selectedWarehouse,
        type: "is",
      });
    }
    if (selectedStatus && selectedStatus != "all") {
      ft.push({
        key: "status",
        value: selectedStatus,
        type: "is",
      });
    }
    AxiosBase.get(
      `/admin/order?pageNo=` +
        page +
        `&rowsPerPage=` +
        rowsPerPage +
        `&sortBy=_id&sortOrder=desc&dateRange=custom&dateFrom=${from_date}&dateTo=${to_date}&dateField=createdAt&search=` +
        search +
        `&filter=` +
        JSON.stringify(ft)
    ).then((response) => {
      setData(response.data.data);
      setCount(response.data.count);
      setSpinner(false);
    });
  };

  useEffect(() => {
    getData(
      filter.dateRange,
      filter.from_date,
      filter.to_date,
      filter.currency,
      filter.country,
      "start"
    );
  }, [page, rowsPerPage, search, selectedWarehouse, selectedStatus]);

  const getAllWarehouse = () => {
    AxiosBase.get(`/admin/warehouse?pageNo=0&rowsPerPage=1000000`).then(
      (response) => {
        setWarehouse(response.data.data);
      }
    );
  };

  useEffect(() => {
    getAllWarehouse();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({
    right: false,
    data: {},
  });
  const open = Boolean(anchorEl);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handledates = (values) => {
    const from_date = moment(values.startDate).format("YYYY-MM-DD");
    const to_date = moment(values.endDate).format("YYYY-MM-DD");
    setFilter({
      ...filter,
      from_date,
      to_date
    })
    getData(
      filter.dateRange,
      from_date,
      to_date,
      filter.currency,
      filter.country
    );
    setDate(values);
  };

  const handleClear = (value) => {
    if (value == "dateRange") {
      setFilter({
        ...filter,
        dateRange: "Today",
        from_date: moment().format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      });
      const filterDate = moment().format("YYYY-MM-DD");
      getData("Today", filterDate, filterDate, filter.currency, filter.country);
      return true;
    }
  };
  const handleFilter = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setDate({
      startDate: "",
      endDate: "",
    });
    if (name == "dateRange") {
      if (value == "Custom") {
        setFilter({
          ...filter,
          dateRange: value,
          from_date: filter.from_date,
          to_date: filter.to_date,
        });
        return true;
      }
      const { from_date, to_date } = getDateRange(value);
      setFilter({
        ...filter,
        dateRange: value,
        from_date,
        to_date,
      });
      getData(value, from_date, to_date, filter.currency, filter.country);      
      return true;
    }
    setFilter({
      ...filter,
      [name]: value,
    });
    return true;
  };

  const getDateRange = (value) => {
    let from_date = moment().format("YYYY-MM-DD");
    let to_date = moment().format("YYYY-MM-DD");
    if (value == "Today") {
      from_date = moment().format("YYYY-MM-DD");
      to_date = moment().format("YYYY-MM-DD");
    } else if (value == "Yesterday") {
      from_date = moment().subtract(1, "day").format("YYYY-MM-DD");
      to_date = moment().subtract(1, "day").format("YYYY-MM-DD");
    } else if (value == "This Week") {
      from_date = moment().startOf("isoWeek").format("YYYY-MM-DD");
      to_date = moment().endOf("isoWeek").format("YYYY-MM-DD");
    } else if (value == "This Month") {
      from_date = moment().startOf("month").format("YYYY-MM-DD");
      to_date = moment().endOf("month").format("YYYY-MM-DD");
    } else if (value == "This Year") {
      from_date = moment().startOf("year").format("YYYY-MM-DD");
      to_date = moment().endOf("year").format("YYYY-MM-DD");
    } else if (value == "Custom") {
      from_date = moment().startOf("year").format("YYYY-MM-DD");
      to_date = moment().endOf("year").format("YYYY-MM-DD");
    }
    return { from_date, to_date };
  };

  const toggleDrawer = (anchor, open, row) => (event) => {
    setState({ ...state, [anchor]: open, data: row });
  };

  const deleteOrder = (row) => {
    setSelectedOrder(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setOpen(false);
    setSpinner(true);
    AxiosBase.delete(`/admin/order/${selectedOrder?._id}/delete`)
      .then((res) => {
        toast("Order deleted successfully", {
          type: "success",
          autoClose: 2000,
        });
        getData(
          filter.dateRange,
          filter.from_date,
          filter.to_date,
          filter.currency,
          filter.country
        );
      })
      .catch((error) => {
        setSpinner(false);
        return toast(
          error?.response?.data?.message || "Something went wrong!",
          {
            type: "error",
            autoClose: 2000,
          }
        );
      });
  };

  const list = (anchor) => (
    <OrderDetails
      anchor={anchor}
      data={state?.data}
      menu_permissions={props?.menu_permissions}
    />
  );

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box>
          <h1 className="merchant_title">Orders</h1>
        </Box>

        <div className="merchant_list_main_div">
          <div className="merchant_list_inner_title_div">
            <div className="merchant_list_search_filter">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="merchant_grid_sec"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  placeholder="search"
                  variant="outlined"
                  fullWidth
                  onKeyUp={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <IconButton className="merchant_search_icon">
                          <img src="/images/icon/search.svg" alt="" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Select
                value={selectedStatus}
                style={{ width: "30%", marginRight: "5px" }}
                onChange={(e) => {
                  setSelectedStatus(e.target.value);
                }}
              >
                <MenuItem key="all" value="all">
                  All Status
                </MenuItem>
                {allStatus.map((w) => {
                  return (
                    <MenuItem key={w.value} value={w.value}>
                      {w.label}
                    </MenuItem>
                  );
                })}
              </Select>
              <Select
                value={selectedWarehouse}
                style={{ width: "30%" }}
                onChange={(e) => {
                  setSelectedWarehouse(e.target.value);
                }}
              >
                <MenuItem key="all" value="all">
                  All Warehouse
                </MenuItem>
                {warehouse.map((w) => {
                  return (
                    <MenuItem key={w._id} value={w._id}>
                      {w.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <Filter
                handleFilter={handleFilter}
                handleClear={handleClear}
                filter={filter}
                handledates={handledates}
              />
            </div>
            <h3 className="merchant_list_inner_title">Order List</h3>
          </div>
          {spinner ? (
            <div className="no_data_found loader_data_tbl">
              <CircularProgress style={{ color: "#1058ff" }} />
            </div>
          ) : (
            <>
              <TableContainer className="table_container all-order-table">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className="data_tbl_head_row">
                    <TableRow style={{ background: "none" }}>
                      <TableCell>Order ID</TableCell>
                      <TableCell>Tag</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Address</TableCell>
                      {/* <TableCell>Quantity</TableCell> */}
                      <TableCell>Amount</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Created At</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="data_tbl_body_row">
                    {data.map((row, index) => (
                      <TableRow key={index} style={{ background: "none" }}>
                        <TableCell>
                          {["right"].map((anchor) => (
                            <React.Fragment key={anchor}>
                              <Link onClick={toggleDrawer(anchor, true, row)}>
                                {row?.order_id}
                              </Link>
                            </React.Fragment>
                          ))}
                        </TableCell>
                        <TableCell>{row.tag_no || "NA"}</TableCell>
                        <TableCell>{row.type}</TableCell>
                        <TableCell>{row.address || "NA"}</TableCell>
                        {/* <TableCell>{row.quantity}</TableCell> */}
                        <TableCell>
                          INR {Number(row.amount || 0).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          <label
                            className={
                              row?.status == "COMPLETED"
                                ? "completed-status"
                                : row?.status == "CANCELLED"
                                ? "cancelled-status"
                                : ""
                            }
                          >
                            {row.status}
                          </label>
                        </TableCell>
                        <TableCell>
                          {moment(row.createdAt).format("DD MMM YYYY hh:mm A")}
                        </TableCell>
                        <TableCell>
                          {props?.menu_permissions?.DELETE && row?.status !== "COMPLETED" && (
                            <Button
                              className="delete_btn"
                              onClick={(e) => {
                                deleteOrder(row);
                              }}
                              variant="contained"
                              title="Detail"
                            >
                              <i className="fa fa-trash"></i>
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className="table_pagination"
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </div>

        {["right"].map((anchor) => (
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        ))}

        <BootstrapDialog
          className="order_detail_refund_popup"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openModal}
        >
          <DialogContent>
            <Typography
              className="order_detail_refund_popup_title"
              gutterBottom
            >
              {
                <>
                  {" "}
                  <p>Confirm Delete</p>
                  <span>Are you sure want to delete this order?</span>
                  <div className="order_detail_refund_popup_btn">
                    <Button
                      className="refund_save_btn"
                      variant="contained"
                      autoFocus
                      fullWidth
                      onClick={handleDelete}
                    >
                      Confirm
                    </Button>
                    <Button
                      className="refund_cancel_btn"
                      variant="contained"
                      autoFocus
                      onClick={handleClose}
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              }
            </Typography>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default OrderList;
