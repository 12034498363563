import axios from "axios";
import { logoutUser, updateToken } from "../redux/actions/auth";
import reduxStore from "../redux/stores";

const AxiosBase = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
});

AxiosBase.interceptors.request.use(
  (req) => {
    if (!req?.headers?.rerequest) {
      const root = JSON.parse(localStorage.getItem("persist:root"));
      const authData = root?.auth ? JSON.parse(root?.auth) : "";
      if (authData?.accessToken) {
        req.headers = {
          Authorization: `Bearer ${authData?.accessToken}`,
        };
      }
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let errorUrl;
AxiosBase.interceptors.response.use(
  (res) => {
    if (res.config.url === errorUrl) {
      errorUrl = "";
    }
    if (res.config.url === `/admin/login`) {
      AxiosBase.defaults.headers = {
        Authorization: `Bearer ${res.data.accessToken}`,
      };
    }
    return res;
  },
  async (error) => {
    if (error?.response?.status === 401) {
      errorUrl = error?.response.config.url;
      const { store } = reduxStore();
      try {
        localStorage.clear();
        store.dispatch(logoutUser("/login", "Login"));
      } catch (err) {
        localStorage.clear();
        store.dispatch(logoutUser("/login", "Login"));
      }
    } else {
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default AxiosBase;
