import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import Resetpassword from "./pages/resetpassword";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import reduxStore from "./redux/stores";
import ProtectedRoute from "./pages/protectedRoute";

import { useEffect, useState } from "react";
import { logoutUser } from "./redux/actions/auth";
import AxiosBase from "./networkRequest/axiosBase";

import LoginMain from "./pages/login";
import WarningMain from "./pages/warning";
import LockedOutMain from "./pages/locked";
import TimeoutMain from "./pages/timeout";

import DashboardMain from "./pages/dashboard";
import CustomersMain from "./pages/customer";
import ConfigurationMain from "./pages/configuration";
import UserMain from "./pages/user";
import MenuMain from "./pages/menu";
import RoleMain from "./pages/role";
import WarehouseMain from "./pages/warehouse";
import OrderMain from "./pages/order";
import VehicleMain from "./pages/vehicle";
import SupportMain from "./pages/support";
import TagMain from "./pages/tag";
import SubscribeMain from "./pages/subscribe";
import TransactionMain from "./pages/transaction";
import ItemMain from "./pages/item";
import SwitchMain from "./pages/switch";
import CrmMain from "./pages/crm";
import AcquirerMain from "./pages/acquirer";
import PayrollMain from "./pages/payroll";

const theme = createTheme({
  typography: {
    fontFamily: "'Whitney', sans-serif",
  },
});

function App() {
  const [loading, setLoading] = useState(true);
  const { store, persistor } = reduxStore();
  const [authAccess, setAuthAccess] = useState({});

  useEffect(() => {
    window.onload = () => {
      checkLogin();
    };
  }, []);

  const checkLogin = async () => {
    try {
      let authData = store.getState();
      // if (authData?.auth?.refreshToken) {
      //   const resp = await AxiosBase.post(`/admin/refresh-login-check`, {
      //     token: authData?.auth?.refreshToken,
      //   });
      // }
      setAuthAccess(authData?.auth?.user_data?.menu_permissions);
      setLoading(false);
    } catch (error) {
      if (
        error?.response?.status === 444 &&
        error.response.data.message === "Second login active"
      ) {
        return store.dispatch(logoutUser("/warning", "Timeout"));
      }
      store.dispatch(logoutUser("/timeout", "Timeout"));
    }
  };

  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <img src={"/loader.gif"} style={{ width: 90 }} />
      </div>
    );
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <div className="App">
            <ToastContainer hideProgressBar={true} autoClose={3000} />
            <Router>
              <Switch>
                <Route exact path="/" render={() => <Redirect to="/login" />} />
                <Route path="/login" component={LoginMain} />
                <Route path="/warning" component={WarningMain} />
                <Route path="/locked" component={LockedOutMain} />
                <Route path="/timeout" component={TimeoutMain} />

                {/* <Route path="/reset" component={Resetpassword} /> */}
                <ProtectedRoute
                  path="/dashboard"
                  component={(e) => {
                    return (
                      <DashboardMain
                        menu_permissions={authAccess?.Dashboard || {}}
                      />
                    );
                  }}
                />
                <ProtectedRoute
                  path="/customer"
                  component={(e) => {
                    return (
                      <CustomersMain
                        menu_permissions={authAccess?.Customer || {}}
                      />
                    );
                  }}
                />
                <ProtectedRoute
                  path="/config"
                  component={(e) => {
                    return (
                      <ConfigurationMain
                        menu_permissions={authAccess?.MasterConfig || {}}
                      />
                    );
                  }}
                />
                <ProtectedRoute
                  path="/user"
                  component={(e) => {
                    return (
                      <UserMain menu_permissions={authAccess?.User || {}} />
                    );
                  }}
                />
                <ProtectedRoute
                  path="/menu"
                  component={(e) => {
                    return (
                      <MenuMain menu_permissions={authAccess?.Menu || {}} />
                    );
                  }}
                />
                <ProtectedRoute
                  path="/role"
                  component={(e) => {
                    return (
                      <RoleMain menu_permissions={authAccess?.Role || {}} />
                    );
                  }}
                />
                <ProtectedRoute
                  path="/warehouse"
                  component={(e) => {
                    return (
                      <WarehouseMain
                        menu_permissions={authAccess?.Warehouse || {}}
                      />
                    );
                  }}
                />
                <ProtectedRoute
                  path="/order"
                  component={(e) => {
                    return (
                      <OrderMain menu_permissions={authAccess?.Order || {}} />
                    );
                  }}
                />
                <ProtectedRoute
                  path="/switch"
                  component={(e) => {
                    return (
                      <SwitchMain menu_permissions={authAccess?.Order || {}} />
                    );
                  }}
                />
                <ProtectedRoute
                  path="/vehicle"
                  component={(e) => {
                    return (
                      <VehicleMain
                        menu_permissions={authAccess?.Vehicle || {}}
                      />
                    );
                  }}
                />
                <ProtectedRoute
                  path="/support"
                  component={(e) => {
                    return (
                      <SupportMain
                        menu_permissions={authAccess?.Support || {}}
                      />
                    );
                  }}
                />
                <ProtectedRoute
                  path="/tag"
                  component={(e) => {
                    return <TagMain menu_permissions={authAccess?.Tag || {}} />;
                  }}
                />
                <ProtectedRoute
                  path="/subscribe"
                  component={(e) => {
                    return (
                      <SubscribeMain
                        menu_permissions={authAccess?.Subscribe || {}}
                      />
                    );
                  }}
                />

                <ProtectedRoute
                  path="/transaction"
                  component={(e) => {
                    return (
                      <TransactionMain
                        menu_permissions={authAccess?.Transaction || {}}
                      />
                    );
                  }}
                />

                <ProtectedRoute
                  path="/item"
                  component={(e) => {
                    return (
                      <ItemMain menu_permissions={authAccess?.Item || {}} />
                    );
                  }}
                />

                <ProtectedRoute
                  path="/crm"
                  component={(e) => {
                    return (
                      <CrmMain menu_permissions={authAccess?.Customer || {}} />
                    );
                  }}
                />

                <ProtectedRoute
                  path="/acquirer"
                  component={(e) => {
                    return (
                      <AcquirerMain
                        menu_permissions={authAccess?.Acquirer || {}}
                      />
                    );
                  }}
                />

                <ProtectedRoute
                  path="/payroll"
                  component={(e) => {
                    return (
                      <PayrollMain
                        menu_permissions={authAccess?.Acquirer || {}}
                      />
                    );
                  }}
                />

                <Route
                  path="/404"
                  exact={true}
                  render={() => <h2>404 - Not Found</h2>}
                />
                <Route render={() => <h2>404 - Not Found</h2>} />
              </Switch>
            </Router>
          </div>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
