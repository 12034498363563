import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Paper } from "@material-ui/core";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import AxiosBase from "../../../networkRequest/axiosBase";
import "./index.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    padding: 9,
  },
});
const CssTextArea = styled(TextareaAutosize)({
  "input#comment-text": {
    width: "100%",
    height: "4.3rem",
  },
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function ItemForm(props) {
  const history = useHistory();
  const { id } = useParams("id");
  const [data, setData] = React.useState({});
  const [enablemerchant, setenablemerchant] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    if (id == -1) {
      setData({
        name: "",
        amount: "",
        gender: "",
        sequence: "",
        status: true,
      });
      return false;
    }
    AxiosBase.get(`/admin/item/${id}`).then((response) => {
      setData(response.data.data);
    });
  }, []);

  const save = async () => {
    if (!data.name) {
      return (
        toast("Item name is required"),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    }
    if (id == -1) {
      AxiosBase.post(`/admin/item`, data)
        .then((response) => {
          toast("Item Added Successfully.", {
            type: "success",
            autoClose: 2000,
          });
          history.push("/item");
        })
        .catch((err) => {
          console.log(err);
          return toast(
            err?.response?.data?.message || "Something went wrong!",
            {
              type: "error",
              autoClose: 2000,
            }
          );
        });
    } else {
      AxiosBase.put(`/admin/item/${id}`, data)
        .then((response) => {
          toast("Item Updated Successfully.", {
            type: "success",
            autoClose: 2000,
          });
          history.push("/item");
        })
        .catch((err) => {
          console.log(err);
          return toast(
            err?.response?.data?.message || "Something went wrong!",
            {
              type: "error",
              autoClose: 2000,
            }
          );
        });
    }
  };

  if (loading) {
    return (
      <div className="no_data_found loader_data_tbl">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <Box>
        <h1 className="merchant_title">Items</h1>
      </Box>
      <div className="merchant_list_main_div">
        <div style={{ padding: "15px" }}>
          {loading ? (
            <div className="no_data_found loader_data_tbl">
              <CircularProgress />
            </div>
          ) : (
            <>
              <Grid container spacing={2} className="merchant_main_grid">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>
                      Name<span>*</span>
                    </p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="Item Name"
                        fullWidth
                        disabled={enablemerchant}
                        value={data?.name}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            name: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>
                      Amount<span>*</span>
                    </p>

                    <div className="inp_sep_div">
                      <CssTextField
                        type="number"
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="Item Amount"
                        fullWidth
                        disabled={enablemerchant}
                        value={data?.amount}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            amount: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className="sep_inpt_sec">
                    <p>
                      Gender<span>*</span>
                    </p>

                    <div className="inp_sep_div">
                      <Select
                        name="key1"
                        labelId="country-lbl"
                        label=""
                        id="country"
                        value={data?.gender || ""}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setData({
                            ...data,
                            gender: e.target.value,
                          });
                        }}
                      >
                        <MenuItem key="Both" value="both">
                          Both
                        </MenuItem>
                        <MenuItem key="Male" value="male">
                          Male
                        </MenuItem>
                        <MenuItem key="Female" value="female">
                          Female
                        </MenuItem>
                      </Select>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>
                      Sequence<span>*</span>
                    </p>

                    <div className="inp_sep_div">
                      <CssTextField
                        type="number"
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="Item Sequence"
                        fullWidth
                        disabled={enablemerchant}
                        value={data?.sequence}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            sequence: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Status</p>

                    <div className="inp_sep_div">
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-radio-buttons-group-label"
                          value={data?.status ? "Active" : "Inactive"}
                          name="row-radio-buttons-group"
                          onChange={(e) => {
                            setData({
                              ...data,
                              status: e.target.value == "Active" ? true : false,
                            });
                          }}
                        >
                          <FormControlLabel
                            disabled={enablemerchant}
                            value="Active"
                            control={<Radio />}
                            label="Active"
                          />
                          <FormControlLabel
                            disabled={enablemerchant}
                            value="Inactive"
                            control={<Radio />}
                            label="Inactive"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </>
          )}

          {!enablemerchant && !loading ? (
            <div className="merchant_edit_button_dev">
              <Link to={`/item`}>
                <Button
                  className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                  variant="contained"
                >
                  Cancel
                </Button>
              </Link>
              {(props?.menu_permissions?.ADD ||
                props?.menu_permissions?.EDIT) && (
                <Button
                  className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                  variant="contained"
                  onClick={save}
                >
                  {id == -1 ? "Save" : "Update"}
                </Button>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default ItemForm;
