import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Box, Paper } from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";

import Tooltip from "@mui/material/Tooltip";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import AxiosBase from "../../../networkRequest/axiosBase";
import "./index.css";
import { Link } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    padding: 9,
  },
});
const CssTextArea = styled(TextareaAutosize)({
  "input#comment-text": {
    width: "100%",
    height: "4.3rem",
  },
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const fuelArr = [
  { name: "Petrol", value: "PETROL" },
  { name: "Diesel", value: "DIESEL" },
  { name: "Gas", value: "GAS" },
  { name: "Electric", value: "ELECTRIC" },
  { name: "Other", value: "OTHER" },
];

const typeArr = [
  { name: "Two Wheeler", value: "TWO_WHEELER" },
  { name: "Three Wheeler", value: "THREE_WHEELER" },
];

function VehicleForm(props) {
  const history = useHistory();
  const { id } = useParams("id");
  const [data, setData] = React.useState({});
  const [enablemerchant, setenablemerchant] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = React.useState("panel1");
  const [commentmandatory, setcommentmandatory] = React.useState(false);
  const [update, setupdate] = useState(false);
  const [rcFile, setRcFile] = useState(null);
  const [insuranceFile, setInsuranceFile] = useState(null);
  const [pucFile, setPucFile] = useState(null);
  const [profileFile, setProfileFile] = useState(null);
  const CHARACTER_LIMIT = 50;
  const mobile_LIMIT = 16;

  const [firsterror, setFirstError] = useState({
    error: false,
    message: "",
  });

  const [numbererror, setNumberError] = useState({
    error: false,
    message: "",
  });

  const [BusinessEmailError, setBusinessEmailError] = useState({
    error: false,
    message: "",
  });

  useEffect(() => {
    if (id == -1) {
      setData({
        number: "",
        documents: {},
      });
      return false;
    }
    AxiosBase.get(`/admin/vehicle/${id}`).then((response) => {
      setData(response.data.data);
    });
  }, []);

  const save = async () => {
    if (data.number == "") {
      setupdate(!update);
      return (
        toast("Vehicle number is required"),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    }
    if (profileFile) {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const formData = new FormData();
      formData.append("file", profileFile);
      const res = await AxiosBase.post(`/admin/uploadImages`, formData, config);
      data.profile = res?.data?.file_name;
    }
    if (rcFile) {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const formData = new FormData();
      formData.append("file", rcFile);
      const res = await AxiosBase.post(`/admin/uploadImages`, formData, config);
      data.documents.rc_url = res?.data?.file_name;
    }
    if (insuranceFile) {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const formData = new FormData();
      formData.append("file", insuranceFile);
      const res = await AxiosBase.post(`/admin/uploadImages`, formData, config);
      data.documents.insurance_url = res?.data?.file_name;
    }
    if (pucFile) {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const formData = new FormData();
      formData.append("file", pucFile);
      const res = await AxiosBase.post(`/admin/uploadImages`, formData, config);
      data.documents.puc_url = res?.data?.file_name;
    }
    setData({ ...data });
    if (data.role != "ADMIN" && data.role != "SUPER_ADMIN") {
      if (!data?.documents?.rc_url || !data?.documents?.rc_number) {
        setupdate(!update);
        return (
          toast("RC is required"),
          {
            type: "error",
            autoClose: 2000,
          }
        );
      }
      if (
        !data?.documents?.insurance_url ||
        !data?.documents?.insurance_number
      ) {
        setupdate(!update);
        return (
          toast("Insurance is required"),
          {
            type: "error",
            autoClose: 2000,
          }
        );
      }
      if (!data?.documents?.puc_url || !data?.documents?.puc_number) {
        setupdate(!update);
        return (
          toast("Pollution is required"),
          {
            type: "error",
            autoClose: 2000,
          }
        );
      }
    }
    if (id == -1) {
      AxiosBase.post(`/admin/vehicle`, data)
        .then((response) => {
          setupdate(!update);
          toast("Vehicle Added Successfully.", {
            type: "success",
            autoClose: 2000,
          });
          history.push("/vehicle");
        })
        .catch((err) => {
          console.log(err);
          setupdate(!update);
          return toast(
            err?.response?.data?.message || "Something went wrong!",
            {
              type: "error",
              autoClose: 2000,
            }
          );
        });
    } else {
      AxiosBase.put(`/admin/vehicle/${id}`, data)
        .then((response) => {
          setupdate(!update);
          toast("Vehicle Updated Successfully.", {
            type: "success",
            autoClose: 2000,
          });
          history.push("/vehicle");
        })
        .catch((err) => {
          console.log(err);
          setupdate(!update);
          return toast(
            err?.response?.data?.message || "Something went wrong!",
            {
              type: "error",
              autoClose: 2000,
            }
          );
        });
    }
  };

  if (loading) {
    return (
      <div className="no_data_found loader_data_tbl">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <Box>
        <h1 className="merchant_title">Vehicles</h1>
      </Box>
      <div className="merchant_list_main_div">
        <div style={{ padding: "15px" }}>
          {loading ? (
            <div className="no_data_found loader_data_tbl">
              <CircularProgress />
            </div>
          ) : (
            <>
              <h4
                style={{ marginLeft: "20px", color: "gray", fontWeight: "500" }}
              >
                BASIC DETAIL
              </h4>
              <hr />
              <Grid container spacing={2} className="merchant_main_grid">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Image</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="aadhar"
                        type="file"
                        fullWidth
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        required
                        onChange={(e) => {
                          setProfileFile(e.target.files[0]);
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Vehicle Number</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="Vehicle Number"
                        type="text"
                        fullWidth
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        value={data?.number}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            number: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Vehicle Company</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="Vehicle Company"
                        type="text"
                        fullWidth
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        value={data?.company}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            company: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Modal Name</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="Vehicle Modal"
                        type="text"
                        fullWidth
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        value={data?.modal_name}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            modal_name: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Modal Year</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="Vehicle Modal Year"
                        type="text"
                        fullWidth
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        value={data?.modal_year}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            modal_year: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Modal Number</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="Modal Number"
                        type="text"
                        fullWidth
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        value={data?.modal_number}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            modal_number: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className="sep_inpt_sec">
                    <p>Fuel</p>

                    <div className="inp_sep_div">
                      <Select
                        name="key1"
                        labelId="country-lbl"
                        label=""
                        id="country"
                        value={data?.fuel_type || ""}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setData({
                            ...data,
                            fuel_type: e.target.value,
                          });
                        }}
                      >
                        {fuelArr &&
                          fuelArr.map((c) => {
                            return (
                              <MenuItem key={c.value} value={c.value}>
                                {c.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className="sep_inpt_sec">
                    <p>Vehicle</p>

                    <div className="inp_sep_div">
                      <Select
                        name="key1"
                        labelId="country-lbl"
                        label=""
                        id="country"
                        value={data?.vehicle_type || ""}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setData({
                            ...data,
                            vehicle_type: e.target.value,
                          });
                        }}
                      >
                        {typeArr &&
                          typeArr.map((c) => {
                            return (
                              <MenuItem key={c.value} value={c.value}>
                                {c.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{ marginTop: "-16px" }}
                >
                  <div className="sep_inpt_sec">
                    <p>Chases Number</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="Chases Number"
                        type="text"
                        fullWidth
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        value={data?.chases_number}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            chases_number: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
              <h4
                style={{ marginLeft: "20px", color: "gray", fontWeight: "500" }}
              >
                Documents
              </h4>
              <hr />
              <Grid container spacing={2} className="merchant_main_grid">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>RC</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="aadhar"
                        type="file"
                        fullWidth
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        required
                        onChange={(e) => {
                          setRcFile(e.target.files[0]);
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>RC Number</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="RC Number"
                        type="number"
                        fullWidth
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        value={data?.documents?.rc_number}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            documents: {
                              ...data.documents,
                              rc_number: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Insurance</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="aadhar"
                        type="file"
                        fullWidth
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        required
                        onChange={(e) => {
                          setInsuranceFile(e.target.files[0]);
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Insurance Number</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="Insurance Number"
                        fullWidth
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        value={data?.documents?.insurance_number}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            documents: {
                              ...data.documents,
                              insurance_number: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Pollution(PUC)</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="aadhar"
                        type="file"
                        fullWidth
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        required
                        onChange={(e) => {
                          setPucFile(e.target.files[0]);
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Pollution Number</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="Pollution Number"
                        fullWidth
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        value={data?.documents?.puc_number}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            documents: {
                              ...data.documents,
                              puc_number: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
              <br />
              <hr />
            </>
          )}

          {!enablemerchant && !loading ? (
            <div className="merchant_edit_button_dev">
              <Link to={`/vehicle`}>
                <Button
                  className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                  variant="contained"
                >
                  Cancel
                </Button>
              </Link>
              {(props?.menu_permissions?.EDIT ||
                props?.menu_permissions?.ADD) && (
                <Button
                  className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                  variant="contained"
                  onClick={save}
                >
                  Update
                </Button>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default VehicleForm;
