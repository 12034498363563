import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Paper } from "@material-ui/core";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import AxiosBase from "../../../networkRequest/axiosBase";
import "./index.css";
import { Link } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import { useHistory } from "react-router-dom";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    padding: 9,
  },
});
const CssTextArea = styled(TextareaAutosize)({
  "input#comment-text": {
    width: "100%",
    height: "4.3rem",
  },
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function RoleForm(props) {
  const history = useHistory();
  const { id } = useParams("id");
  const [data, setData] = React.useState({});
  const [enablemerchant, setenablemerchant] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [update, setupdate] = useState(false);
  const [menu, setMenu] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    if (id == -1) {
      setData({
        name: "",
      });
      return false;
    }
    AxiosBase.get(`/admin/role/${id}`).then((response) => {
      setData(response.data.data);
      setSelectedIds(response.data.data?.access_menu || []);
    });
  }, []);

  useEffect(() => {
    AxiosBase.get(`/admin/menu`).then((response) => {
      setMenu(response.data.data);
    });
  }, []);

  const save = async () => {
    if (!data.name) {
      return (
        toast("Role name is required"),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    }
    // if (!selectedIds?.length) {
    //   return (
    //     toast("Please select at least one menu"),
    //     {
    //       type: "error",
    //       autoClose: 2000,
    //     }
    //   );
    // }
    // data.access_menu = selectedIds;
    if (id == -1) {
      AxiosBase.post(`/admin/role`, data)
        .then((response) => {
          toast("Role Added Successfully.", {
            type: "success",
            autoClose: 2000,
          });
          history.push("/role");
        })
        .catch((err) => {
          console.log(err);
          return toast(
            err?.response?.data?.message || "Something went wrong!",
            {
              type: "error",
              autoClose: 2000,
            }
          );
        });
    } else {
      AxiosBase.put(`/admin/role/${id}`, data)
        .then((response) => {
          toast("Role Updated Successfully.", {
            type: "success",
            autoClose: 2000,
          });
          history.push("/role");
        })
        .catch((err) => {
          console.log(err);
          return toast(
            err?.response?.data?.message || "Something went wrong!",
            {
              type: "error",
              autoClose: 2000,
            }
          );
        });
    }
  };

  const handleChange = (index) => {
    const ind = selectedIds.findIndex((x) => x == menu[index]?.name);
    if (ind > -1) {
      selectedIds.splice(ind, 1);
    } else {
      selectedIds.push(menu[index]?.name);
    }
    setSelectedIds([...selectedIds]);
  };

  if (loading) {
    return (
      <div className="no_data_found loader_data_tbl">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <Box>
        <h1 className="merchant_title">Roles</h1>
      </Box>
      <div className="merchant_list_main_div">
        <div style={{ padding: "15px" }}>
          {loading ? (
            <div className="no_data_found loader_data_tbl">
              <CircularProgress />
            </div>
          ) : (
            <>
              <Grid container spacing={2} className="merchant_main_grid">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Name</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="Role Name"
                        fullWidth
                        disabled={enablemerchant}
                        value={data?.name}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            name: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
              {/* <br />
              <Grid className="row" style={{ padding: "15px" }}>
                <p>Access Menu</p>
                {menu.map((row, index) => (
                  <div>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="merchant_grid_sec"
                      style={{ display: "inline-flex" }}
                    >
                      <Checkbox
                        value={row.name}
                        checked={selectedIds.includes(row.name)}
                        onChange={(e) => handleChange(index)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p style={{ marginTop: "7px" }}>{row.name}</p>
                    </Grid>
                  </div>
                ))}
              </Grid> */}
            </>
          )}

          {!enablemerchant && !loading ? (
            <div className="merchant_edit_button_dev">
              <Link to={`/role`}>
                <Button
                  className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                  variant="contained"
                >
                  Cancel
                </Button>
              </Link>
              {(props?.menu_permissions?.ADD ||
                props?.menu_permissions?.EDIT) && (
                <Button
                  className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                  variant="contained"
                  onClick={save}
                >
                  Update
                </Button>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default RoleForm;
