import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Box } from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import AxiosBase from "../../../networkRequest/axiosBase";
import "./index.css";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import GoogleAddress from "../../../utils/googleAddress";
import UsersList from "./user";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    padding: 9,
  },
});
const CssTextArea = styled(TextareaAutosize)({
  "input#comment-text": {
    width: "100%",
    height: "4.3rem",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function WarehouseForm(props) {
  const history = useHistory();
  const { id } = useParams("id");
  const [data, setData] = React.useState({});
  const [enablemerchant, setenablemerchant] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [update, setupdate] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const CHARACTER_LIMIT = 50;
  const mobile_LIMIT = 16;
  const [center, setCenter] = useState({
    lat: 37.7749,
    lng: -122.4194,
  });

  const [firsterror, setFirstError] = useState({
    error: false,
    message: "",
  });

  const [numbererror, setNumberError] = useState({
    error: false,
    message: "",
  });

  const [BusinessEmailError, setBusinessEmailError] = useState({
    error: false,
    message: "",
  });

  useEffect(() => {
    if (id == -1) {
      setData({
        name: "",
        country_code: "91",
        mobile_no: "91",
        user_name: "",
        address: "",
        code: "",
        lat: "",
        lng: "",
      });
      getUserLocation();
      return false;
    }
    AxiosBase.get(`/admin/warehouse/${id}`).then((response) => {
      setData(response.data.data);
      if (response.data.data?.lat && response.data.data?.lng) {
        setCenter({
          lat: response.data.data?.lat,
          lng: response.data.data?.lng,
        });
      }
    });
  }, []);

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  };

  const save = async () => {
    if (data.name != "" && firsterror.error) {
      setupdate(!update);
      return (
        toast(firsterror.message.toString()),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    } else if (numbererror.error) {
      setupdate(!update);
      return (
        toast(numbererror.message.toString()),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    } else if (BusinessEmailError.error) {
      setupdate(!update);
      return (
        toast(BusinessEmailError.message.toString()),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    } else if (
      data.mobile_no == "" ||
      data.user_name == "" ||
      data.code == "" ||
      data.name == ""
    ) {
      setupdate(!update);
      return (
        toast("Please fill warehouse information"),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    }
    if (id == -1) {
      AxiosBase.post(`/admin/warehouse`, data)
        .then((response) => {
          setupdate(!update);
          toast("Warehouse Added Successfully.", {
            type: "success",
            autoClose: 2000,
          });
          history.push("/warehouse");
        })
        .catch((err) => {
          console.log(err);
          setupdate(!update);
          return toast(
            err?.response?.data?.message || "Something went wrong!",
            {
              type: "error",
              autoClose: 2000,
            }
          );
        });
    } else {
      AxiosBase.put(`/admin/warehouse/${id}`, data)
        .then((response) => {
          setupdate(!update);
          toast("Warehouse Updated Successfully.", {
            type: "success",
            autoClose: 2000,
          });
          history.push("/warehouse");
        })
        .catch((err) => {
          console.log(err);
          setupdate(!update);
          return toast(
            err?.response?.data?.message || "Something went wrong!",
            {
              type: "error",
              autoClose: 2000,
            }
          );
        });
    }
  };

  const openGoogleMap = () => {
    setShowMap(true);
  };

  const closeGoogleMap = () => {
    setShowMap(false);
  };

  if (loading) {
    return (
      <div className="no_data_found loader_data_tbl">
        <CircularProgress />
      </div>
    );
  }

  const onSubmitAddress = async () => {
    AxiosBase.post(`/admin/google/full-address`, {
      lat: center?.lat,
      lng: center?.lng,
    }).then((response) => {
      setData({
        ...data,
        address: response.data.location,
        lat: center?.lat,
        lng: center?.lng,
      });
      setShowMap(false);
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <Box>
        <h1 className="merchant_title">Warehouses</h1>
      </Box>
      <div className="merchant_list_main_div">
        <div style={{ padding: "15px" }}>
          {loading ? (
            <div className="no_data_found loader_data_tbl">
              <CircularProgress />
            </div>
          ) : (
            <Grid container spacing={2} className="merchant_main_grid">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="merchant_grid_sec"
              >
                <div className="sep_inpt_sec">
                  <p>Name</p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="name"
                      fullWidth
                      helperText={
                        firsterror.error == true ? firsterror.message : ""
                      }
                      disabled={enablemerchant}
                      value={data?.name}
                      required
                      onChange={(e) => {
                        setData({
                          ...data,
                          name: e.target.value,
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <IconButton color="primary">
                              <PersonOutlinedIcon className="merchant_inpt_icon" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>
                    Mobile Number<span>*</span>
                  </p>

                  <div className="inp_sep_div">
                    {data?.mobile_no && (
                      <MuiPhoneNumber
                        className="create_merchant_input_feild mui_number_field"
                        data-cy="warehouse-phone"
                        defaultCountry={"ae"}
                        variant="outlined"
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: mobile_LIMIT,
                        }}
                        value={data?.mobile_no?.toString()}
                        onChange={(val, countrycode) => {
                          if (!val) return;
                          val = val.replace(/-|\s/g, "");
                          if (val.length < 12 || val.length > 16) {
                            setNumberError({
                              error: true,
                              message: "Enter valid Number",
                            });
                          } else {
                            setNumberError({ error: false, message: "" });
                          }
                          setData({
                            ...data,
                            mobile_no: val,
                            country_code: countrycode.dialCode,
                          });
                        }}
                      />
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>
                    User Name<span>*</span>
                  </p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      type="email"
                      disabled={enablemerchant}
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Business Email"
                      fullWidth
                      value={data?.user_name}
                      required
                      inputProps={{
                        maxLength: CHARACTER_LIMIT,
                      }}
                      error={
                        !data?.user_name?.match(
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        ) && data?.user_name !== ""
                      }
                      helperText={
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                          data?.user_name
                        ) || data?.user_name === ""
                          ? " "
                          : "Business email id must be between 6 to 50 characters"
                      }
                      onChange={(e) => {
                        if (
                          !e.target.value.match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                          ) &&
                          e.target.value !== ""
                        ) {
                          setBusinessEmailError({
                            error: true,
                            message: "Please enter valid email id",
                          });
                        } else {
                          setBusinessEmailError({
                            error: false,
                            message: "",
                          });
                        }
                        setData({
                          ...data,
                          user_name: e.target.value,
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <IconButton color="primary">
                              <img
                                src="/images/icon/top-email.svg"
                                alt=""
                                style={{ width: "25px", height: "25px" }}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>
                    Code<span>*</span>
                  </p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      type="text"
                      disabled={enablemerchant}
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Warehouse Code"
                      fullWidth
                      value={data?.code}
                      required
                      onChange={(e) => {
                        setData({
                          ...data,
                          code: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>
                    Warehouse Distance Limit<span>*</span>
                  </p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      type="number"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Warehouse Distance"
                      fullWidth
                      value={data?.warehouse_distance}
                      required
                      onChange={(e) => {
                        setData({
                          ...data,
                          warehouse_distance: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>
                    Rider Distance Limit<span>*</span>
                  </p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      type="number"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Rider Distance"
                      fullWidth
                      value={data?.rider_distance}
                      required
                      onChange={(e) => {
                        setData({
                          ...data,
                          rider_distance: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>
                    Delivery Charge(Per Km)<span>*</span>
                  </p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      type="number"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Delivery Charge"
                      fullWidth
                      value={data?.delivery_charge}
                      required
                      onChange={(e) => {
                        setData({
                          ...data,
                          delivery_charge: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>
                    Min Delivery Charge<span>*</span>
                  </p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      type="number"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Min Delivery Charge"
                      fullWidth
                      value={data?.min_delivery_charge}
                      required
                      onChange={(e) => {
                        setData({
                          ...data,
                          min_delivery_charge: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>
                    Min Order Amount(Applicable Delivery Charges)<span>*</span>
                  </p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      type="number"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Amount"
                      fullWidth
                      value={data?.min_order_amount_charge}
                      required
                      onChange={(e) => {
                        setData({
                          ...data,
                          min_order_amount_charge: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}></Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p></p>

                  <div className="inp_sep_div">
                    <Button variant="contained" onClick={openGoogleMap}>
                      Open Google map to set Location
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="merchant_grid_sec"
              >
                <div className="sep_inpt_sec">
                  <p>Address</p>

                  <div className="inp_sep_div">
                    <CssTextArea
                      id="text"
                      variant="outlined"
                      sx={{
                        width: "96%",
                        borderColor: "#c4c4c4",
                        p: "10px",
                        borderRadius: "5px",
                        fontSize: "14px",
                      }}
                      placeholder="address"
                      value={data?.address}
                      onChange={(e) => {
                        setData({
                          ...data,
                          address: e,
                        });
                      }}
                      minRows={3}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <IconButton color="primary">
                              <img
                                src="/images/icon/top-email.svg"
                                alt=""
                                style={{ width: "25px", height: "25px" }}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          )}

          {!enablemerchant && !loading ? (
            <div className="merchant_edit_button_dev">
              <Link to={`/warehouse`}>
                <Button
                  className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                  variant="contained"
                >
                  Cancel
                </Button>
              </Link>
              {(props?.menu_permissions?.ADD ||
                props?.menu_permissions?.EDIT) && (
                <Button
                  className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                  variant="contained"
                  onClick={save}
                >
                  Update
                </Button>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      {id != -1 && (
        <Grid
          container
          spacing={2}
          className="merchant_main_grid"
          style={{ marginTop: "3rem" }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <UsersList id={id} {...props} />
          </Grid>
        </Grid>
      )}

      <div>
        <BootstrapDialog
          className="google-address-dialog"
          onClose={closeGoogleMap}
          aria-labelledby="customized-dialog-title"
          open={showMap}
        >
          <DialogContent>
            <GoogleAddress
              onSubmitAddress={onSubmitAddress}
              center={center}
              setCenter={setCenter}
            />
          </DialogContent>
        </BootstrapDialog>
      </div>
    </div>
  );
}

export default WarehouseForm;
