import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import "./index.scss";
import { SettingsPowerRounded } from "@material-ui/icons";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const statusColors = {
  completed_order: {
    color: "green",
    icon: "/images/dashboard/Dashboard ICONS 1-08.svg",
    label: "Completed Order",
  },
  pick_order: {
    color: "#01b49e",
    icon: "/images/dashboard/Dashboard ICONS 1-07.svg",
    label: "Pick Order",
  },
  rider_order: {
    color: "#2C1867",
    icon: "/images/dashboard/Dashboard ICONS 1-04.svg",
    label: "Rider Order",
  },
  transporter_order: {
    color: "#38EDCC",
    icon: "/images/dashboard/Dashboard ICONS 1-02.svg",
    label: "Transporter Order",
  },
  drop_order: {
    color: "#F58500",
    icon: "/images/dashboard/Dashboard ICONS 1-01.svg",
    label: "Drop Order",
  },
  warehouse_order: {
    color: "#D14DFF",
    icon: "/images/dashboard/Dashboard ICONS 1-06.svg",
    label: "Warehouse Order",
  },
  declined_order: {
    color: "#D14DFF",
    icon: "/images/dashboard/Dashboard ICONS 1-06.svg",
    label: "Declined Order",
  },
  customer: {
    color: "#0F79DB",
    icon: "/images/dashboard/Dashboard ICONS 1-03.svg",
    label: "New Customer",
  },
  rider: {
    color: "#C90000",
    icon: "/images/dashboard/Dashboard ICONS 1-09.svg",
    label: "Total Partner",
  },
  // warehouse: {
  //   color: "#A7A7A7",
  //   icon: "/images/dashboard/Dashboard ICONS 1-08.svg",
  //   label: "Total Warehouse",
  // },
  deposit: {
    color: "#A7A7A7",
    icon: "/images/dashboard/Dashboard ICONS 1-06.svg",
    label: "Deposit",
  },
  // "Session Created": {
  //   color: "#A7A7A7",
  //   icon: "/images/dashboard/Dashboard ICONS 1-08.svg",
  // },
};

const StatusCard = (props) => {
  const { data, filter } = props;
  return (
    <div
      className="dashboard-status-card"
      style={{ color: statusColors[data?._id].color }}
    >
      <div className="dashboard-status-card-top">
        <h2>{data?.key}</h2>
        <img src={statusColors[data?._id].icon} alt={data?._id} />
      </div>
      <div className="dashboard-status-card-bottom">
        <div>
          <p>{Intl.NumberFormat("en-IN").format(data?.amount || 0)}</p>
        </div>
      </div>
    </div>
  );
};

export default function FirstSection(props) {
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(null);
  const [selectedType, setSelectedType] = React.useState("");

  const data = props?.data
    ? Object.keys(statusColors).map((key) => {
        let resData = null;
        if (props.data?.order[key]) {
          resData = {
            _id: key,
            key: statusColors[key].label,
            amount: props.data?.order[key]?.length,
            data: props.data?.order[key],
            type: "order",
          };
        } else if (
          key == "pick_order" ||
          key == "drop_order" ||
          key == "completed_order" ||
          key == "declined_order" ||
          key == "customer" ||
          key == "warehouse" ||
          key == "rider"
        ) {
          resData = {
            _id: key,
            key: statusColors[key].label,
            amount: props.data[key]?.length,
            data: props.data[key],
            type: key,
          };
        } else if (key == "deposit") {
          resData = {
            _id: key,
            key: statusColors[key].label,
            amount: props.data[key],
            data: [],
            type: "deposit",
          };
        } else {
          resData = {
            _id: key,
            key: statusColors[key].label,
            amount: 0,
            data: [],
            type: "",
          };
        }
        return resData;
      })
    : [];

  const handleSelected = (val, type) => {
    if (!val.length) {
      return;
    }
    setSelectedType(type);
    setSelected(val);
    setOpen(true);
  };

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div>
      <div className="dashboard-status">
        {data?.map(
          (status, index) =>
            statusColors[status._id] && (
              <div
                onClick={() => {
                  handleSelected(status?.data, status?.type);
                }}
              >
                <StatusCard data={status} filter={props.filter} key={index} />
              </div>
            )
        )}
      </div>
      <hr></hr>

      <div>
        <BootstrapDialog
          className="custom-dialog"
          onClose={toggle}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogContent>
            {selectedType == "order" ||
            selectedType == "pick_order" ||
            selectedType == "drop_order" ||
            selectedType == "completed_order" ||
            selectedType == "declined_order" ? (
              <TableContainer className="table_container all-order-table">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className="data_tbl_head_row">
                    <TableRow style={{ background: "none" }}>
                      <TableCell>Order ID</TableCell>
                      <TableCell>Tag</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Customer</TableCell>
                      <TableCell>Rider</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>
                        {selectedType == "pick_order"
                          ? "Pick Date"
                          : selectedType == "drop_order"
                          ? "Drop Date"
                          : selectedType == "decline_order"
                          ? "Decline Date"
                          : "Created At"}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="data_tbl_body_row">
                    {selected.map((row, index) => (
                      <TableRow key={index} style={{ background: "none" }}>
                        <TableCell>{row.order_id}</TableCell>
                        <TableCell>{row.tag_no || "NA"}</TableCell>
                        <TableCell>{row.type}</TableCell>
                        <TableCell>
                          {selectedType == "order"
                            ? row.customer?.name || "NA"
                            : row.customer_id?.name || "NA"}
                        </TableCell>
                        <TableCell>
                          {selectedType == "order"
                            ? row.rider?.name || "NA"
                            : row.delivery_id?.name || "NA"}
                        </TableCell>
                        <TableCell>{row.status}</TableCell>
                        <TableCell>
                          {selectedType == "pick_order"
                            ? moment(row.pick_date).format(
                                "DD MMM YYYY hh:mm A"
                              )
                            : selectedType == "drop_order"
                            ? moment(row.drop_date).format(
                                "DD MMM YYYY hh:mm A"
                              )
                            : selectedType == "decline_order"
                            ? moment(row.updatedAt).format(
                                "DD MMM YYYY hh:mm A"
                              )
                            : moment(row.createdAt).format(
                                "DD MMM YYYY hh:mm A"
                              )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : selectedType == "customer" || selectedType == "rider" ? (
              <TableContainer className="table_container all-order-table">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className="data_tbl_head_row">
                    <TableRow style={{ background: "none" }}>
                      <TableCell>ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Mobile No</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell>Created At</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="data_tbl_body_row">
                    {selected.map((row, index) => (
                      <TableRow key={index} style={{ background: "none" }}>
                        <TableCell>{row._id}</TableCell>
                        <TableCell>{row.name || "NA"}</TableCell>
                        <TableCell>{row.mobile_no}</TableCell>
                        <TableCell>{row?.role || "Customer"}</TableCell>
                        <TableCell>
                          {moment(row.createdAt).format("DD MMM YYYY hh:mm A")}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : selectedType == "warehouse" ? (
              <TableContainer className="table_container all-order-table">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className="data_tbl_head_row">
                    <TableRow style={{ background: "none" }}>
                      <TableCell>ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Mobile No</TableCell>
                      <TableCell>Created At</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="data_tbl_body_row">
                    {selected.map((row, index) => (
                      <TableRow key={index} style={{ background: "none" }}>
                        <TableCell>{row._id}</TableCell>
                        <TableCell>{row.name || "NA"}</TableCell>
                        <TableCell>{row.mobile_no}</TableCell>
                        <TableCell>
                          {moment(row.createdAt).format("DD MMM YYYY hh:mm A")}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
          </DialogContent>
        </BootstrapDialog>
      </div>
    </div>
  );
}
