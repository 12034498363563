import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DateRangeComp from "./datepicker";
import "./Filter.scss";

export default function Filter(props) {
  return (
    <div className="custom-date-filter">
      <div>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small">Date Range</InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={props.filter.dateRange}
            label="Date Range"
            name="dateRange"
            onChange={props.handleFilter}
          >
            <MenuItem value={"Today"}>Today</MenuItem>
            <MenuItem value={"Yesterday"}>Yesterday</MenuItem>
            <MenuItem value={"This Week"}>This Week</MenuItem>
            <MenuItem value={"This Month"}>This Month</MenuItem>
            <MenuItem value={"This Year"}>This Year</MenuItem>
            <MenuItem value={"Custom"}>Custom Range</MenuItem>
          </Select>
        </FormControl>
        {props.filter.dateRange == "Custom" && (
          <DateRangeComp
            handledates={props.handledates}
            position="bottom right"
            style={{ display: "inline-block", marginTop: 10, marginRight: 4 }}
            open={true}
          />
        )}
      </div>
    </div>
  );
}
