import * as actionTypes from "./action-types";
import AxiosBase from "../../networkRequest/axiosBase";
import { createBrowserHistory } from "history";
import moment from "moment";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authRestart = (payload) => {
  return {
    type: actionTypes.AUTH_RESTART,
    payload,
  };
};

export const authSuccess = (payload) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    payload,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error,
  };
};

export const logout = () => {
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const authUpdate = (user_data) => {
  return {
    type: actionTypes.AUTH_UPDATE,
    user_data,
  };
};

export const resetError = () => {
  return {
    type: actionTypes.RESET_ERROR,
  };
};

export const authToken = (payload) => {
  return {
    type: actionTypes.AUTH_UPDATE_TOKEN,
    payload,
  };
};

export const sessionWarning = (payload) => {
  return {
    type: actionTypes.SESSION_WARNING,
    payload,
  };
};

export const lockedOut = (payload) => {
  return {
    type: actionTypes.LOCKED_OUT,
    payload,
  };
};

const ResetPassword = (data) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post("/admin/user/resetpasswordfromtemprory", data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log("error", err);
        resolve(err.response);
      });
  });
};
const login = (data) =>
  new Promise((resolve, reject) => {
    AxiosBase.post("/admin/login", data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        resolve(err.response);
      });
  });

export const updateToken = (payload) => async (dispatch) => {
  dispatch(authToken(payload));
};

export const logoutUser =
  (redirect = "/login", action = "Logout") =>
  async (dispatch) => {
    try {
      try {
        await AxiosBase.post("/admin/logout", { action });
      } catch (err) {
        console.log("logout");
      }
      dispatch(logout());
      if (window.location.pathname !== redirect) {
        createBrowserHistory().push(redirect);
        window.location.reload();
      }
      if (redirect == "/warning") {
        dispatch(sessionWarning({ value: true }));
      }
    } catch (error) {
      console.log(error);
    }
  };

export const authLogin = (data) => {
  return async (dispatch) => {
    if (!data.is_error) {
      dispatch(authStart());
      const res = data.res;
      if (res?.status === 200 || res?.status === 204) {
        await dispatch(authSuccess(res.data));
      } else {
        if (res?.data?.is_locked) {
          dispatch(lockedOut({ value: true }));
          createBrowserHistory().push("/locked");
          window.location.reload();
        } else {
          dispatch(authFail(res?.data));
        }
      }
    } else {
      const error = data.error;
      if (error?.is_locked) {
        dispatch(lockedOut({ value: true }));
        createBrowserHistory().push("/locked");
        window.location.reload();
      } else {
        dispatch(authFail(error));
      }
    }
  };
};

export const authReset = (data, redirect = "/login") => {
  return async (dispatch) => {
    try {
      dispatch(authStart());
      const res = await ResetPassword(data);
      if (res?.data?.status) {
        dispatch(logout());
        if (window.location.pathname !== redirect) {
          createBrowserHistory().push(redirect);
          window.location.reload();
        }
      } else {
        dispatch(authFail(res.data.message));
      }
    } catch (error) {
      dispatch(authFail(error));
    }
  };
};

export const updateUser = (updateData) => (dispatch) => {
  dispatch(authUpdate(updateData));
};
