import React, { useState, useEffect, useRef } from "react";
import { useHistory, Redirect } from "react-router-dom";
import styles from "./login.module.css";
import "font-awesome/css/font-awesome.css";
import { login } from "../../networkRequest/request/auth";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/auth";
import EmailIcon from "@mui/icons-material/Email";
import KeyIcon from "@mui/icons-material/Key";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import moment from "moment";
import AxiosBase from "../../networkRequest/axiosBase";

function Login(props) {
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [timer, setTimer] = useState("00");
  const Ref = useRef(null);
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    return {
      total,
      seconds,
    };
  };

  useEffect(() => {
    props.onSessionWarning({ value: false });
    props.onLockedOut({ value: false });
  }, []);

  useEffect(() => {
    if (props.error) {
      props.onResetError();
      toast(props.error || "Wrong email or password!", {
        type: "error",
        autoClose: 5000,
      });
      setSpinner(false);
    }
  }, [props.error]);

  const submitClick = async () => {
    setSpinner(true);
    try {
      const res = await AxiosBase.post("/admin/login", {
        email_id: emailId,
        password,
      });
      if (res.data.reset_pass) {
        res.data.user_data.isTemporaryPasswordMsg =
          "Your password has expired and needs to be reset. This is because you have not changed your password in the last 90 days as required by our security policy.";
      }
      props.onLogin({ is_error: false, res });
    } catch (error) {
      props.onLogin({ is_error: true, error: error?.response?.data });
    }
  };

  if (props.isAuthenticated && !props.isTemporaryPassword) {
    window.location.href = "/dashboard";
    return <></>;
    // return <Redirect to="/dashboard" />;
  }
  if (props.isTemporaryPassword) {
    window.location.href = "/reset";
    return <></>;
    // return <Redirect to="/reset" />;
  }

  return (
    <>
      <div
        className={styles.outer}
        style={{ backgroundImage: 'url("/images/login-bg.jpg")' }}
      >
        <div className={styles.loginBox}>
          <div
            className={styles.inner}
            style={{
              "text-align": "center",
              "margin-top": "-1.5rem",
            }}
          >
            <img src="/logo.svg" alt="Logo" style={{ width: "40%" }} />
          </div>
          <div className={styles.inner}>
            <div
              className={`${styles.justify_content_center} ${styles.align_items_center} ${styles.h_100}`}
            >
              <div className={styles.loginContainer}>
                <div className={styles.card}>
                  <div className={styles.card_body}>
                    <h4 className={styles.heading}>Sign In</h4>
                    <form className={styles.form} autocomplete="off">
                      <div className={styles.inputContainer}>
                        <TextField
                          id="emailId"
                          name="emailId"
                          placeholder="Enter Email ID"
                          value={emailId}
                          onChange={(e) => setEmailId(e.target.value)}
                          fullWidth
                          variant="outlined"
                          size="small"
                          className={styles.login_input}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon htmlColor="#8f8c8cbf" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div className={styles.inputContainer}>
                        <div className={styles.inputContainer}>
                          <TextField
                            id="pass"
                            type="password"
                            name="pass"
                            value={password}
                            placeholder="Enter Password"
                            onChange={(e) => setPassword(e.target.value)}
                            fullWidth
                            variant="outlined"
                            size="small"
                            autoComplete="off"
                            className={styles.login_input}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <KeyIcon htmlColor="#8f8c8cbf" />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <input
                            name="password1"
                            type="password"
                            value={`${Date.now()}${Date.now()}`}
                            autocomplete="new-password"
                            style={{ display: "none" }}
                          ></input>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className={styles.signupBtn}
                        id="login-btn"
                        disabled={spinner ? true : false}
                        onClick={(e) => {
                          e.preventDefault();
                          submitClick();
                        }}
                      >
                        {spinner ? (
                          <i
                            className="fa fa-spinner fa-spin"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          "Login"
                        )}
                      </button>
                    </form>
                  </div>
                </div>
                {/* <div className={`${styles.card} ${styles.bottom_card}`}></div> */}
              </div>
            </div>
            <div className={styles.login_footer}>
              <a href="https://www.clothiq.in/" target="_blank">
                &copy; ClothiQ
              </a>{" "}
              |
              <a href="https://www.clothiq.in/contact-page" target="_blank">
                Contact us
              </a>{" "}
              |
              <a href="https://www.clothiq.in/contact-page" target="_blank">
                Help
              </a>{" "}
              |
              <a
                href="https://www.clothiq.in/terms-condition-page"
                target="_blank"
              >
                Terms & Conditions
              </a>{" "}
              |
              <a
                href="https://www.clothiq.in/privacy-policy-page"
                target="_blank"
              >
                Cookie Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user_data: state.auth.user_data,
    accessToken: state.auth.accessToken,
    refreshToken: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated,
    isTemporaryPassword: state.auth.isTemporaryPassword,
    error: state.auth.error,
    loading: state.auth.loading,
    sessionWarning: state.auth.sessionWarning,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (data) => dispatch(actions.authLogin(data)),
    onLogout: () => dispatch(actions.logoutUser()),
    onResetError: () => dispatch(actions.resetError()),
    onSessionWarning: (data) => dispatch(actions.sessionWarning(data)),
    onLockedOut: (data) => dispatch(actions.lockedOut(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
