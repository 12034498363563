import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Slider from "react-slick";
import Carousel from "react-material-ui-carousel";
import { toast } from "react-toastify";

import "./UploadDoc.css";

const Input = styled("input")({
  display: "none",
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function UploadDoc(props) {
  const [open, setOpen] = React.useState(props.open);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [validFiles, setValidFiles] = useState([]);
  const fileInputRef = useRef(null);

  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
    severiety: "success",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  var type = props.type;

  // dragdrop img sec
  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const handleFiles = (files) => {
      if (validateFile(files[0])) {
        setOpen(false);
        setSelectedFiles((prevArray) => [...prevArray, files[0]]);
        props.handleData(files[0]);
      } else {
        setOpen(true);
        files[0]["invalid"] = true;
        setSelectedFiles((prevArray) => [...prevArray, files[0]]);
      }
  };
  
  const validateFile = (file) => {
    const validTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
      "text/csv",
      // "image/gif",
      // "image/x-icon",
    ];
    if (validTypes.indexOf(file.type) === -1) {
      setErrorMessage("File type not permitted");
      return false;
    }
    if (!(file.size <= 6000000)) {
      setErrorMessage(
        "The uploaded image is greater than 6 MB, please resize the image and try again."
      );
      return false;
    }
    return true;
  };
  const fileSize = (size) => {
    if (size === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };
  const fileType = (fileName) => {
    return (
      fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) ||
      fileName
    );
  };

  const removeFile = (name) => {
    const validFileIndex = validFiles.findIndex((e) => e.name === name);
    validFiles.splice(validFileIndex, 1);
    setValidFiles([...validFiles]);
    const selectedFileIndex = selectedFiles.findIndex((e) => e.name === name);
    selectedFiles.splice(selectedFileIndex, 1);
    setSelectedFiles([...selectedFiles]);
  };
  useEffect(() => {
    let filteredArray = selectedFiles.reduce((file, current) => {
      const x = file.find((item) => item.name === current.name);
      if (!x) {
        return file.concat([current]);
      } else {
        return file;
      }
    }, []);
    setValidFiles([...filteredArray]);
  }, [selectedFiles]);

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };
  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };
  return (
    <div>
      <BootstrapDialog
        onClose={props.handleDocumentClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        style={{ textAlign: "center" }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          className="customized-dialog-title"
          onClose={props.handleDocumentClose}
          sx={{ textAlign: "center" }}
        >
          UPLOAD FILES
          <p style={{ fontSize: "12px", margin: "0px", color: "#666666" }}>
            Upload document you want to share
                <br /> with your team
          </p>
        </BootstrapDialogTitle>
        <DialogContent style={{width: "500px"}}>
          <div
            className="dilog_body"
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
          >
            <img src="/UPLOADFILES.svg" alt="" />
            <p>Drag & Drop your file here</p>
            <br />
            <p>OR</p>
            {
              type == "bank_statement" ? 
              (
                <Input
                  accept="image/png, image/jpg, image/jpeg, .csv, .xls, .xlsx, .pdf"
                  id="contained-button-file"
                  ref={fileInputRef}
                  onChange={filesSelected}
                  type="file"
                />
              ) :
              (
                <Input
                  accept="image/png, image/jpg, image/jpeg"
                  id="contained-button-file"
                  ref={fileInputRef}
                  onChange={filesSelected}
                  type="file"
                />
              )
            }
            <Button
              className="upload_btn"
              variant="contained"
              onClick={fileInputClicked}
            >
              Browse File
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className="closefile"
            autoFocus
            onClick={props.handleDocumentClose}
          >
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

function checklenght(val, lenght) {
  if (val?.length > lenght) {
    return val?.substring(0, lenght) + "..";
  }
  if (!val) {
    return "NA";
  }
  return val;
}
