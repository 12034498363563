import React from "react";
import { Route, Switch } from "react-router-dom";
import MenuList from "../../Components/menu/list";
import Topheader from "../../universal-components/header/topHeader";

function MenuMain(props) {
  if (!props?.menu_permissions?.VIEW) {
    return (
      <>
        <Topheader />
        <div className="no-access-section">
          <div style={{ height: "25vh" }}></div>
          <img
            src="/images/icon/access-denied.jpg"
            alt=""
            style={{ width: "20%" }}
          />
          <br />
          <label className="gr">
            You currently does not have access to this page
          </label>
          <br />
          <label className="gr">Please try again latter</label>
        </div>
      </>
    );
  }

  return (
    <div style={{ padding: "15px" }}>
      <Topheader />
      <Switch>
        <MenuList {...props} />
      </Switch>
    </div>
  );
}

export default MenuMain;
