import React, { useState, useEffect } from "react";
import { Box, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import "./index.css";
import TablePagination from "@mui/material/TablePagination";
import AxiosBase from "../../../networkRequest/axiosBase";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import * as XLSX from "xlsx";

const Div = styled("div")(unstable_styleFunctionSx);

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "2px",
    backgroundColor: "#FEFEFE",
  },
});

// main function

const CustomerList = (props) => {
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [search, setSearch] = React.useState("");
  const [spinner, setSpinner] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    setSpinner(true);
    AxiosBase.get(
      `/admin/customer?pageNo=` +
        page +
        `&rowsPerPage=` +
        rowsPerPage +
        `&sortBy=_id&sortOrder=desc&search=` +
        search
    ).then((response) => {
      setData(response.data.data);
      setCount(response.data.count);
      setSpinner(false);
    });
  }, [page, rowsPerPage, count, search]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const exportToExcel = async () => {
    const filename = "customers.xlsx";
    setSpinner(true);
    AxiosBase.get(
      `/admin/customer?pageNo=` +
        0 +
        `&rowsPerPage=` +
        count +
        `&sortBy=_id&sortOrder=desc&search=` +
        search
    ).then((response) => {
      const excelData =
        response?.data?.data.map((x) => {
          return {
            ID: x?._id,
            Name: x?.name,
            "Mobile No": x?.mobile_no,
            Email: x?.email || "N/A",
            Date: moment(x?.createdAt).format("DD MMM YYYY hh:mm A"),
          };
        }) || [];
      // Create a new workbook and worksheet
      const worksheet = XLSX.utils.json_to_sheet(excelData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      // Write the workbook to a file and trigger the download
      XLSX.writeFile(workbook, filename);
      setSpinner(false);
    });
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box>
          <h1 className="merchant_title">Customers</h1>
        </Box>

        <div className="merchant_list_main_div">
          <div className="merchant_list_inner_title_div">
            <div className="merchant_list_search_filter">
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                className="merchant_grid_sec"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  placeholder="search"
                  variant="outlined"
                  fullWidth
                  onKeyUp={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <IconButton className="merchant_search_icon">
                          <img src="/images/icon/search.svg" alt="" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <div className="merchant_list_filter_div">
                <img src="/Filter.svg" alt="" />
              </div>
            </div>
            <h3 className="merchant_list_inner_title">
              Customer List
              <label className="excel-btn" onClick={exportToExcel}>
                Export to Excel
              </label>
            </h3>
          </div>
          {spinner ? (
            <div className="no_data_found loader_data_tbl">
              <CircularProgress style={{ color: "#1058ff" }} />
            </div>
          ) : (
            <>
              <TableContainer className="table_container">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className="data_tbl_head_row">
                    <TableRow style={{ background: "none" }}>
                      <TableCell>Customer ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Email ID</TableCell>
                      <TableCell>Contact Number</TableCell>
                      <TableCell>Wallet Amount</TableCell>
                      <TableCell>Created At</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="data_tbl_body_row">
                    {data.map((row, index) => (
                      <TableRow key={row?._id} style={{ background: "none" }}>
                        <TableCell>
                          {props?.menu_permissions?.EDIT ? (
                            <Link
                              to={`/customer/form/${row._id}`}
                              style={{
                                textDecoration: "none",
                                color: "#1058ff",
                              }}
                            >
                              {row._id}
                            </Link>
                          ) : (
                            row._id
                          )}
                        </TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.mobile_no}</TableCell>
                        <TableCell>
                          {(row.wallet_amount || 0).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          {moment(row.createdAt).format("DD-MMM-YYYY HH:MM A")}
                        </TableCell>
                        <TableCell>
                          {props?.menu_permissions?.EDIT && (
                            <Link to={`/customer/form/${row._id}`}>
                              <IconButton
                                className="merchant_tbl_edit_icon"
                                aria-label="more"
                                id="long-button"
                                aria-controls="long-menu"
                                aria-expanded={open ? "true" : undefined}
                                aria-haspopup="true"
                              >
                                <img src="/images/icon/editt.svg" alt="" />
                              </IconButton>
                            </Link>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className="table_pagination"
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomerList;
