import React from "react";
import { Box, Paper } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import { Link } from "react-router-dom";
import "./header.css";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/auth";
import moment from "moment";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

function Topheader(props) {
  return (
    <div>
      <Box className="topHeader-main">
        <Box className="topHeader-sub-div">
          {/* <IconButton className="topHeader-icon">
            <img
              src="/top-email.svg"
              alt="emailicon"
              style={{ height: "25px" }}
            />
          </IconButton>

          <IconButton className="topHeader-icon" sx={{ mx: "20px" }}>
            <img src="/top-bell-icon.svg" alt="bellicon" />
          </IconButton> */}

          <Box sx={{ display: "flex" }}>
            <Paper className="topHeader-paper" elevation={5} component="form">
              <Box className="topHeader-paper-box" sx={{ mx: "1rem" }}>
                <Box>
                  <IconButton sx={{ borderRadius: "50%" }}>
                    <img
                      className="topHeader-profile-pic"
                      src="/Profile-01.svg"
                      alt=""
                    />
                  </IconButton>
                </Box>
                <Box
                  className="topHeader-paper-box"
                  sx={{
                    textAlign: "right",
                    ml: "5px",
                  }}
                >
                  <Box>
                    <Box sx={{ fontSize: "1.3rem" }}>
                      {props?.user_data?.name}
                    </Box>
                    <Box sx={{ fontSize: "0.7rem" }}>
                      member since{" "}
                      {moment(props?.user_data?.createdAt).format(
                        "YYYY/MM/DD HH:mm"
                      )}
                    </Box>
                  </Box>
                  <Box sx={{ ml: "1rem" }}>
                    <PopupState variant="popover" popupId="demo-popup-popover">
                      {(popupState) => (
                        <div>
                          <KeyboardArrowDownSharpIcon
                            sx={{ fontSize: "1.5rem" }}
                            {...bindTrigger(popupState)}
                          />
                          <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            sx={{ mt: "20px", ml: "15px" }}
                          >
                            <Typography className="topheader_popup_links">
                              <img
                                className="topheader_link_icons"
                                src="/Configuration-01.svg"
                                alt=""
                              />
                              <Link to="/">Settings</Link>
                            </Typography>
                            <Typography className="topheader_popup_links" onClick={() => props.onLogout()}>
                              <img
                                className="topheader_link_icons"
                                src="/logout-01.svg"
                                alt=""
                              />
                              <Link>Logout</Link>
                            </Typography>
                          </Popover>
                        </div>
                      )}
                    </PopupState>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user_data: state.auth.user_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logoutUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Topheader);
