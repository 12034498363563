import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FaceIcon from "@material-ui/icons/Face";
import { NavLink } from "react-router-dom";
import styles from "./navbar.module.scss";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/auth";
import LogoutIcon from "@mui/icons-material/Logout";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#1456F5",
    borderRadius: "0 15px 15px 0",
    border: "none",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
}));

function PermanentDrawerRight(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div
          className={classes.toolbar}
          style={{ padding: "20px", backgroundColor: "#1456F5" }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src="/logo.svg" alt="ClothiQ Logo" width="120px" />
          </div>
        </div>
        <Divider />

        <List>
          {/* <NavLink className={styles.navlink} activeClassName={styles.navlink_active} to="/merchants">
            <ListItem button>
              <ListItemIcon>
                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 34.388 27.987">
                  <g>
                    <path fill="#fff" fillRule="evenodd" stroke="#fff" strokeWidth="0.25px" d="M19.957 966.6a7.572 7.572 0 0 0-7.561 7.561 13.7 13.7 0 0 0 1.8 6.589 9.534 9.534 0 0 0 1.223 1.671 2.349 2.349 0 0 1-.144.569c-.07.157-.1.155-.108.159-.694.263-2.735.435-4.666 1.039s-3.951 1.82-4.48 4.288a.776.776 0 0 0 .238.738 20.889 20.889 0 0 0 27.408 0 .776.776 0 0 0 .254-.612 15.725 15.725 0 0 0 5.957-3.353.776.776 0 0 0 .238-.738 4.916 4.916 0 0 0-3.671-3.525c-1.558-.487-3.2-.634-3.7-.823-.008 0 0 .015-.046-.068a1.234 1.234 0 0 1-.087-.267 7.72 7.72 0 0 0 .984-1.345 11.023 11.023 0 0 0 1.449-5.319 6.171 6.171 0 0 0-10.145-4.72 7.534 7.534 0 0 0-4.939-1.84zm0 1.551a6 6 0 0 1 6.013 6.009 12.459 12.459 0 0 1-1.586 5.809c-1.045 1.8-2.493 3.032-4.428 3.032s-3.379-1.235-4.424-3.032a12.464 12.464 0 0 1-1.585-5.809 6 6 0 0 1 6.009-6.009zm8.911.392a4.607 4.607 0 0 1 4.62 4.62 9.784 9.784 0 0 1-1.238 4.539 3.97 3.97 0 0 1-3.382 2.332 3.285 3.285 0 0 1-2.325-.973 13.661 13.661 0 0 0 .979-4.9 7.516 7.516 0 0 0-1.559-4.589 4.593 4.593 0 0 1 2.906-1.028zm-3.008 11.973a4.837 4.837 0 0 0 3.008 1.073 4.687 4.687 0 0 0 2.484-.712 1.684 1.684 0 0 0 .836.738c1.035.393 2.47.442 3.79.854a3.418 3.418 0 0 1 2.494 1.947 14.246 14.246 0 0 1-5.06 2.72 6.549 6.549 0 0 0-3.993-2.945c-1.93-.6-3.971-.776-4.665-1.039-.062-.024-.094-.041-.167-.181a1.887 1.887 0 0 1-.147-.483 9.475 9.475 0 0 0 1.284-1.735q.069-.118.134-.238zm-9.141 3.036a5.723 5.723 0 0 0 6.44.026c.016.034.032.069.05.1a1.919 1.919 0 0 0 1 .923c1.23.466 3.061.54 4.751 1.069 1.551.485 2.813 1.2 3.311 2.706a19.461 19.461 0 0 1-24.619 0c.5-1.506 1.759-2.221 3.31-2.706 1.691-.529 3.523-.6 4.753-1.069a1.812 1.812 0 0 0 .974-.978l.032-.074z" transform="translate(-5.871 -966.479) translate(0)" />
                  </g>
                </svg>
              </ListItemIcon>
              <ListItemText style={{ color: 'white' }} primary={'Super Merchants'} />
            </ListItem>
          </NavLink> */}
          <NavLink
            className={styles.navlink}
            activeClassName={styles.navlink_active}
            to="/customers"
          >
            <ListItem button>
              <ListItemIcon>
                {" "}
                <FaceIcon style={{ color: "white" }} />{" "}
              </ListItemIcon>
              <ListItemText
                style={{ color: "white" }}
                sx={{ fontWeight: 200 }}
                primary={"Merchants"}
              />
            </ListItem>
          </NavLink>

          <NavLink
            className={styles.navlink}
            activeClassName={styles.navlink_active}
            to="/kyc"
          >
            <ListItem button>
              <ListItemIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M14 18.597v.403h-10v-.417c-.004-1.112.044-1.747 1.324-2.043 1.403-.324 2.787-.613 2.122-1.841-1.973-3.637-.563-5.699 1.554-5.699 2.077 0 3.521 1.985 1.556 5.699-.647 1.22.688 1.51 2.121 1.841 1.284.297 1.328.936 1.323 2.057zm6-9.597h-4v2h4v-2zm0 4h-4v2h4v-2zm0 4h-4v2h4v-2zm-4-12v2h6v14h-20v-14h6v-2h-8v18h24v-18h-8zm-6-4v6h4v-6h-4zm2 4c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1z" />
                </svg>
              </ListItemIcon>
              <ListItemText style={{ color: "white" }} primary={"KYC"} />
            </ListItem>
          </NavLink>

          <NavLink
            className={styles.navlink}
            activeClassName={styles.navlink_active}
            to="/users"
          >
            <ListItem button>
              <ListItemIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30px"
                  height="30px"
                  viewBox="0 0 34.388 27.987"
                >
                  <g>
                    <path
                      fill="#fff"
                      fillRule="evenodd"
                      stroke="#fff"
                      strokeWidth="0.25px"
                      d="M19.957 966.6a7.572 7.572 0 0 0-7.561 7.561 13.7 13.7 0 0 0 1.8 6.589 9.534 9.534 0 0 0 1.223 1.671 2.349 2.349 0 0 1-.144.569c-.07.157-.1.155-.108.159-.694.263-2.735.435-4.666 1.039s-3.951 1.82-4.48 4.288a.776.776 0 0 0 .238.738 20.889 20.889 0 0 0 27.408 0 .776.776 0 0 0 .254-.612 15.725 15.725 0 0 0 5.957-3.353.776.776 0 0 0 .238-.738 4.916 4.916 0 0 0-3.671-3.525c-1.558-.487-3.2-.634-3.7-.823-.008 0 0 .015-.046-.068a1.234 1.234 0 0 1-.087-.267 7.72 7.72 0 0 0 .984-1.345 11.023 11.023 0 0 0 1.449-5.319 6.171 6.171 0 0 0-10.145-4.72 7.534 7.534 0 0 0-4.939-1.84zm0 1.551a6 6 0 0 1 6.013 6.009 12.459 12.459 0 0 1-1.586 5.809c-1.045 1.8-2.493 3.032-4.428 3.032s-3.379-1.235-4.424-3.032a12.464 12.464 0 0 1-1.585-5.809 6 6 0 0 1 6.009-6.009zm8.911.392a4.607 4.607 0 0 1 4.62 4.62 9.784 9.784 0 0 1-1.238 4.539 3.97 3.97 0 0 1-3.382 2.332 3.285 3.285 0 0 1-2.325-.973 13.661 13.661 0 0 0 .979-4.9 7.516 7.516 0 0 0-1.559-4.589 4.593 4.593 0 0 1 2.906-1.028zm-3.008 11.973a4.837 4.837 0 0 0 3.008 1.073 4.687 4.687 0 0 0 2.484-.712 1.684 1.684 0 0 0 .836.738c1.035.393 2.47.442 3.79.854a3.418 3.418 0 0 1 2.494 1.947 14.246 14.246 0 0 1-5.06 2.72 6.549 6.549 0 0 0-3.993-2.945c-1.93-.6-3.971-.776-4.665-1.039-.062-.024-.094-.041-.167-.181a1.887 1.887 0 0 1-.147-.483 9.475 9.475 0 0 0 1.284-1.735q.069-.118.134-.238zm-9.141 3.036a5.723 5.723 0 0 0 6.44.026c.016.034.032.069.05.1a1.919 1.919 0 0 0 1 .923c1.23.466 3.061.54 4.751 1.069 1.551.485 2.813 1.2 3.311 2.706a19.461 19.461 0 0 1-24.619 0c.5-1.506 1.759-2.221 3.31-2.706 1.691-.529 3.523-.6 4.753-1.069a1.812 1.812 0 0 0 .974-.978l.032-.074z"
                      transform="translate(-5.871 -966.479) translate(0)"
                    />
                  </g>
                </svg>
              </ListItemIcon>
              <ListItemText style={{ color: "white" }} primary={"Users"} />
            </ListItem>
          </NavLink>

          <NavLink
            className={styles.navlink}
            activeClassName={styles.navlink_active}
            to="/menu"
          >
            <ListItem button>
              <ListItemIcon>
                <svg
                  width="30"
                  height="30"
                  xmlns="http://www.w3.org/2000/svg"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                >
                  <path
                    d="M24 18v1h-24v-1h24zm0-6v1h-24v-1h24zm0-6v1h-24v-1h24z"
                    fill="#1040e2"
                  />
                  <path d="M24 19h-24v-1h24v1zm0-6h-24v-1h24v1zm0-6h-24v-1h24v1z" />
                </svg>
              </ListItemIcon>
              <ListItemText style={{ color: "white" }} primary={"Menu"} />
            </ListItem>
          </NavLink>

          <NavLink
            className={styles.navlink}
            activeClassName={styles.navlink_active}
            to="/orders"
          >
            <ListItem button>
              <ListItemIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30px"
                  height="30px"
                  viewBox="0 0 34.6 30.746"
                >
                  <defs>
                    <style>.cls-1</style>
                  </defs>
                  <g id="Group_21" transform="translate(-4075.707 -4873.274)">
                    <path
                      id="Path_1496"
                      d="M4112.431 4901.934h-11.064v1.384h11.064zm-.377 9.692h-10.688v1.384h9.516a9.607 9.607 0 0 1 1.172-1.384zm-10.688 6.23h7.79a9.12 9.12 0 0 1 .2-1.384h-7.994zm-3.1-15.968h-1.495v1.494h1.495zm14.167 4.994h-11.064v1.383h11.064zm-14.167-.046h-1.495v1.494h1.495zm0 4.735h-1.495v1.494h1.495zm0 4.778h-1.495v1.494h1.495z"
                      className="cls-1"
                      transform="translate(-16.73 -22.729)"
                    />
                    <path
                      id="Path_1496-2"
                      d="M4179.782 4949.858a.8.8 0 0 0-.642.853 6.65 6.65 0 1 1-3.068-4.919 1 1 0 0 0 1.273-.015.8.8 0 0 0-.2-1.12.824.824 0 0 0-.071-.045 8.178 8.178 0 1 0 3.591 5.937.756.756 0 0 0-.806-.7c-.027 0-.055.005-.082.01z"
                      className="cls-1"
                      transform="translate(-70.419 -55.573)"
                    />
                    <path
                      id="Path_1496-3"
                      d="M4184.324 4960.08a.769.769 0 1 0-1.106 1.068l.019.019 3.064 3.065a.767.767 0 0 0 1.087 0l7.912-7.911a.769.769 0 1 0-1.068-1.105l-.019.019-7.368 7.368z"
                      className="cls-1"
                      transform="translate(-85.228 -64.917)"
                    />
                    <path
                      id="Path_1497"
                      d="M4098.706 4886.741v-11.588a.338.338 0 0 0-.333-.342h-20.786a.338.338 0 0 0-.342.334v23.03a.338.338 0 0 0 .334.342h15.2a9.281 9.281 0 0 0 .581 1.537h-15.773a1.88 1.88 0 0 1-1.879-1.879v-23.022a1.88 1.88 0 0 1 1.879-1.879h20.778a1.879 1.879 0 0 1 1.879 1.879v11.147a9.47 9.47 0 0 0-1.538.441z"
                      className="cls-1"
                    />
                  </g>
                </svg>
              </ListItemIcon>
              <ListItemText style={{ color: "white" }} primary={"Orders"} />
            </ListItem>
          </NavLink>

          <NavLink
            className={styles.navlink}
            activeClassName={styles.navlink_active}
            to="/settlements"
          >
            <ListItem button>
              <ListItemIcon>
                {" "}
                <FaceIcon style={{ color: "white" }} />{" "}
              </ListItemIcon>
              <ListItemText
                style={{ color: "white" }}
                primary={"Settlements"}
              />
            </ListItem>
          </NavLink>

          <ListItem button onClick={() => props.onLogout()}>
            <ListItemIcon>
              {" "}
              <LogoutIcon style={{ color: "white" }} />{" "}
            </ListItemIcon>
            <ListItemText style={{ color: "white" }} primary={"Logout"} />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logoutUser()),
  };
};

export default connect(null, mapDispatchToProps)(PermanentDrawerRight);
