import React, { useEffect } from "react";
import { addDays } from "date-fns";
import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Button from "@mui/material/Button";
import moment from "moment";

const DateRangeComp = (props) => {
  const [isOpen, setisOpen] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date() + 1,
      key: "selection",
    },
  ]);

  useEffect(() => {
    if (props.open !== undefined && props.open !== null && props.open !== "") {
      setisOpen(props.open);
    }
  }, [props.open]);

  const setDates = () => {
    props.handledates({
      startDate: state[0].startDate,
      endDate: state[0].endDate,
    });
    setisOpen(false);
  };
  const handleOpen = () => {
    setisOpen(true);
  };

  const handleClose = () => {
    setisOpen(false);
  };
  return (
    <>
      <div style={props.style || {}}>
        <Popup
          trigger={
            <div>
              <i
                style={{
                  fontSize: "30px",
                  marginLeft: "10px",
                  color: "#757575",
                  cursor: "pointer",
                  backgroundColor: "#ededf4",
                }}
                class="fa fa-calendar"
                aria-hidden="true"
              ></i>
            </div>
          }
          position={props.position || undefined}
          content={<button onClick={handleClose}>click to close</button>}
          on="click"
          open={isOpen}
          onOpen={handleOpen}
          className="backgroundblue"
        >
          <DateRangePicker
            onChange={(item) => setState([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={state}
            style={{
              backgroundColor: "#ededf4",
            }}
            direction={props.direction || "horizontal"}
            disabledDay={(day) => {
              return props.DisableCurrent
                ? moment(day).isAfter(new Date())
                : false;
            }}
          />
          <Button
            className="btn1 btnwidth"
            style={{ display: "block" }}
            variant="contained"
            onClick={setDates}
          >
            Apply
          </Button>
        </Popup>
      </div>
    </>
  );
};

export default DateRangeComp;
