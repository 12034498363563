import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Paper } from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MobileFriendly from "@mui/icons-material/MobileFriendly";
import Web from "@mui/icons-material/Web";

import EditIcon from "@mui/icons-material/Edit";
import Radio from "@mui/material/Radio";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";

import CopyAll from "@mui/icons-material/CopyAll";
import Tooltip from "@mui/material/Tooltip";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import AxiosBase from "../../../networkRequest/axiosBase";
import "./index.css";

import AddressList from "./address";
import OrderList from "./order";
import TransactionList from "./transaction";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    padding: 9,
  },
});
const CssTextArea = styled(TextareaAutosize)({
  "input#comment-text": {
    width: "100%",
    height: "4.3rem",
  },
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function CustomerForm(props) {
  const { id } = useParams("id");
  const [data, setData] = React.useState({});
  const [enablemerchant, setenablemerchant] = React.useState(true);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = React.useState("panel1");
  const [commentmandatory, setcommentmandatory] = React.useState(false);
  const [update, setupdate] = useState(false);
  const CHARACTER_LIMIT = 50;
  const ZIP_LIMIT = 6;
  const mobile_LIMIT = 16;

  const handleChange = (panel) => (event) => {
    // setExpanded(panel);
    if (panel == expanded) {
      setExpanded("");
    } else {
      setExpanded(panel);
    }
  };

  const [firsterror, setFirstError] = useState({
    error: false,
    message: "",
  });

  const [numbererror, setNumberError] = useState({
    error: false,
    message: "",
  });

  const [BusinessEmailError, setBusinessEmailError] = useState({
    error: false,
    message: "",
  });

  useEffect(() => {
    AxiosBase.get(`/admin/customer/${id}`).then((response) => {
      setData(response.data.data);
    });
  }, []);

  const handleEdit = () => {
    setenablemerchant(false);
  };
  const handleCancel = () => {
    setenablemerchant(true);
  };
  const handleUpdate = async () => {
    if (data.name != "" && firsterror.error) {
      setupdate(!update);
      return (
        toast(firsterror.message.toString()),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    } else if (numbererror.error) {
      setupdate(!update);
      return (
        toast(numbererror.message.toString()),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    } else if (BusinessEmailError.error) {
      setupdate(!update);
      return (
        toast(BusinessEmailError.message.toString()),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    } else if (data.mobile_no == "" || data.email_id == "" || data.name == "") {
      setupdate(!update);
      return (
        toast("Please fill customer information"),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    }
    AxiosBase.put(`/admin/customer/${id}`, data)
      .then((response) => {
        setupdate(!update);
        return toast("Customer Data Updated Successfully.", {
          type: "success",
          autoClose: 2000,
        });
      })
      .catch((err) => {
        console.log(err);
        setupdate(!update);
        return toast(err?.response?.data?.message || "Something went wrong!", {
          type: "error",
          autoClose: 2000,
        });
      });
  };

  if (loading) {
    return (
      <div className="no_data_found loader_data_tbl">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <Box>
        <h1 className="merchant_title">Customers</h1>
      </Box>
      <div
        className="merchant_detail_tab_main_page"
        style={{ marginTop: "1rem" }}
      >
        <Accordion
          className="expanded_bar margintop"
          sx={{ mt: "20px" }}
          expanded={expanded === "panel1"}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography onClick={handleChange("panel1")}>
              Basic Information
            </Typography>

            <Tooltip title="This Merchant onboarding done by Admin Pannel">
              <IconButton color="primary">
                <Web className="merchant_inpt_icon" />
              </IconButton>
            </Tooltip>

            <Stack className="topmargin10" spacing={2} alignItems="center">
              <Stack direction="row" spacing={1}>
                {data?.is_active ? (
                  <Chip
                    variant="outlined"
                    size="large"
                    label="Active"
                    color="success"
                  />
                ) : (
                  <Tooltip title="Only active customer will be able to login into the mobile app and web app">
                    <Chip
                      size="large"
                      variant="outlined"
                      label="Inactive"
                      color="success"
                      icon=""
                    />
                  </Tooltip>
                )}

                {data?.is_locked ? (
                  <Tooltip title="The account Lock means more than three incorrect OTP attempts were made. The account will be automatically unlocked after 24 hours or click here 'Unlock' to Lift restrictions.                  ">
                    <Chip
                      size="large"
                      variant="outlined"
                      label="Locked"
                      color="primary"
                    />
                  </Tooltip>
                ) : (
                  <></>
                )}
              </Stack>
            </Stack>

            <Button
              onClick={!loading ? handleEdit : () => {}}
              className="merchant_list_add_new_merchant branch_add_btn kycedit"
              variant="contained"
              startIcon={<EditIcon />}
            >
              Edit Customer Information
            </Button>
          </AccordionSummary>
          <AccordionDetails>
            {loading ? (
              <div className="no_data_found loader_data_tbl">
                <CircularProgress />
              </div>
            ) : (
              <Grid container spacing={2} className="merchant_main_grid">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Name</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="First name"
                        fullWidth
                        helperText={
                          firsterror.error == true ? firsterror.message : ""
                        }
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 15,
                        }}
                        value={data?.name}
                        required
                        onChange={(e) => {
                          if (
                            e.target.value.length < 3 ||
                            e.target.value.length > 15
                          ) {
                            setFirstError({
                              error: true,
                              message:
                                "First Name must be between 3 to 15 characters",
                            });
                          } else {
                            setFirstError({ error: false, message: "" });
                          }
                          setData({
                            ...data,
                            name: e.target.value,
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className="create_merchn_inpt_icon">
                              <IconButton color="primary">
                                <PersonOutlinedIcon className="merchant_inpt_icon" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className="sep_inpt_sec">
                    <p>
                      Mobile Number<span>*</span>
                    </p>

                    <div className="inp_sep_div">
                      {data?.mobile_no && (
                        <MuiPhoneNumber
                          className="create_merchant_input_feild mui_number_field"
                          data-cy="user-phone"
                          defaultCountry={"ae"}
                          variant="outlined"
                          disabled={enablemerchant}
                          inputProps={{
                            maxLength: mobile_LIMIT,
                          }}
                          value={data?.mobile_no?.toString()}
                          onChange={(val, countrycode) => {
                            if (!val) return;
                            val = val.replace(/-|\s/g, "");
                            if (val.length < 12 || val.length > 16) {
                              setNumberError({
                                error: true,
                                message: "Enter valid Number",
                              });
                            } else {
                              setNumberError({ error: false, message: "" });
                            }
                            setData({
                              ...data,
                              mobile_no: val,
                              country_code: countrycode.dialCode,
                            });
                          }}
                        />
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className="sep_inpt_sec">
                    <p>
                      Email<span>*</span>
                    </p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        type="email"
                        disabled={enablemerchant}
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="Business Email"
                        fullWidth
                        value={data?.email}
                        required
                        inputProps={{
                          maxLength: CHARACTER_LIMIT,
                        }}
                        error={
                          !data?.email?.match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                          ) && data?.email_id !== ""
                        }
                        helperText={
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                            data?.email
                          ) || data?.email === ""
                            ? " "
                            : "Business email must be between 6 to 50 characters"
                        }
                        onChange={(e) => {
                          if (
                            !e.target.value.match(
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            ) &&
                            e.target.value !== ""
                          ) {
                            setBusinessEmailError({
                              error: true,
                              message: "Please enter valid business email id",
                            });
                          } else {
                            setBusinessEmailError({
                              error: false,
                              message: "",
                            });
                          }
                          setData({
                            ...data,
                            email: e.target.value,
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className="create_merchn_inpt_icon">
                              <IconButton color="primary">
                                <img
                                  src="/images/icon/top-email.svg"
                                  alt=""
                                  style={{ width: "25px", height: "25px" }}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment className="create_merchn_inpt_icon">
                              {data?.is_email_verified ? (
                                <Tooltip title="Email Verified">
                                  <IconButton color="primary">
                                    <img
                                      src="/images/icon/8.png"
                                      alt=""
                                      style={{ width: "25px", height: "25px" }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Email Not Verified.Please click here to resend email.">
                                  <IconButton color="primary">
                                    <img
                                      src="/images/icon/2.png"
                                      alt=""
                                      style={{ width: "25px", height: "25px" }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Wallet Amount</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="Wallet Amount"
                        fullWidth
                        type="number"
                        disabled={enablemerchant}
                        value={data?.wallet_amount}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            wallet_amount: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                {enablemerchant ? (
                  <></>
                ) : (
                  <div className="statusbar">
                    <p>Status:</p>
                    <Grid container spacing={2} className="merchant_main_grid">
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        className="merchant_grid_sec"
                      >
                        <div className="sep_inpt_sec ">
                          <p>
                            Is Active:<span>*</span>
                          </p>
                          <div className="inp_sep_div">
                            <FormControl>
                              <RadioGroup
                                row
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={
                                  !data.is_active ? "InActive" : "Active"
                                }
                                name="row-radio-buttons-group"
                                onChange={(e) => {
                                  setData({
                                    ...data,
                                    is_active:
                                      e.target.value == "InActive"
                                        ? false
                                        : true,
                                  });
                                }}
                              >
                                <Tooltip title="Only active merchants will be able to login into the mobile app and web app.">
                                  <FormControlLabel
                                    disabled={enablemerchant}
                                    value="Active"
                                    control={<Radio />}
                                    label="Active"
                                  />
                                </Tooltip>
                                <Tooltip title="Only active merchants will be able to login into the mobile app and web app.">
                                  <FormControlLabel
                                    disabled={enablemerchant}
                                    value="InActive"
                                    control={<Radio />}
                                    label="InActive"
                                  />
                                </Tooltip>
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        className="merchant_grid_sec"
                      >
                        <Tooltip title={data.is_locked ? "" : ""}>
                          <div
                            className="sep_inpt_sec "
                            style={{ cursor: "pointer" }}
                          >
                            <p>Is Lock: *</p>

                            <div className="inp_sep_div">
                              <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  defaultValue={
                                    !data.is_locked ? "Unlock" : "Lock"
                                  }
                                  name="row-radio-buttons-group"
                                  onChange={(e) => {
                                    setData({
                                      ...data,
                                      is_locked:
                                        e.target.value == "Lock" ? true : false,
                                    });
                                  }}
                                >
                                  <Tooltip title="The account Lock means more than three incorrect OTP attempts were made. The account will be automatically unlocked after 24 hours or click here 'Unlock' to Lift restrictions.">
                                    <FormControlLabel
                                      disabled={enablemerchant}
                                      value="Lock"
                                      control={<Radio />}
                                      label="Lock"
                                    />
                                  </Tooltip>{" "}
                                  <Tooltip title="The account Lock means more than three incorrect OTP attempts were made. The account will be automatically unlocked after 24 hours or click here 'Unlock' to Lift restrictions.">
                                    <FormControlLabel
                                      disabled={enablemerchant}
                                      value="Unlock"
                                      control={<Radio />}
                                      label="Unlock"
                                    />
                                  </Tooltip>{" "}
                                </RadioGroup>
                              </FormControl>
                            </div>
                          </div>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Grid>
            )}

            {!enablemerchant && !loading ? (
              <div className="merchant_edit_button_dev">
                <Button
                  className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                  variant="contained"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                {props?.menu_permissions?.EDIT && (
                  <Button
                    className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                    variant="contained"
                    onClick={handleUpdate}
                  >
                    Update
                  </Button>
                )}
              </div>
            ) : (
              <></>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          className="expanded_bar"
          sx={{ mt: "20px" }}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography onClick={handleChange("panel2")}>Address</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AddressList customer_id={id} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          className="expanded_bar"
          sx={{ mt: "20px" }}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography onClick={handleChange("panel3")}>Orders</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <OrderList customer_id={id} menu_permissions={props?.menu_permissions} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          className="expanded_bar"
          sx={{ mt: "20px" }}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography onClick={handleChange("panel4")}>
              Transaction
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TransactionList customer_id={id} />
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default CustomerForm;
