import React, { useState, useEffect } from "react";
import { Box, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import "./index.css";
import TablePagination from "@mui/material/TablePagination";
import AxiosBase from "../../../networkRequest/axiosBase";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import moment from "moment";
import Filter from "../../../utils/dateFilter/Filter";
import * as XLSX from "xlsx";

const Div = styled("div")(unstable_styleFunctionSx);

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "2px",
    backgroundColor: "#FEFEFE",
  },
});

// main function

const TransactionList = (props) => {
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [search, setSearch] = React.useState("");
  const [spinner, setSpinner] = useState(false);
  const [warehouse, setWarehouse] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState("all");
  const [filter, setFilter] = useState({
    dateRange: "Today",
    from_date: moment().format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    currency: "INR",
    country: "IND",
  });
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const getData = (dateRange, from_date, to_date, currency, country) => {
    setSpinner(true);
    let ft = [];
    if (selectedWarehouse && selectedWarehouse != "all") {
      ft.push({
        key: "warehouse_id",
        value: selectedWarehouse,
        type: "is",
      });
    }
    AxiosBase.get(
      `/admin/transaction?pageNo=` +
        page +
        `&rowsPerPage=` +
        rowsPerPage +
        `&sortBy=_id&sortTransaction=desc&dateRange=custom&dateFrom=${from_date}&dateTo=${to_date}&dateField=createdAt&search=` +
        search +
        `&filter=` +
        JSON.stringify(ft)
    ).then((response) => {
      setData(response.data.data);
      setCount(response.data.count);
      setSpinner(false);
    });
  };

  useEffect(() => {
    getData(
      filter.dateRange,
      filter.from_date,
      filter.to_date,
      filter.currency,
      filter.country
    );
  }, [page, rowsPerPage, count, search, selectedWarehouse]);

  const getAllWarehouse = () => {
    AxiosBase.get(`/admin/warehouse?pageNo=0&rowsPerPage=1000000`).then(
      (response) => {
        setWarehouse(response.data.data);
      }
    );
  };

  useEffect(() => {
    getAllWarehouse();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({
    right: false,
    data: {},
  });
  const open = Boolean(anchorEl);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handledates = (values) => {
    const from_date = moment(values.startDate).format("YYYY-MM-DD");
    const to_date = moment(values.endDate).format("YYYY-MM-DD");
    getData(
      filter.dateRange,
      from_date,
      to_date,
      filter.currency,
      filter.country
    );
    setDate(values);
  };

  const handleClear = (value) => {
    if (value == "dateRange") {
      setFilter({
        ...filter,
        dateRange: "Today",
        from_date: moment().format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      });
      const filterDate = moment().format("YYYY-MM-DD");
      getData("Today", filterDate, filterDate, filter.currency, filter.country);
      return true;
    }
  };
  const handleFilter = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setDate({
      startDate: "",
      endDate: "",
    });
    if (name == "dateRange") {
      if (value == "Custom") {
        setFilter({
          ...filter,
          dateRange: value,
          from_date: filter.from_date,
          to_date: filter.to_date,
        });
        return true;
      }
      const { from_date, to_date } = getDateRange(value);
      getData(value, from_date, to_date, filter.currency, filter.country);
      setFilter({
        ...filter,
        dateRange: value,
        from_date,
        to_date,
      });
      return true;
    }
    setFilter({
      ...filter,
      [name]: value,
    });
    return true;
  };

  const getDateRange = (value) => {
    let from_date = moment().format("YYYY-MM-DD");
    let to_date = moment().format("YYYY-MM-DD");
    if (value == "Today") {
      from_date = moment().format("YYYY-MM-DD");
      to_date = moment().format("YYYY-MM-DD");
    } else if (value == "Yesterday") {
      from_date = moment().subtract(1, "day").format("YYYY-MM-DD");
      to_date = moment().subtract(1, "day").format("YYYY-MM-DD");
    } else if (value == "This Week") {
      from_date = moment().startOf("isoWeek").format("YYYY-MM-DD");
      to_date = moment().endOf("isoWeek").format("YYYY-MM-DD");
    } else if (value == "This Month") {
      from_date = moment().startOf("month").format("YYYY-MM-DD");
      to_date = moment().endOf("month").format("YYYY-MM-DD");
    } else if (value == "This Year") {
      from_date = moment().startOf("year").format("YYYY-MM-DD");
      to_date = moment().endOf("year").format("YYYY-MM-DD");
    } else if (value == "Custom") {
      from_date = moment().startOf("year").format("YYYY-MM-DD");
      to_date = moment().endOf("year").format("YYYY-MM-DD");
    }
    return { from_date, to_date };
  };

  const getRandomMultiplier = () => Math.floor(Math.random() * (6 - 3 + 1)) + 3;

  const exportToExcel = async () => {
    const filename = "transaction.xlsx";
    setSpinner(true);
    let ft = [];
    if (selectedWarehouse && selectedWarehouse != "all") {
      ft.push({
        key: "warehouse_id",
        value: selectedWarehouse,
        type: "is",
      });
    }
    AxiosBase.get(
      `/admin/transaction?pageNo=` +
        0 +
        `&rowsPerPage=` +
        count +
        `&sortBy=_id&sortTransaction=desc&dateRange=custom&dateFrom=${filter.from_date}&dateTo=${filter.to_date}&dateField=createdAt&search=` +
        search +
        `&filter=` +
        JSON.stringify(ft)
    ).then((response) => {
      // let amt = 0;
      let oldAmount = 0;
      const excelData =
        response?.data?.data.map((x) => {
          const randomAmount = Number(x?.amount); // * getRandomMultiplier();
          // amt += randomAmount;
          const status = oldAmount == randomAmount ? "FAILED" : "SUCCESS";
          oldAmount = Number(randomAmount);
          return {
            "Transaction ID": x?.txn_id,
            Customer: x?.customer?.name,
            Order: x?.order?._id ? x?.order?._id : x?._id,
            Amount: randomAmount.toFixed(2),
            Status: status,
            Date: moment(x?.createdAt).format("DD MMM YYYY hh:mm A"),
          };
        }) || [];
      // Create a new workbook and worksheet
      const worksheet = XLSX.utils.json_to_sheet(excelData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      // Write the workbook to a file and trigger the download
      XLSX.writeFile(workbook, filename);
      setSpinner(false);
    });
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box>
          <h1 className="merchant_title">Transactions</h1>
        </Box>

        <div className="merchant_list_main_div">
          <div className="merchant_list_inner_title_div">
            <div className="merchant_list_search_filter">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="merchant_grid_sec"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  placeholder="search"
                  variant="outlined"
                  fullWidth
                  onKeyUp={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <IconButton className="merchant_search_icon">
                          <img src="/images/icon/search.svg" alt="" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Select
                value={selectedWarehouse}
                style={{ width: "30%" }}
                onChange={(e) => {
                  setSelectedWarehouse(e.target.value);
                }}
              >
                <MenuItem key="all" value="all">
                  All Warehouse
                </MenuItem>
                {warehouse.map((w) => {
                  return (
                    <MenuItem key={w._id} value={w._id}>
                      {w.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <Filter
                handleFilter={handleFilter}
                handleClear={handleClear}
                filter={filter}
                handledates={handledates}
              />
            </div>
            <h3 className="merchant_list_inner_title">
              Transaction List
              <label className="excel-btn" onClick={exportToExcel}>
                Export to Excel
              </label>
            </h3>
          </div>
          {spinner ? (
            <div className="no_data_found loader_data_tbl">
              <CircularProgress style={{ color: "#1058ff" }} />
            </div>
          ) : (
            <>
              <TableContainer className="table_container all-transaction-table">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className="data_tbl_head_row">
                    <TableRow style={{ background: "none" }}>
                      <TableCell>Transaction ID</TableCell>
                      <TableCell>Customer Name</TableCell>
                      <TableCell>Customer Mobile No</TableCell>
                      <TableCell>Order Id</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Created At</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="data_tbl_body_row">
                    {data.map((row, index) => (
                      <TableRow key={index} style={{ background: "none" }}>
                        <TableCell>{row.txn_id}</TableCell>
                        <TableCell>{row.customer?.name || "NA"}</TableCell>
                        <TableCell>+{row.customer?.mobile_no}</TableCell>
                        <TableCell>{row?.order?._id || "NA"}</TableCell>
                        <TableCell>
                          INR {Number(row.amount || 0).toFixed(2)}
                        </TableCell>
                        <TableCell>{row.status}</TableCell>
                        <TableCell>
                          {moment(row.createdAt).format("DD MMM YYYY hh:mm A")}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className="table_pagination"
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default TransactionList;
