import React, { useState, useEffect } from "react";
import { Box, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { styled } from "@mui/material/styles";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import "./index.css";
import TablePagination from "@mui/material/TablePagination";
import AxiosBase from "../../../../networkRequest/axiosBase";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

const Div = styled("div")(unstable_styleFunctionSx);

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "2px",
    backgroundColor: "#FEFEFE",
  },
});

// main function

const AddressList = (props) => {
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [search, setSearch] = React.useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    AxiosBase.get(
      `/admin/address/customer/${props?.customer_id}?pageNo=` +
        page +
        `&rowsPerPage=` +
        rowsPerPage +
        `&sortBy=_id&sortOrder=desc`
    ).then((response) => {
      console.log(response.data);
      setData(response.data.data);
      setCount(response.data.count);
    });
  }, [page, rowsPerPage, count, search]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <div className="">
          <TableContainer className="table_container address-table">
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row">
                <TableRow style={{ background: "none" }}>
                  <TableCell>Type</TableCell>
                  <TableCell>Address</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="data_tbl_body_row">
                {data.map((row, index) => (
                  <TableRow key={row?._id} style={{ background: "none" }}>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>{row.address}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className="table_pagination"
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default AddressList;
