import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./ConfigurationTab.css";
import Banner from "./banner";
import LaundryServices from "./laundryServices";
import TimeSlot from "./timeSlot";
import Faq from "./faq";
import Offer from "./offer";
import Coupon from "./coupon";
import MobileAppConfig from "./mobileAppConfig";
import { useHistory } from "react-router-dom";
import NavbarHoc from "../../pages/navhoc";
import Config from "../../config.json";
import { connect } from "react-redux";
import reduxStore from "../../redux/stores";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: "15px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ConfigurationTab = (props) => {
  const [rolePermission, setRolePermission] = React.useState([]);
  const [allPermission, setAllPermission] = React.useState([]);
  const [spinner, setSpinner] = useState(false);
  const history = useHistory();
  const redirecttodetail = (_id) => {
    history.push(`config?id=${_id}`);
  };

  let authData = localStorage.getItem("persist:root")
    ? JSON.parse(localStorage.getItem("persist:root"))
    : null;
  const menu_permissions = authData.auth
    ? JSON.parse(authData.auth)?.user_data?.menu_permissions
    : {};

  useEffect(() => {}, []);

  return (
    <NavbarHoc
      id={Config.PermissionId.Configuration}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
        setAllPermission(e.AllData);
      }}
    >
      <Box>
        <h1 className="merchant_title">Master Config</h1>
      </Box>
      <div className="merchant_list_main_div configuration_tab_div">
        <Box
          className="configuration_tab"
          sx={{ borderColor: "divider", ml: "-15px" }}
        >
          <Tabs
            value={props.value}
            // onChange={props.handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              id="1"
              label="Banner"
              onClick={() => redirecttodetail(0)}
              {...a11yProps(0)}
            />
            <Tab
              id="2"
              label="Our Services"
              onClick={() => redirecttodetail(1)}
              {...a11yProps(1)}
            />
            <Tab
              id="3"
              label="Time Slot"
              onClick={() => redirecttodetail(2)}
              {...a11yProps(2)}
            />
            <Tab
              id="4"
              label="Offer"
              onClick={() => redirecttodetail(3)}
              {...a11yProps(3)}
            />
            <Tab
              id="5"
              label="Coupon"
              onClick={() => redirecttodetail(4)}
              {...a11yProps(4)}
            />
            <Tab
              id="6"
              label="Customer App"
              onClick={() => redirecttodetail(5)}
              {...a11yProps(5)}
            />
            <Tab
              id="7"
              label="Rider App"
              onClick={() => redirecttodetail(6)}
              {...a11yProps(6)}
            />
            <Tab
              id="8"
              label="Help FAQ"
              onClick={() => redirecttodetail(7)}
              {...a11yProps(7)}
            />
          </Tabs>
        </Box>
        <TabPanel selectedIndex="true" value={props.value} index={0}>
          <Banner menu_permissions={menu_permissions?.Banner} />
        </TabPanel>
        <TabPanel value={props.value} index={1}>
          <LaundryServices menu_permissions={menu_permissions?.OurServices} />
        </TabPanel>
        <TabPanel value={props.value} index={2}>
          <TimeSlot menu_permissions={menu_permissions?.TimeSlot} />
        </TabPanel>
        <TabPanel value={props.value} index={3}>
          <Offer menu_permissions={menu_permissions?.Offer} />
        </TabPanel>
        <TabPanel value={props.value} index={4}>
          <Coupon menu_permissions={menu_permissions?.Coupon} />
        </TabPanel>
        <TabPanel value={props.value} index={5}>
          <MobileAppConfig type="customer" menu_permissions={menu_permissions?.CustomerApp} />
        </TabPanel>
        <TabPanel value={props.value} index={6}>
          <MobileAppConfig type="rider" menu_permissions={menu_permissions?.RiderApp} />
        </TabPanel>
        <TabPanel value={props.value} index={7}>
          <Faq menu_permissions={menu_permissions?.Faq} />
        </TabPanel>
      </div>
    </NavbarHoc>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData,
  };
};

export default connect(mapStateToProps, null)(ConfigurationTab);
