import React, { useEffect, useState } from "react";
import OrderCount from "./OrderCount";
import OrderAmount from "./OrderAmount";
import "./index.scss";
import moment from "moment";
import AxiosBase from "../../../networkRequest/axiosBase";
import CircularProgress from "@mui/material/CircularProgress";
import Filter from "../../../utils/dateFilter/Filter";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const allStatus = [
  {
    value: "INITIATE",
    label: "Initiate",
  },
  {
    value: "CANCELLED",
    label: "Cancelled",
  },
  {
    value: "RECEIVED_BY_RIDER_FROM_CUSTOMER",
    label: "Received By Rider From Customer",
  },
  {
    value: "WAITING_RECEIVED_BY_TRANSPORTER_FROM_RIDER",
    label: "Waiting Received By Rider From Customer",
  },
  {
    value: "RECEIVED_BY_TRANSPORTER_FROM_RIDER",
    label: "Received By Transporter From Rider",
  },
  {
    value: "WAITING_RECEIVED_BY_WAREHOUSE",
    label: "Waiting Received By Warehouse",
  },
  {
    value: "RECEIVED_BY_WAREHOUSE",
    label: "Received By Warehouse",
  },
  {
    value: "READY_FOR_SERVICE",
    label: "Ready For Service",
  },
  {
    value: "READY_FOR_RETURN",
    label: "Ready For Return",
  },
  {
    value: "RECEIVED_BY_TRANSPORTER_FROM_WAREHOUSE",
    label: "Received By Transporter From Warehouse",
  },
  {
    value: "WAITING_RECEIVED_BY_RIDER_FROM_TRANSPORTER",
    label: "Waiting Received By Rider From Transporter",
  },
  {
    value: "RECEIVE_BY_RIDER_FROM_TRANSPORTER",
    label: "Received By Rider From Transporter",
  },
  {
    value: "COMPLETED",
    label: "Completed",
  },
];

export default function CustomerOrderChart(props) {
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("all");

  const [filter, setFilter] = useState({
    dateRange: "Today",
    from_date: moment().format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    currency: "INR",
    country: "IND",
  });
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });

  const getData = (
    dateRange,
    from_date,
    to_date,
    currency,
    country,
    from = ""
  ) => {
    setSpinner(true);
    let ft = [];
    if (selectedStatus && selectedStatus != "all") {
      ft.push({
        key: "status",
        value: selectedStatus,
        type: "is",
      });
    }
    AxiosBase.get(
      `/admin/customer/order-chart?customer_id=${props?.customer_id}&dateRange=custom&dateFrom=${from_date}&dateTo=${to_date}&dateField=createdAt&filter=` +
        JSON.stringify(ft)
    )
      .then((response) => {
        setData(response.data.data);
        setSpinner(false);
      })
      .catch((err) => {
        setSpinner(false);
      });
  };

  useEffect(() => {
    if (props?.customer_id) {
      getData(
        filter.dateRange,
        filter.from_date,
        filter.to_date,
        filter.currency,
        filter.country,
        "start"
      );
    }
  }, [props?.customer_id, selectedStatus]);

  const handledates = (values) => {
    const from_date = moment(values.startDate).format("YYYY-MM-DD");
    const to_date = moment(values.endDate).format("YYYY-MM-DD");
    setFilter({
      ...filter,
      from_date,
      to_date,
    });
    getData(
      filter.dateRange,
      from_date,
      to_date,
      filter.currency,
      filter.country
    );
    setDate(values);
  };

  const handleClear = (value) => {
    if (value == "dateRange") {
      setFilter({
        ...filter,
        dateRange: "Today",
        from_date: moment().format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      });
      const filterDate = moment().format("YYYY-MM-DD");
      getData("Today", filterDate, filterDate, filter.currency, filter.country);
      return true;
    }
  };

  const handleFilter = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setDate({
      startDate: "",
      endDate: "",
    });
    if (name == "dateRange") {
      if (value == "Custom") {
        setFilter({
          ...filter,
          dateRange: value,
          from_date: filter.from_date,
          to_date: filter.to_date,
        });
        return true;
      }
      const { from_date, to_date } = getDateRange(value);
      setFilter({
        ...filter,
        dateRange: value,
        from_date,
        to_date,
      });
      getData(value, from_date, to_date, filter.currency, filter.country);
      return true;
    }
    setFilter({
      ...filter,
      [name]: value,
    });
    return true;
  };

  const getDateRange = (value) => {
    let from_date = moment().format("YYYY-MM-DD");
    let to_date = moment().format("YYYY-MM-DD");
    if (value == "Today") {
      from_date = moment().format("YYYY-MM-DD");
      to_date = moment().format("YYYY-MM-DD");
    } else if (value == "Yesterday") {
      from_date = moment().subtract(1, "day").format("YYYY-MM-DD");
      to_date = moment().subtract(1, "day").format("YYYY-MM-DD");
    } else if (value == "This Week") {
      from_date = moment().startOf("isoWeek").format("YYYY-MM-DD");
      to_date = moment().endOf("isoWeek").format("YYYY-MM-DD");
    } else if (value == "This Month") {
      from_date = moment().startOf("month").format("YYYY-MM-DD");
      to_date = moment().endOf("month").format("YYYY-MM-DD");
    } else if (value == "This Year") {
      from_date = moment().startOf("year").format("YYYY-MM-DD");
      to_date = moment().endOf("year").format("YYYY-MM-DD");
    } else if (value == "Custom") {
      from_date = moment().startOf("year").format("YYYY-MM-DD");
      to_date = moment().endOf("year").format("YYYY-MM-DD");
    }
    return { from_date, to_date };
  };

  return (
    <>
      <div
        className="merchant_list_search_filter"
        style={{ display: "flow-root" }}
      >
        <Select
          value={selectedStatus}
          style={{ width: "30%", marginRight: "5px" }}
          onChange={(e) => {
            setSelectedStatus(e.target.value);
          }}
        >
          <MenuItem key="all" value="all">
            All Status
          </MenuItem>
          {allStatus.map((w) => {
            return (
              <MenuItem key={w.value} value={w.value}>
                {w.label}
              </MenuItem>
            );
          })}
        </Select>
        <Filter
          handleFilter={handleFilter}
          handleClear={handleClear}
          filter={filter}
          handledates={handledates}
        />
      </div>
      <div className="second-main">
        <div className="second-section">
          <div className="second-section__left second-section-card">
            <h6>
              Orders Quantity
            </h6>
            <div className="second-section-cart">
              {spinner ? (
                <div className="no_data_found loader_data_tbl">
                  <CircularProgress style={{ color: "#1058ff" }} />
                </div>
              ) : (
                <OrderCount
                  data={data && data.length ? data[0].order_detail : []}
                  filter={filter}
                />
              )}
            </div>
          </div>
          <div className="second-section__right second-section-card">
            <h6>
              Orders Amount
            </h6>
            <div className="second-section-cart">
              {spinner ? (
                <div className="no_data_found loader_data_tbl">
                  <CircularProgress style={{ color: "#1058ff" }} />
                </div>
              ) : (
                <OrderAmount
                  data={data && data.length ? data[0].order_detail : []}
                  filter={filter}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
