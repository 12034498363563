import React from "react";
import { connect } from "react-redux";
import { Redirect, useHistory, Link } from "react-router-dom";
import "./timeout.scss";
import styles from "../../page-components/login/login.module.css";

function Timeout(props) {
  const history = useHistory();

  return (
    <div
      className={`${styles.outer} warning-main`}
      style={{ backgroundImage: 'url("/images/Login-BG-01.svg")' }}
    >
      <div className={styles.loginBox}>
        <div
          style={{
            "text-align": "center",
            "margin-bottom": "1.5rem",
            "margin-left": "auto",
            "margin-right": "auto",
          }}
        >
          <img
            src="/logo.svg"
            alt="Logo"
            style={{ width: "210px", height: "48px" }}
          />
        </div>
        <div
          style={{
            "margin-left": "auto",
            "margin-right": "auto",
            width: "50%",
          }}
        >
          <div
            className={`${styles.justify_content_center} ${styles.align_items_center} ${styles.h_100}`}
          >
            <div>
              <div className={styles.card}>
                <div className={styles.card_body}>
                  <img
                    src="/Login Clipart-01.svg"
                    alt="Login Clipart"
                    style={{ width: "50%" }}
                  />
                  <h5>
                    <span style={{ color: "red", "font-weight": "bold" }}>
                      Alert:
                    </span>
                    <br /> Your session has expired due to inactivity. This is a
                    security measure to protect your account and ensure that
                    unauthorized access does not occur. To continue using app,
                    Please{" "}
                    <Link
                      to={`/login`}
                      className="tbl_id"
                      style={{ "font-weight": "bold" }}
                    >
                      login
                    </Link>{" "}
                    again.
                  </h5>
                  <button
                    onClick={() => {
                      history.push("/login");
                    }}
                  >
                    Login
                  </button>
                </div>
              </div>
              <div className={`${styles.card} ${styles.bottom_card}`}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isTemporaryPassword: state.auth.isTemporaryPassword,
    sessionWarning: state.auth.sessionWarning,
    lockedOut: state.auth.lockedOut,
  };
};

export default connect(mapStateToProps, null)(Timeout);
