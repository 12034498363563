import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Box, Paper } from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import AxiosBase from "../../../networkRequest/axiosBase";
import "./index.css";
import { Link } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import GoogleAddress from "../../../utils/googleAddress";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    padding: 9,
  },
});
const CssTextArea = styled(TextareaAutosize)({
  "input#comment-text": {
    width: "100%",
    height: "4.3rem",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function UserForm(props) {
  const history = useHistory();
  const { id, warehouse_id } = useParams("id");
  const [data, setData] = React.useState({});
  const [enablemerchant, setenablemerchant] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [update, setupdate] = useState(false);
  const [roleArr, setRoleArr] = useState([]);
  const [aadharFile, setAadharFile] = useState(null);
  const [panFile, setPanFile] = useState(null);
  const [licenseFile, setLicenseFile] = useState(null);
  const [profileFile, setProfileFile] = useState(null);
  const CHARACTER_LIMIT = 50;
  const mobile_LIMIT = 16;

  const [firsterror, setFirstError] = useState({
    error: false,
    message: "",
  });

  const [numbererror, setNumberError] = useState({
    error: false,
    message: "",
  });

  const [BusinessEmailError, setBusinessEmailError] = useState({
    error: false,
    message: "",
  });

  const [center, setCenter] = useState({
    lat: 37.7749,
    lng: -122.4194,
  });
  const [warehouse, setWarehouse] = useState([]);
  const [vehicle, setVehicle] = useState([]);

  const [warehouseMenu, setWarehouseMenu] = useState({
    Dashboard: true,
    Receiver: true,
    Deliver: true,
    Express: true,
    Rider: true,
    Offline_customer: true,
    Detect_order: true,
    Location: true,
    Order: true,
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2); // Ensure two-digit month
    const day = `0${date.getDate()}`.slice(-2); // Ensure two-digit day
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (id == -1) {
      setData({
        name: "",
        country_code: "91",
        mobile_no: "91",
        email_id: "",
        role: "",
        vehicle_id: "",
        vehicle_number: "",
        joining_date: formatDate(new Date()),
        warehouse_id: warehouse_id ? warehouse_id : "",
      });
      getUserLocation();
      return false;
    }
    AxiosBase.get(`/admin/user/${id}`).then((response) => {
      setData({
        ...response.data.data,
        joining_date: formatDate(
          response.data.data.joining_date
            ? response.data.data.joining_date
            : new Date()
        ),
      });
      if (response.data.data?.menu_permission) {
        setWarehouseMenu(response.data.data?.menu_permission);
      }
      if (response.data.data?.lat && response.data.data?.lng) {
        setCenter({
          lat: response.data.data?.lat,
          lng: response.data.data?.lng,
        });
      }
    });
  }, []);

  useEffect(() => {
    AxiosBase.get(`/admin/role`).then((response) => {
      setRoleArr(response.data.data);
    });
  }, []);

  useEffect(() => {
    AxiosBase.get(`/admin/warehouse?pageNo=0&rowsPerPage=1000`).then(
      (response) => {
        setWarehouse(response.data.data);
      }
    );
  }, []);

  const getAllVehicle = () => {
    AxiosBase.get(`/admin/vehicle`).then((response) => {
      setVehicle(response.data.data);
    });
  };

  useEffect(() => {
    getAllVehicle();
  }, []);

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  };

  const save = async () => {
    if (data.name != "" && firsterror.error) {
      setupdate(!update);
      return (
        toast(firsterror.message.toString()),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    } else if (numbererror.error) {
      setupdate(!update);
      return (
        toast(numbererror.message.toString()),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    } else if (BusinessEmailError.error) {
      setupdate(!update);
      return (
        toast(BusinessEmailError.message.toString()),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    } else if (data.mobile_no == "" || data.email_id == "" || data.name == "") {
      setupdate(!update);
      return (
        toast("Please fill user information"),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    } else if (!data.role) {
      setupdate(!update);
      return (
        toast("Please select user role"),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    }
    if (profileFile) {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const formData = new FormData();
      formData.append("file", profileFile);
      const res = await AxiosBase.post(`/admin/uploadImages`, formData, config);
      data.profile = res?.data?.file_name;
    }
    if (aadharFile) {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const formData = new FormData();
      formData.append("file", aadharFile);
      const res = await AxiosBase.post(`/admin/uploadImages`, formData, config);
      data.documents.aadhar_url = res?.data?.file_name;
    }
    if (panFile) {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const formData = new FormData();
      formData.append("file", panFile);
      const res = await AxiosBase.post(`/admin/uploadImages`, formData, config);
      data.documents.pan_url = res?.data?.file_name;
    }
    if (licenseFile) {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const formData = new FormData();
      formData.append("file", licenseFile);
      const res = await AxiosBase.post(`/admin/uploadImages`, formData, config);
      data.documents.license_url = res?.data?.file_name;
    }
    setData({ ...data });
    if (data.role != "ADMIN" && data.role != "SUPER_ADMIN") {
      if (!data?.documents?.aadhar_url || !data?.documents?.aadhar_number) {
        setupdate(!update);
        return (
          toast("Aadhar card is required"),
          {
            type: "error",
            autoClose: 2000,
          }
        );
      }
      if (!data?.documents?.pan_url || !data?.documents?.pan_number) {
        setupdate(!update);
        return (
          toast("PAN card is required"),
          {
            type: "error",
            autoClose: 2000,
          }
        );
      }
      if (!data?.documents?.license_url || !data?.documents?.license_number) {
        setupdate(!update);
        return (
          toast("License is required"),
          {
            type: "error",
            autoClose: 2000,
          }
        );
      }
    }
    if (data.role == "DELIVERY_BOY" && (!data?.lat || !data?.lng)) {
      setupdate(!update);
      return (
        toast("Please select your address from google map"),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    }
    if (data?.role == "WAREHOUSE") {
      data.menu_permission = warehouseMenu;
    } else {
      data.menu_permission = null;
    }
    if (id == -1) {
      AxiosBase.post(`/admin/user`, data)
        .then((response) => {
          setupdate(!update);
          toast("User Added Successfully.", {
            type: "success",
            autoClose: 2000,
          });
          history.push("/user");
        })
        .catch((err) => {
          console.log(err);
          setupdate(!update);
          return toast(
            err?.response?.data?.message || "Something went wrong!",
            {
              type: "error",
              autoClose: 2000,
            }
          );
        });
    } else {
      AxiosBase.put(`/admin/user/${id}`, data)
        .then((response) => {
          setupdate(!update);
          toast("User Updated Successfully.", {
            type: "success",
            autoClose: 2000,
          });
          history.push("/user");
        })
        .catch((err) => {
          console.log(err);
          setupdate(!update);
          return toast(
            err?.response?.data?.message || "Something went wrong!",
            {
              type: "error",
              autoClose: 2000,
            }
          );
        });
    }
  };

  const openGoogleMap = () => {
    setShowMap(true);
  };

  const closeGoogleMap = () => {
    setShowMap(false);
  };

  const onSubmitAddress = async () => {
    AxiosBase.post(`/admin/google/full-address`, {
      lat: center?.lat,
      lng: center?.lng,
    }).then((response) => {
      setData({
        ...data,
        address: response.data.location,
        lat: center?.lat,
        lng: center?.lng,
      });
      setShowMap(false);
    });
  };

  if (loading) {
    return (
      <div className="no_data_found loader_data_tbl">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <Box>
        <h1 className="merchant_title">Users</h1>
      </Box>
      <div className="merchant_list_main_div">
        <div style={{ padding: "15px" }}>
          {loading ? (
            <div className="no_data_found loader_data_tbl">
              <CircularProgress />
            </div>
          ) : (
            <>
              <h4
                style={{ marginLeft: "20px", color: "gray", fontWeight: "500" }}
              >
                BASIC DETAIL
              </h4>
              <hr />
              <Grid container spacing={2} className="merchant_main_grid">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Name</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="Name"
                        fullWidth
                        helperText={
                          firsterror.error == true ? firsterror.message : ""
                        }
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 15,
                        }}
                        value={data?.name}
                        required
                        onChange={(e) => {
                          if (
                            e.target.value.length < 3 ||
                            e.target.value.length > 15
                          ) {
                            setFirstError({
                              error: true,
                              message:
                                "First Name must be between 3 to 15 characters",
                            });
                          } else {
                            setFirstError({ error: false, message: "" });
                          }
                          setData({
                            ...data,
                            name: e.target.value,
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className="create_merchn_inpt_icon">
                              <IconButton color="primary">
                                <PersonOutlinedIcon className="merchant_inpt_icon" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className="sep_inpt_sec">
                    <p>
                      Mobile Number<span>*</span>
                    </p>

                    <div className="inp_sep_div">
                      {data?.mobile_no && (
                        <MuiPhoneNumber
                          className="create_merchant_input_feild mui_number_field"
                          data-cy="user-phone"
                          defaultCountry={"ae"}
                          variant="outlined"
                          disabled={enablemerchant}
                          inputProps={{
                            maxLength: mobile_LIMIT,
                          }}
                          value={data?.mobile_no?.toString()}
                          onChange={(val, countrycode) => {
                            if (!val) return;
                            val = val.replace(/-|\s/g, "");
                            if (val.length < 12 || val.length > 16) {
                              setNumberError({
                                error: true,
                                message: "Enter valid Number",
                              });
                            } else {
                              setNumberError({ error: false, message: "" });
                            }
                            setData({
                              ...data,
                              mobile_no: val,
                              country_code: countrycode.dialCode,
                            });
                          }}
                        />
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className="sep_inpt_sec">
                    <p>
                      Email<span>*</span>
                    </p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        type="email"
                        disabled={enablemerchant}
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="Business Email"
                        fullWidth
                        value={data?.email_id}
                        required
                        inputProps={{
                          maxLength: CHARACTER_LIMIT,
                        }}
                        error={
                          !data?.email_id?.match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                          ) && data?.email_id !== ""
                        }
                        // helperText={
                        //   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        //     data?.email_id
                        //   ) || data?.email_id === ""
                        //     ? " "
                        //     : "Business email id must be between 6 to 50 characters"
                        // }
                        onChange={(e) => {
                          if (
                            !e.target.value.match(
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            ) &&
                            e.target.value !== ""
                          ) {
                            setBusinessEmailError({
                              error: true,
                              message: "Please enter valid business email id",
                            });
                          } else {
                            setBusinessEmailError({
                              error: false,
                              message: "",
                            });
                          }
                          setData({
                            ...data,
                            email_id: e.target.value,
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className="create_merchn_inpt_icon">
                              <IconButton color="primary">
                                <img
                                  src="/images/icon/top-email.svg"
                                  alt=""
                                  style={{ width: "25px", height: "25px" }}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment className="create_merchn_inpt_icon">
                              {data?.is_email_verified ? (
                                <Tooltip title="Email Verified">
                                  <IconButton color="primary">
                                    <img
                                      src="/images/icon/8.png"
                                      alt=""
                                      style={{ width: "25px", height: "25px" }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Email Not Verified.Please click here to resend email.">
                                  <IconButton color="primary">
                                    <img
                                      src="/images/icon/2.png"
                                      alt=""
                                      style={{ width: "25px", height: "25px" }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className="sep_inpt_sec">
                    <p>
                      Role<span>*</span>
                    </p>

                    <div className="inp_sep_div">
                      <Select
                        name="key1"
                        labelId="country-lbl"
                        label=""
                        id="country"
                        value={data?.role || ""}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setData({
                            ...data,
                            role: e.target.value,
                          });
                        }}
                      >
                        {roleArr &&
                          roleArr.map((c) => {
                            return (
                              <MenuItem key={c._id} value={c.name}>
                                {c.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Profile</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="aadhar"
                        type="file"
                        fullWidth
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        required
                        onChange={(e) => {
                          setProfileFile(e.target.files[0]);
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className="sep_inpt_sec">
                    <p>
                      Address{" "}
                      <img
                        src="/images/icon/google-maps.png"
                        alt="map"
                        onClick={openGoogleMap}
                        style={{ height: "15px", cursor: "pointer" }}
                      />
                    </p>

                    <div className="inp_sep_div">
                      <CssTextArea
                        id="text"
                        variant="outlined"
                        sx={{
                          width: "95% !important",
                          borderColor: "#c4c4c4",
                          p: "10px",
                          borderRadius: "5px",
                          fontSize: "14px",
                        }}
                        placeholder="address"
                        value={data?.address}
                        onChange={(e) => {
                          setData({
                            ...data,
                            address: e.target.value,
                          });
                        }}
                        minRows={2}
                      />
                      {/* <div className="inp_sep_div">
                        <Button variant="contained" onClick={openGoogleMap}>
                          Open Google map to set Location
                        </Button>
                      </div> */}
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Joining Date</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="Joining date"
                        fullWidth
                        disabled={enablemerchant}
                        value={data?.joining_date}
                        required
                        type="date"
                        onChange={(e) => {
                          setData({
                            ...data,
                            joining_date: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                {warehouse_id == -1 && (
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <div className="sep_inpt_sec">
                      <p>Warehouse</p>

                      <div className="inp_sep_div">
                        <Select
                          name="key1"
                          labelId="country-lbl"
                          label=""
                          id="country"
                          value={data?.warehouse_id || ""}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            setData({
                              ...data,
                              warehouse_id: e.target.value,
                            });
                          }}
                        >
                          {warehouse &&
                            warehouse.map((c) => {
                              return (
                                <MenuItem key={c._id} value={c._id}>
                                  {c.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </div>
                    </div>
                  </Grid>
                )}
                {(data?.role == "DELIVERY_BOY" ||
                  data?.role == "EXPRESS_DELIVERY_BOY" ||
                  data?.role == "TRANSPORT") && (
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <div className="sep_inpt_sec">
                      <p>Vehicle</p>

                      <div className="inp_sep_div">
                        <Select
                          name="key1"
                          labelId="country-lbl"
                          label=""
                          id="country"
                          value={data?.vehicle_id || ""}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            const ind = vehicle.findIndex(
                              (x) => x.number == e.target.value
                            );
                            setData({
                              ...data,
                              vehicle_id: e.target.value,
                              vehicle_number:
                                ind > -1 ? vehicle[ind]?.number : "",
                            });
                          }}
                        >
                          {vehicle &&
                            vehicle.map((c) => {
                              return (
                                <MenuItem key={c._id} value={c._id}>
                                  {c.number}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </div>
                    </div>
                  </Grid>
                )}
              </Grid>
              {data?.role == "WAREHOUSE" && (
                <div className="row">
                  <h4
                    style={{
                      marginLeft: "20px",
                      color: "gray",
                      fontWeight: "500",
                    }}
                  >
                    PERMISSIONS
                  </h4>
                  <hr />
                  <Grid container spacing={2} className="merchant_main_grid">
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={2}
                      lg={2}
                      xl={2}
                      className="merchant_grid_sec"
                    >
                      <label>Dashboard </label>
                      <Switch
                        checked={warehouseMenu.Dashboard || false}
                        onChange={(e) => {
                          warehouseMenu.Dashboard = e.target.checked;
                          setWarehouseMenu({ ...warehouseMenu });
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={2}
                      lg={2}
                      xl={2}
                      className="merchant_grid_sec"
                    >
                      <label>Receiver </label>
                      <Switch
                        checked={warehouseMenu.Receiver || false}
                        onChange={(e) => {
                          warehouseMenu.Receiver = e.target.checked;
                          setWarehouseMenu({ ...warehouseMenu });
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={2}
                      lg={2}
                      xl={2}
                      className="merchant_grid_sec"
                    >
                      <label>Deliver </label>
                      <Switch
                        checked={warehouseMenu.Deliver || false}
                        onChange={(e) => {
                          warehouseMenu.Deliver = e.target.checked;
                          setWarehouseMenu({ ...warehouseMenu });
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={2}
                      lg={2}
                      xl={2}
                      className="merchant_grid_sec"
                    >
                      <label>Express </label>
                      <Switch
                        checked={warehouseMenu.Express || false}
                        onChange={(e) => {
                          warehouseMenu.Express = e.target.checked;
                          setWarehouseMenu({ ...warehouseMenu });
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={2}
                      lg={2}
                      xl={2}
                      className="merchant_grid_sec"
                    >
                      <label>Rider </label>
                      <Switch
                        checked={warehouseMenu.Rider || false}
                        onChange={(e) => {
                          warehouseMenu.Rider = e.target.checked;
                          setWarehouseMenu({ ...warehouseMenu });
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={2}
                      lg={2}
                      xl={2}
                      className="merchant_grid_sec"
                    >
                      <label>Offline Customer </label>
                      <Switch
                        checked={warehouseMenu.Offline_customer || false}
                        onChange={(e) => {
                          warehouseMenu.Offline_customer = e.target.checked;
                          setWarehouseMenu({ ...warehouseMenu });
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={2}
                      lg={2}
                      xl={2}
                      className="merchant_grid_sec"
                    >
                      <label>Detect Order </label>
                      <Switch
                        checked={warehouseMenu.Detect_order || false}
                        onChange={(e) => {
                          warehouseMenu.Detect_order = e.target.checked;
                          setWarehouseMenu({ ...warehouseMenu });
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={2}
                      lg={2}
                      xl={2}
                      className="merchant_grid_sec"
                    >
                      <label>Location </label>
                      <Switch
                        checked={warehouseMenu.Location || false}
                        onChange={(e) => {
                          warehouseMenu.Location = e.target.checked;
                          setWarehouseMenu({ ...warehouseMenu });
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={2}
                      lg={2}
                      xl={2}
                      className="merchant_grid_sec"
                    >
                      <label>Order </label>
                      <Switch
                        checked={warehouseMenu.Order || false}
                        onChange={(e) => {
                          warehouseMenu.Order = e.target.checked;
                          setWarehouseMenu({ ...warehouseMenu });
                        }}
                      />
                    </Grid>
                  </Grid>
                  <br />
                </div>
              )}
              <h4
                style={{ marginLeft: "20px", color: "gray", fontWeight: "500" }}
              >
                KYC DETAIL
              </h4>
              <hr />
              <Grid container spacing={2} className="merchant_main_grid">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Aadhar Card</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="aadhar"
                        type="file"
                        fullWidth
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        required
                        onChange={(e) => {
                          setAadharFile(e.target.files[0]);
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Aadhar Number</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="Aadhar Number"
                        type="number"
                        fullWidth
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        value={data?.documents?.aadhar_number}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            documents: {
                              ...data.documents,
                              aadhar_number: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>PAN Card</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="aadhar"
                        type="file"
                        fullWidth
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        required
                        onChange={(e) => {
                          setPanFile(e.target.files[0]);
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>PAN Number</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="PAN Number"
                        fullWidth
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        value={data?.documents?.pan_number}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            documents: {
                              ...data.documents,
                              pan_number: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>License</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="aadhar"
                        type="file"
                        fullWidth
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        required
                        onChange={(e) => {
                          setLicenseFile(e.target.files[0]);
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>License Number</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="License Number"
                        fullWidth
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        value={data?.documents?.license_number}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            documents: {
                              ...data.documents,
                              license_number: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
              <br />
              <h4
                style={{ marginLeft: "20px", color: "gray", fontWeight: "500" }}
              >
                BANK DETAIL
              </h4>
              <hr />
              <Grid container spacing={2} className="merchant_main_grid">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Bank Name</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="Bank Name"
                        fullWidth
                        helperText={
                          firsterror.error == true ? firsterror.message : ""
                        }
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        value={data?.bank_detail?.bank_name}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            bank_detail: {
                              ...data.bank_detail,
                              bank_name: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Account Number</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="Account Number"
                        type="number"
                        fullWidth
                        helperText={
                          firsterror.error == true ? firsterror.message : ""
                        }
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        value={data?.bank_detail?.account_number}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            bank_detail: {
                              ...data.bank_detail,
                              account_number: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Holder Name</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="Holder Name"
                        fullWidth
                        helperText={
                          firsterror.error == true ? firsterror.message : ""
                        }
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        value={data?.bank_detail?.holder_name}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            bank_detail: {
                              ...data.bank_detail,
                              holder_name: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>IFSC Code</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="ABCD01234"
                        fullWidth
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        value={data?.bank_detail?.ifsc}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            bank_detail: {
                              ...data.bank_detail,
                              ifsc: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
              <br />
              <h4
                style={{ marginLeft: "20px", color: "gray", fontWeight: "500" }}
              >
                SALARY DETAIL
              </h4>
              <hr />
              <Grid container spacing={2} className="merchant_main_grid">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Basic Salary</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="Basic Salary"
                        fullWidth
                        type="number"
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        value={data?.salary_detail?.basic}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            salary_detail: {
                              ...data.salary_detail,
                              basic: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>HRA</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="HRA"
                        fullWidth
                        type="number"
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        value={data?.salary_detail?.hra}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            salary_detail: {
                              ...data.salary_detail,
                              hra: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>PF</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="PF"
                        fullWidth
                        type="number"
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        value={data?.salary_detail?.pf}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            salary_detail: {
                              ...data.salary_detail,
                              pf: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>TDS</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="TDS"
                        fullWidth
                        type="number"
                        disabled={enablemerchant}
                        inputProps={{
                          maxLength: 50,
                        }}
                        value={data?.salary_detail?.tds}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            salary_detail: {
                              ...data.salary_detail,
                              tds: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
              <br />
              <hr />
            </>
          )}

          {!enablemerchant && !loading ? (
            <div className="merchant_edit_button_dev">
              <Link
                to={
                  warehouse_id == -1
                    ? `/user`
                    : `/warehouse/form/${warehouse_id}`
                }
              >
                <Button
                  className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                  variant="contained"
                >
                  Cancel
                </Button>
              </Link>
              {(props?.menu_permissions?.ADD ||
                props?.menu_permissions?.EDIT) && (
                <Button
                  className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                  variant="contained"
                  onClick={save}
                >
                  Update
                </Button>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div>
        <BootstrapDialog
          className="google-address-dialog"
          onClose={closeGoogleMap}
          aria-labelledby="customized-dialog-title"
          open={showMap}
        >
          <DialogContent>
            <GoogleAddress
              onSubmitAddress={onSubmitAddress}
              center={center}
              setCenter={setCenter}
            />
          </DialogContent>
        </BootstrapDialog>
      </div>
    </div>
  );
}

export default UserForm;
