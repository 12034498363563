import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AxiosBase from "../../networkRequest/axiosBase";
import "./index.scss";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import Autocomplete from "@mui/material/Autocomplete";

const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "350px",
};

function GoogleAddress(props) {
  const { onSubmitAddress, center, setCenter } = props;
  const [loading, setLoading] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({
    lat: 37.7749,
    lng: -122.4194,
  });
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  });

  const [map, setMap] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCurrentLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  };

  useEffect(() => {
    getUserLocation();
  }, []);

  const getSearchResult = async () => {
    if (!searchInput) return;
    AxiosBase.post(`/admin/google/get-place`, {
      search: searchInput,
      lat: currentLocation?.lat,
      lng: currentLocation?.lng,
    }).then((response) => {
      const arr = response.data.location.map((x) => {
        return {
          ...x,
          label: x.formatted_address,
          id: x.place_id,
        };
      });
      setSearchResults(arr);
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getSearchResult();
    }, 500);

    return () => clearTimeout(timer);
  }, [searchInput]);

  if (loading) {
    return (
      <div className="no_data_found loader_data_tbl">
        <CircularProgress />
      </div>
    );
  }

  const handleMapClick = (event) => {
    setCenter({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  const handlePlaceSelect = (place) => {
    setSearchInput("");
    setSearchResults([]);
    setCenter(place.geometry.location);

    if (map) {
      map.panTo(place.geometry.location);
    }
  };

  return (
    <div>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={searchResults}
        sx={{ width: "100%" }}
        filterOptions={(options, state) => options}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search Place..."
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
          />
        )}
        onChange={(e, val) => {
          handlePlaceSelect(val);
        }}
      />
      <br />
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={54}
          center={center}
          onClick={handleMapClick}
        >
          <Marker
            position={center}
            icon={{
                url: "/images/icon/cloth-map.png",
                scaledSize: new window.google.maps.Size(40, 40),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(20, 40),
            }}
            search={true}
            draggable={true}
            onDragEnd={(event) => {
              setCenter({
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
              });
            }}
            title="Drag to change location"
            animation={window.google.maps.Animation.DROP}
          />
        </GoogleMap>
      )}
      <br />
      <Button
        className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
        variant="contained"
        disabled={loading}
        onClick={onSubmitAddress}
        style={{ float: "right" }}
      >
        Submit
      </Button>
      <br />
    </div>
  );
}

export default GoogleAddress;
