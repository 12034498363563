import React from "react";
import Navbar from "../universal-components/navbar";
import CollapseNavBar from "../universal-components/navbar/CollapseNavBar";
import Container from "@mui/material/Container";

function MainLayout({ children }) {
  return (
    <div style={{ width: "calc(100% - 10px)", display: "flex" }}>
      <div>
        <CollapseNavBar />
      </div>
      <div className='main_children'>
        <Container
          maxWidth="ls"
        >
          {children}
        </Container>
      </div>
    </div>
  );
}

export default MainLayout;
