import React from "react";
import { useState, useEffect } from "react";
import "./index.css";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import AxiosBase from "../../../../networkRequest/axiosBase";
import UploadDoc from "./upload-doc/UploadDoc";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "4px",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Detail = (props) => {
  const { SelectedBanner } = props;
  const [banner, setBanner] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openUpload, setOpenUpload] = React.useState(false);
  const [uploadDoc, setUploadDoc] = React.useState(false);

  useEffect(() => {
    console.log("banner")
    setBanner(SelectedBanner);
  }, [props.SelectedBanner]);

  const hiddenFileInput = React.useRef(null);

  const addBanner = async () => {
    if (!banner?.name || banner?.name == "") {
      return toast("name is required", {
        type: "error",
        autoClose: 2000,
      });
    }
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    const formData = new FormData();
    formData.append("file", banner?.file);
    const res = await AxiosBase.post(`/admin/uploadImages`, formData, config);
    banner.file = null;
    banner.image_url = res?.data?.file_name;
    props.onSubmitBanner(banner);
  };

  const onChangeInput = (e, key, type = "String") => {
    let val = e.target.value;
    if (type == "Int") {
      val = val.toString().replaceAll("+", "");
      let val1 = val.match(/[0-9]/);
      if (val1) {
        val = isNaN(parseInt(val)) ? "" : parseInt(val);
      } else if (!val) {
        val = "";
      } else {
        return;
      }
    } else {
      val = val.replace(/^\s+/, "").replace("  ", " ");
    }
    banner[key] = val;
    setBanner({
      ...banner,
    });
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const handleFileClick = (event) => {
    setUploadDoc(true);
    setOpenUpload(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenUpload(false);
  };

  const handleUploadDocClose = () => {
    setOpenUpload(false);
    setUploadDoc(false);
  };

  const handleData = (file) => {
    setOpenUpload(false);
    setUploadDoc(false);
    banner.file = file;
    banner.image_url = URL.createObjectURL(file);
    setBanner({
      ...banner,
    });
  };

  return (
    <>
      <div style={{ width: "100%" }} className="kyc_detail_ctrl">
        <div className="">
          <div>
            <div className="add_menu_role_star">
              <p>Name:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec banner-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"name_"}
                  placeholder="name"
                  variant="outlined"
                  aria-readonly
                  value={banner.name}
                  onChange={(e) => {
                    onChangeInput(e, "name");
                  }}
                />
              </Grid>
            </div>

            <div className="add_menu_role_star">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec banner-field"
              >
                <div className="">
                  <Button
                    className="banner_upload_btn"
                    onClick={handleFileClick}
                  >
                    <i className="fa fa-upload"></i>&nbsp;&nbsp; Upload Banner
                  </Button>
                </div>
                {banner.image_url && (
                  <img
                    src={
                      banner?.file
                        ? banner.image_url
                        : `${process.env.REACT_APP_API_URL}/api/image/show/${banner.image_url}`
                    }
                    style={{ width: "25%", "margin-top": "15px" }}
                  />
                )}
              </Grid>
            </div>
          </div>
        </div>
        <div>
          <div className="banner_edit_drawer_btn_sec">
            <Button
              className="drawer_merchant_cancel_btn"
              variant="contained"
              onClick={props.toggleDrawer("right", false)}
            >
              Cancel
            </Button>

            <Button
              className="drawer_merchant_save_btn"
              variant="contained"
              style={{ marginLeft: "5px" }}
              onClick={() => {
                addBanner();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>

      <div>
        <BootstrapDialog
          className="order_detail_refund_popup"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openUpload}
        >
          <DialogContent>
            <Typography
              className="order_detail_refund_popup_title"
              gutterBottom
            >
              {
                <>
                  <UploadDoc
                    open={true}
                    handleDocumentClose={handleUploadDocClose}
                    handleData={handleData}
                    type={banner.type}
                  />
                </>
              }
            </Typography>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default Detail;
