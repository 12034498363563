import React, { useState, useEffect } from "react";
import { Box, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import "./index.css";
import TablePagination from "@mui/material/TablePagination";
import AxiosBase from "../../../networkRequest/axiosBase";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import moment from "moment";

const Div = styled("div")(unstable_styleFunctionSx);

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "2px",
    backgroundColor: "#FEFEFE",
  },
});

const CssTextArea = styled(TextareaAutosize)({
  "input#comment-text": {
    width: "100%",
    height: "4.3rem",
  },
});

// main function

const SupportList = (props) => {
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [search, setSearch] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [spinner, setSpinner] = useState(false);
  const [state, setState] = React.useState({
    right: false,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const getData = () => {
    setSpinner(true);
    AxiosBase.get(
      `/admin/support?pageNo=` +
        page +
        `&rowsPerPage=` +
        rowsPerPage +
        `&sortBy=_id&sortOrder=desc&search=` +
        search
    ).then((response) => {
      setData(response.data.data);
      setCount(response.data.count);
      setSpinner(false);
    });
  };

  useEffect(() => {
    getData();
  }, [page, rowsPerPage, count, search]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const toggleDrawer =
    (anchor, open, row = null) =>
    (event) => {
      setState({ ...state, [anchor]: open, data: row });
    };

  const replyQuery = () => {
    AxiosBase.post("/admin/support", {
      message: message,
      _id: state?.data?._id,
    })
      .then((res) => {
        if (res?.status == 200) {
          setSpinner(false);
          setMessage("");
          setState({ ...state, ["right"]: false, data: null });
          getData();
          return toast("Replied successfully.", {
            type: "success",
            autoClose: 2000,
          });
        } else {
          setSpinner(false);
          return toast(JSON.stringify(res?.response?.data), {
            type: "error",
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        setSpinner(false);
        return toast(
          error?.response?.data?.message || "Something went wrong!",
          {
            type: "error",
            autoClose: 2000,
          }
        );
      });
  };

  const list = (anchor) => (
    <Box
      className="kyc_detail_box"
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 600,
        overflowX: "hidden",
      }}
      role="presentation"
    >
      <List>
        <ListItem className="banner_drower">
          <p>Enquiry Detail</p>
        </ListItem>
      </List>
      <Divider />
      {
        <>
          <div className="kyc_array">
            <p>Query:</p>
            {state?.data?.message || "NA"}
          </div>
          <div className="add_menu_role_star" style={{ margin: "10px" }}>
            <p>Reply Query:</p>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="gatway_second_grid_sec offer-field"
            >
              <CssTextArea
                id="text"
                variant="outlined"
                sx={{
                  width: "96%",
                  borderColor: "#c4c4c4",
                  p: "10px",
                  borderRadius: "5px",
                  fontSize: "14px",
                }}
                placeholder="message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                minRows={3}
              />
            </Grid>
            <div>
              <div className="offer_edit_drawer_btn_sec">
                <Button
                  className="drawer_merchant_cancel_btn"
                  variant="contained"
                  onClick={toggleDrawer("right", false)}
                >
                  Cancel
                </Button>

                <Button
                  className="drawer_merchant_save_btn"
                  variant="contained"
                  style={{ marginLeft: "5px" }}
                  onClick={() => {
                    replyQuery();
                  }}
                >
                  Send
                </Button>
              </div>
            </div>
          </div>
        </>
      }
    </Box>
  );

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box>
          <h1 className="merchant_title">Supports</h1>
        </Box>

        <div className="merchant_list_main_div">
          <div className="merchant_list_inner_title_div">
            <div className="merchant_list_search_filter">
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                className="merchant_grid_sec"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  placeholder="search"
                  variant="outlined"
                  fullWidth
                  onKeyUp={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <IconButton className="merchant_search_icon">
                          <img src="/images/icon/search.svg" alt="" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <div className="merchant_list_filter_div">
                <img src="/Filter.svg" alt="" />
              </div>
            </div>
            <h3 className="merchant_list_inner_title">Support List</h3>
          </div>
          {spinner ? (
            <div className="no_data_found loader_data_tbl">
              <CircularProgress style={{ color: "#1058ff" }} />
            </div>
          ) : (
            <>
              <TableContainer className="table_container">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className="data_tbl_head_row">
                    <TableRow style={{ background: "none" }}>
                      <TableCell>Support ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Email ID</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Created At</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="data_tbl_body_row">
                    {data.map((row, index) => (
                      <TableRow key={row?._id} style={{ background: "none" }}>
                        <TableCell>
                          {["right"].map((anchor) => (
                            <React.Fragment key={anchor}>
                              <Link
                                onClick={toggleDrawer(anchor, true, row)}
                              >
                                {row._id}
                              </Link>
                            </React.Fragment>
                          ))}
                        </TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>
                          {row.is_replied ? <span className="reply-status">Replied</span> : <span className="pending-status">Pending</span>}
                        </TableCell>
                        <TableCell>{moment(row.createdAt).format("DD-MMM-YYYY HH:MM A")}</TableCell>
                        <TableCell>
                          {["right"].map((anchor) => (
                            <React.Fragment key={anchor}>
                              <Link
                                onClick={toggleDrawer(anchor, true, row)}
                              >
                                <IconButton
                                  className="merchant_tbl_edit_icon"
                                  aria-label="more"
                                  id="long-button"
                                  aria-controls="long-menu"
                                  aria-expanded={open ? "true" : undefined}
                                  aria-haspopup="true"
                                >
                                  <img src="/images/icon/editt.svg" alt="" />
                                </IconButton>
                              </Link>
                            </React.Fragment>
                          ))}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className="table_pagination"
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </div>
      </div>

      {["right"].map((anchor) => (
        <SwipeableDrawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
          onOpen={toggleDrawer(anchor, true)}
        >
          {list(anchor)}
        </SwipeableDrawer>
      ))}
    </>
  );
};

export default SupportList;
