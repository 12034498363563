import React, { useState, useEffect } from "react";
import { Box, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import "./index.css";
import TablePagination from "@mui/material/TablePagination";
import AxiosBase from "../../../networkRequest/axiosBase";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OrderDetails from "../../orderDetail";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import moment from "moment";
import Filter from "../../../utils/dateFilter/Filter";
import { toast } from "react-toastify";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";

const Div = styled("div")(unstable_styleFunctionSx);

const CssTextField = styled(TextField)({
  "input#new-menu": {
    padding: 9,
    width: "100%",
  },
});

// main function

const OrdersSwitch = (props) => {
  const [data, setData] = React.useState([]);
  const [users, setUsers] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [formData, setFormData] = useState({
    warehouse_id: "",
    from_rider: "",
    to_rider: "",
    from_date: "",
    to_date: "",
  });

  const getRiderData = (val) => {
    setSpinner(true);
    AxiosBase.get(`/admin/user/rider/${val}`)
      .then((response) => {
        setUsers(response.data.data);
        setSpinner(false);
      })
      .catch((err) => {
        setSpinner(false);
      });
  };

  const getAllWarehouse = () => {
    setSpinner(true);
    AxiosBase.get(`/admin/warehouse?pageNo=0&rowsPerPage=1000000`)
      .then((response) => {
        setWarehouses(response.data.data);
        onChangeInput("warehouse_id", response.data.data[0]._id);
      })
      .catch((err) => {
        setSpinner(false);
      });
  };

  useEffect(() => {
    getAllWarehouse();
  }, []);

  const onChangeInput = (key, val) => {
    if (key == "warehouse_id") {
      getRiderData(val);
    }
    if (
      (key == "from_rider" && formData?.to_rider == val) ||
      (key == "to_rider" && formData?.from_rider == val)
    ) {
      return (
        toast("From and To rider cannot be same"),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    }
    setFormData({
      ...formData,
      [key]: val,
    });
  };

  const save = () => {
    if (
      !formData?.warehouse_id ||
      !formData?.from_rider ||
      !formData?.to_rider ||
      !formData?.from_date ||
      !formData?.to_date
    ) {
      return (
        toast("All fields required"),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    }
    setSpinner(true);
    AxiosBase.post(`/admin/order/switch`, formData)
      .then((response) => {
        setSpinner(false);
        setFormData({
          ...formData,
          from_rider: "",
          to_rider: "",
          from_date: "",
          to_date: "",
        });
        toast("Order switched Successfully.", {
          type: "success",
          autoClose: 2000,
        });
      })
      .catch((err) => {
        setSpinner(false);
        toast("Something went wrong", {
          type: "success",
          autoClose: 2000,
        });
      });
  };

  if (!props?.menu_permissions?.EDIT) {
    return (
      <>
        <div className="no-access-section">
          <div style={{ height: "25vh" }}></div>
          <img
            src="/images/icon/access-denied.jpg"
            alt=""
            style={{ width: "20%" }}
          />
          <br />
          <label className="gr">
            You currently does not have access to this page
          </label>
          <br />
          <label className="gr">Please try again latter</label>
        </div>
      </>
    );
  }

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box>
          <h1 className="merchant_title">Transfer Orders</h1>
        </Box>

        <div className="merchant_list_main_div">
          <div className="merchant_list_inner_title_div">
            <div className="merchant_list_search_filter"></div>
            <h3 className="merchant_list_inner_title">Transfer Order</h3>
          </div>
          {spinner ? (
            <div className="no_data_found loader_data_tbl">
              <CircularProgress style={{ color: "#1058ff" }} />
            </div>
          ) : (
            <>
              <Grid container spacing={2} className="merchant_main_grid">
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className="sep_inpt_sec">
                    <p>Warehouse</p>

                    <div className="inp_sep_div">
                      <Select
                        name="key1"
                        labelId="country-lbl"
                        label=""
                        id="country"
                        value={formData?.warehouse_id || ""}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          onChangeInput("warehouse_id", e.target.value);
                        }}
                      >
                        {warehouses &&
                          warehouses.map((c) => {
                            return (
                              <MenuItem key={c._id} value={c._id}>
                                {c.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}></Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className="sep_inpt_sec">
                    <p>From Rider</p>

                    <div className="inp_sep_div">
                      <Select
                        name="key1"
                        labelId="country-lbl"
                        label=""
                        id="country"
                        value={formData?.from_rider || ""}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          onChangeInput("from_rider", e.target.value);
                        }}
                      >
                        {users &&
                          users.map((c) => {
                            return (
                              <MenuItem key={c._id} value={c._id}>
                                {c.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className="sep_inpt_sec">
                    <p>To Rider</p>

                    <div className="inp_sep_div">
                      <Select
                        name="key1"
                        labelId="country-lbl"
                        label=""
                        id="country"
                        value={formData?.to_rider || ""}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          onChangeInput("to_rider", e.target.value);
                        }}
                      >
                        {users &&
                          users.map((c) => {
                            return (
                              <MenuItem key={c._id} value={c._id}>
                                {c.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className="sep_inpt_sec">
                    <p>From Date</p>

                    <div className="inp_sep_div">
                      <LocalizationProvider
                        className="merchant_list_search_bar"
                        dateAdapter={AdapterDateFns}
                      >
                        <DatePicker
                          className="mui-date-picker"
                          inputFormat="MM/dd/yyyy"
                          value={formData?.from_date}
                          maxDate={formData?.to_date}
                          onChange={(selectedDate) => {
                            console.log(selectedDate);
                            onChangeInput("from_date", selectedDate);
                          }}
                          fullWidth
                          renderInput={(params) => (
                            <CssTextField
                              fullWidth
                              id={"from_date"}
                              name={"from_date"}
                              className="premium_detail_field"
                              variant="outlined"
                              placeholder="From Date"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className="sep_inpt_sec">
                    <p>To Date</p>

                    <div className="inp_sep_div">
                      <LocalizationProvider
                        className="merchant_list_search_bar"
                        dateAdapter={AdapterDateFns}
                      >
                        <DatePicker
                          className="mui-date-picker"
                          inputFormat="MM/dd/yyyy"
                          value={formData?.to_date}
                          minDate={formData?.from_date}
                          onChange={(selectedDate) => {
                            onChangeInput("to_date", selectedDate);
                          }}
                          fullWidth
                          renderInput={(params) => (
                            <CssTextField
                              fullWidth
                              id={"to_date"}
                              name={"to_date"}
                              className="premium_detail_field"
                              variant="outlined"
                              placeholder="To Date"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Grid>
                {!spinner ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ textAlign: "right", marginBottom: "15px" }}
                  >
                    <Link to={`/dashboard`}>
                      <Button
                        className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                        variant="contained"
                      >
                        Cancel
                      </Button>
                    </Link>
                    <Button
                      className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                      variant="contained"
                      onClick={save}
                    >
                      Update
                    </Button>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default OrdersSwitch;
