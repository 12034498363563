import React from "react";
import { useState, useEffect } from "react";
import "./index.css";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import AxiosBase from "../../../../networkRequest/axiosBase";
import Dialog from "@mui/material/Dialog";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "4px",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Detail = (props) => {
  const { SelectedTimeSlot } = props;
  const [timeSlot, setTimeSlot] = React.useState([]);

  useEffect(() => {
    setTimeSlot(SelectedTimeSlot);
  }, [props.SelectedTimeSlot]);

  const addTimeSlot = async () => {
    if (!timeSlot?.title || timeSlot?.title == "") {
      return toast("title is required", {
        type: "error",
        autoClose: 2000,
      });
    }
    props.onSubmitTimeSlot(timeSlot);
  };

  const onChangeInput = (e, key, type = "String") => {
    let val = e.target.value;
    if (type == "Int") {
      val = val.toString().replaceAll("+", "");
      let val1 = val.match(/[0-9]/);
      if (val1) {
        val = isNaN(parseInt(val)) ? "" : parseInt(val);
      } else if (!val) {
        val = "";
      } else {
        return;
      }
    } else {
      val = val.replace(/^\s+/, "").replace("  ", " ");
    }
    timeSlot[key] = val;
    setTimeSlot({
      ...timeSlot,
    });
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <>
      <div style={{ width: "100%" }} className="kyc_detail_ctrl">
        <div className="">
          <div>
            <div className="add_menu_role_star">
              <p>Title:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec timeSlot-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"title_"}
                  placeholder="title"
                  variant="outlined"
                  aria-readonly
                  value={timeSlot.title}
                  onChange={(e) => {
                    onChangeInput(e, "title");
                  }}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>Start Slot:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec timeSlot-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"start_"}
                  placeholder="start"
                  variant="outlined"
                  aria-readonly
                  value={timeSlot.start}
                  onChange={(e) => {
                    onChangeInput(e, "start");
                  }}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>End Slot:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec timeSlot-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"end_"}
                  placeholder="end"
                  variant="outlined"
                  aria-readonly
                  value={timeSlot.end}
                  onChange={(e) => {
                    onChangeInput(e, "end");
                  }}
                />
              </Grid>
            </div>
          </div>
        </div>
        <div style={{marginTop: '15px'}}>
          <div className="timeSlot_edit_drawer_btn_sec">
            <Button
              className="drawer_merchant_cancel_btn"
              variant="contained"
              onClick={props.toggleDrawer("right", false)}
            >
              Cancel
            </Button>

            <Button
              className="drawer_merchant_save_btn"
              variant="contained"
              style={{ marginLeft: "5px" }}
              onClick={() => {
                addTimeSlot();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Detail;
