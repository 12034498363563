import React, { useState ,useEffect} from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";


function NavbarHoc(WrappedComponent) {
  const history = useHistory();

  useEffect(()=>{
    if(WrappedComponent.NavBarData[WrappedComponent.id]){
      WrappedComponent.onGetReports({ParticularData:WrappedComponent.NavBarData[WrappedComponent.id],AllData:WrappedComponent.NavBarData})
    }else{
      // history.push(`/dashboard`)
      WrappedComponent.onGetReports({ParticularData:WrappedComponent.NavBarData[WrappedComponent.id],AllData:WrappedComponent.NavBarData})
    }
  },[WrappedComponent.NavBarData])
  
    return (<>{WrappedComponent.children}</>);
}



  const mapStateToProps = (state) => {
    return {
      NavBarData: state.auth.NavbarAccess,
    };
  };
  
  export default connect(mapStateToProps, null)(NavbarHoc);