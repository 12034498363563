import React, { useState, useEffect } from "react";
import { Box, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import "./index.css";
import TablePagination from "@mui/material/TablePagination";
import AxiosBase from "../../../networkRequest/axiosBase";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import moment from "moment";
import SettingsIcon from "@mui/icons-material/Settings";
import Modal from "@mui/material/Modal";
import MenuDetail from "../menu";

const no_edit_whitelist = [
  "EXPRESS_DELIVERY_BOY",
  "WAREHOUSE",
  "IRON",
  "CLEANING",
  "TRANSPORT",
  "DELIVERY_BOY",
  "ADMIN",
  "SUPER_ADMIN",
];

const no_setting_whitelist = [
  "EXPRESS_DELIVERY_BOY",
  "WAREHOUSE",
  "IRON",
  "CLEANING",
  "TRANSPORT",
  "DELIVERY_BOY",
];

const Div = styled("div")(unstable_styleFunctionSx);

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "2px",
    backgroundColor: "#FEFEFE",
  },
});

// main function

const RoleList = (props) => {
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [search, setSearch] = React.useState("");
  const [spinner, setSpinner] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [menu, setMenu] = React.useState([]);
  const [selectedRole, setSelectedRole] = React.useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    setSpinner(true);
    AxiosBase.get(
      `/admin/role?pageNo=` +
        page +
        `&rowsPerPage=` +
        rowsPerPage +
        `&sortBy=_id&sortOrder=desc&search=` +
        search
    ).then((response) => {
      setData(response.data.data);
      setCount(response.data.count);
      setSpinner(false);
    });
  }, [page, rowsPerPage, count, search]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);

  const getAllMenu = async () => {
    try {
      const res = await AxiosBase.get("/admin/menu?sortOrder=asc");
      setMenu(res?.data?.data);
    } catch (err) {}
  };

  useEffect(() => {
    getAllMenu();
  }, []);

  const handleClose = () => {
    setOpen(false);
    setSelectedRole("");
  };

  const handleOpen = async (id) => {
    setSelectedRole(id);
    setOpen(true);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box>
          <h1 className="merchant_title">Role</h1>
        </Box>

        <div className="merchant_list_main_div">
          <div className="merchant_list_inner_title_div">
            <div className="merchant_list_search_filter">
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                className="merchant_grid_sec"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  placeholder="search"
                  variant="outlined"
                  fullWidth
                  onKeyUp={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <IconButton className="merchant_search_icon">
                          <img src="/images/icon/search.svg" alt="" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <div className="merchant_list_filter_div">
                <img src="/Filter.svg" alt="" />
              </div>
              {props?.menu_permissions?.ADD && (
                <Link className="margin-auto" to={`/role/form/-1`}>
                  <Button
                    className="merchant_list_add_new_merchant"
                    variant="contained"
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                  >
                    Add
                  </Button>
                </Link>
              )}
            </div>
            <h3 className="merchant_list_inner_title">Role List</h3>
          </div>
          {spinner ? (
            <div className="no_data_found loader_data_tbl">
              <CircularProgress style={{ color: "#1058ff" }} />
            </div>
          ) : (
            <>
              <TableContainer className="table_container">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className="data_tbl_head_row">
                    <TableRow style={{ background: "none" }}>
                      <TableCell>Role ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Created At</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="data_tbl_body_row">
                    {data
                      .filter((x) => !no_setting_whitelist.includes(x.name))
                      .map((row, index) => (
                        <TableRow
                          key={row?._id}
                          style={{ background: "none", height: "60px" }}
                        >
                          <TableCell>{row._id}</TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>
                            {moment(row.createdAt).format("DD-MM-YYYY hh:mm A")}
                          </TableCell>
                          <TableCell>{row?.status || "Active"}</TableCell>
                          <TableCell>
                            {!no_edit_whitelist.includes(row.name) &&
                              props?.menu_permissions?.EDIT && (
                                <Link to={`/role/form/${row._id}`}>
                                  <IconButton
                                    className="merchant_tbl_edit_icon"
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls="long-menu"
                                    aria-expanded={open1 ? "true" : undefined}
                                    aria-haspopup="true"
                                  >
                                    <img src="/images/icon/editt.svg" alt="" />
                                  </IconButton>
                                </Link>
                              )}
                            {!no_setting_whitelist.includes(row.name) &&
                              props?.menu_permissions?.EDIT && (
                                <Link>
                                  <IconButton
                                    onClick={() => {
                                      handleOpen(row?._id);
                                    }}
                                  >
                                    <SettingsIcon
                                      style={{
                                        marginTop: "4px",
                                        fontSize: "30px",
                                      }}
                                    />
                                  </IconButton>
                                </Link>
                              )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className="table_pagination"
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <MenuDetail
          selectedRole={selectedRole}
          menu={menu}
          setMenu={setMenu}
          handleClose={handleClose}
        />
      </Modal>
    </>
  );
};

export default RoleList;
