import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "../reducers/auth";

const rootReducer = combineReducers({
  auth: authReducer,
});

export default function reduxStore(initialState) {
  const persistConfig = {
    key: "root",
    storage,
  };
  let composeEnhancer = compose;
  if (process.env.NODE_ENV === "development") {
    composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }

  const store = createStore(
    persistReducer(persistConfig, rootReducer),
    initialState,
    composeEnhancer(applyMiddleware(thunk))
  );
  const persistor = persistStore(store);

  return { store, persistor };
}
