import React from "react";
import { useState, useEffect } from "react";
import "./index.css";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Switch from "@mui/material/Switch";
import AxiosBase from "../../../networkRequest/axiosBase";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Box, Paper } from "@material-ui/core";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import { unstable_styleFunctionSx } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Detail from "./detail";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    value="checkedA"
    inputProps={{ "aria-label": "Switch A" }}
  />
))(({ theme }) => ({
  width: 45,
  height: 22,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(22px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#00C9A5",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 18,
    height: 18,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const Banner = (props) => {
  const { rolePermission } = props;
  const [spinner, setSpinner] = useState(false);
  const [controlDisable, setControlDisable] = useState(true);
  const [currentSaveCancelId, setCurrentSaveCancelId] = useState("");
  const [currentEditId, setCurrentEditId] = useState("");
  const [bannerList, setBannerList] = useState([]);
  const [state, setState] = React.useState({
    right: false,
  });
  const [open, setOpen] = React.useState(false);
  const [selectedBanner, setSelectedBanner] = React.useState({});

  Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  useEffect(() => {
    getBannerData();
  }, []);

  const getBannerData = () => {
    setSpinner(true);
    setControlDisable(true);
    AxiosBase.get(`/admin/banner`).then((res) => {
      setBannerList(res?.data?.data || []);
      setSpinner(false);
    });
  };

  const deleteBanner = (e, row) => {
    setSelectedBanner(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setOpen(false);
    setSpinner(true);
    AxiosBase.delete(`/admin/banner/${selectedBanner?._id}`)
      .then((res) => {
        toast("Banner deleted successfully", {
          type: "success",
          autoClose: 2000,
        });
        cancelBanner({}, selectedBanner?._id);
      })
      .catch((error) => {
        setSpinner(false);
        getBannerData();
        return toast(
          error?.response?.data?.message || "Something went wrong!",
          {
            type: "error",
            autoClose: 2000,
          }
        );
      });
  };

  const cancelBanner = () => {
    setCurrentEditId("");
    getBannerData();
  };
  const addBanner = () => {
    if (currentEditId == "add_new") {
      return;
    }
    const obj = {
      _id: "add_new",
      name: "",
      image_url: "",
      image_name: "",
    };
    setCurrentEditId("add_new");
    setSelectedBanner(obj);
    setState({ ...state, ["right"]: true });
  };

  const saveBanner = (e, _id, banner) => {
    try {
      setSpinner(true);
      if (_id == "add_new") {
        delete banner._id;
        AxiosBase.post("/admin/banner", banner)
          .then((res) => {
            if (res?.status == 200) {
              if (res?.data?._id !== "") {
                setCurrentSaveCancelId("");
                setCurrentEditId("");
                setControlDisable(true);
                cancelBanner({}, selectedBanner._id);
                return toast("A new Banner added successfully.", {
                  type: "success",
                  autoClose: 2000,
                });
              }
            } else {
              setCurrentSaveCancelId("");
              setCurrentEditId("");
              setControlDisable(true);
              setSpinner(false);
              return toast(JSON.stringify(res?.response?.data), {
                type: "error",
                autoClose: 2000,
              });
            }
          })
          .catch((error) => {
            setSpinner(false);
            getBannerData();
            return toast(
              error?.response?.data?.message || "Something went wrong!",
              {
                type: "error",
                autoClose: 2000,
              }
            );
          });
      } else {
        AxiosBase.put("/admin/banner/" + _id, banner)
          .then((res) => {
            if (res?.status == 200) {
              if (res?.data?._id !== "") {
                setCurrentSaveCancelId("");
                setCurrentEditId("");
                setControlDisable(true);
                cancelBanner({}, selectedBanner._id);
                return toast("Banner Updated successfully.", {
                  type: "success",
                  autoClose: 2000,
                });
              }
            } else {
              setCurrentSaveCancelId("");
              setCurrentEditId("");
              setControlDisable(true);
              setSpinner(false);
              return toast(JSON.stringify(res?.response?.data), {
                type: "error",
                autoClose: 2000,
              });
            }
          })
          .catch((error) => {
            setSpinner(false);
            getBannerData();
            return toast(
              error?.response?.data?.message || "Something went wrong!",
              {
                type: "error",
                autoClose: 2000,
              }
            );
          });
      }
    } catch (err) {
      setCurrentSaveCancelId("");
      setCurrentEditId("");
      setControlDisable(true);
      setSpinner(false);
      return toast(err?.response?.data?.message || "Something went wrong!", {
        type: "error",
        autoClose: 5000,
      });
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
    cancelBanner({}, selectedBanner?._id);
  };

  const detailBanner = (anchor, open, selected) => {
    setSelectedBanner(selected);
    setState({ ...state, [anchor]: open });
  };

  const onSubmitBanner = (p) => {
    setState({ ...state, ["right"]: false });
    saveBanner({}, p._id, p);
  };

  const list = (anchor) => (
    <Box
      className="kyc_detail_box"
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 600,
        overflowX: "hidden",
      }}
      role="presentation"
    >
      <List>
        <ListItem className="banner_drower">
          <p>Banner Detail</p>
        </ListItem>
      </List>
      <Divider />
      {
        <>
          <div className="kyc_array">
            {
              <Detail
                SelectedBanner={selectedBanner}
                onSubmitBanner={onSubmitBanner}
                toggleDrawer={toggleDrawer}
              />
            }
          </div>
        </>
      }
    </Box>
  );

  if (!props?.menu_permissions?.VIEW) {
    return (
      <>
        <div className="no-access-section">
          <div style={{ height: "25vh" }}></div>
          <img
            src="/images/icon/access-denied.jpg"
            alt=""
            style={{ width: "20%" }}
          />
          <br />
          <label className="gr">
            You currently does not have access to this page
          </label>
          <br />
          <label className="gr">Please try again latter</label>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="banner_top_div">
        {spinner ? (
          <div className="no_data_found loader_data_tbl">
            <CircularProgress style={{ color: "#1058ff" }} />
          </div>
        ) : (
          <>
            <div className="master-config" style={{ position: "relative" }}>
              <p>Banner List</p>
              {props?.menu_permissions?.ADD && (
                <Link
                  style={{
                    position: "absolute",
                    right: "0",
                    top: "8px",
                  }}
                >
                  <Button
                    className="merchant_list_add_new_merchant"
                    variant="contained"
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                    onClick={(e) => {
                      addBanner();
                    }}
                  >
                    Add
                  </Button>
                </Link>
              )}
            </div>
            <div className="banner_div">
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className="kyc_kyc_input_grid th_heading"
                style={{ width: "33.33%" }}
              >
                <p>{"Name"}</p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className="kyc_kyc_input_grid th_heading"
                style={{ width: "33.33%" }}
              >
                <p>{"Image"}</p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className="kyc_kyc_input_grid th_heading"
                style={{ width: "33.33%" }}
              >
                <p>{"Action"}</p>
              </Grid>
            </div>
            {bannerList.map((elm) => {
              const { _id } = elm;
              return (
                <div key={_id} className="banner_div kyc_row">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                    className="kyc_kyc_input_grid kyc_row_name"
                    style={{ width: "33.33%" }}
                  >
                    <p>{elm.name}</p>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                    className="kyc_kyc_input_grid kyc_row_name"
                    style={{ width: "33.33%" }}
                  >
                    <img
                      src={`${process.env.REACT_APP_API_URL}/api/image/show/${elm.image_url}`}
                      style={{ width: "25%", "margin-top": "15px" }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                    className="kyc_kyc_input_grid kyc_row_name"
                    style={{ width: "33.33%" }}
                  >
                    <div className="kyc_kyc_edit_button_dev kyc_kyc_drawer_save_cancel_btn">
                      {["right"].map((anchor) => (
                        <React.Fragment key={anchor}>
                          {props?.menu_permissions?.EDIT && (
                            <Button
                              className="kyc_detailed_btn"
                              onClick={(e) => {
                                detailBanner(anchor, true, elm);
                              }}
                              variant="contained"
                              title="Detail"
                            >
                              <i className="fa fa-pencil"></i>
                            </Button>
                          )}

                          {props?.menu_permissions?.DELETE && (
                            <Button
                              className="delete_btn"
                              onClick={(e) => {
                                deleteBanner(anchor, elm);
                              }}
                              variant="contained"
                              title="Detail"
                            >
                              <i className="fa fa-trash"></i>
                            </Button>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  </Grid>
                </div>
              );
            })}
          </>
        )}
      </div>
      <div>
        {["right"].map((anchor) => (
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        ))}
        <BootstrapDialog
          className="order_detail_refund_popup"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogContent>
            <Typography
              className="order_detail_refund_popup_title"
              gutterBottom
            >
              {
                <>
                  {" "}
                  <p>Confirm Delete</p>
                  <span>Are you sure want to delete this banner?</span>
                  <div className="order_detail_refund_popup_btn">
                    <Button
                      className="refund_save_btn"
                      variant="contained"
                      autoFocus
                      fullWidth
                      onClick={handleDelete}
                    >
                      Confirm
                    </Button>
                    <Button
                      className="refund_cancel_btn"
                      variant="contained"
                      autoFocus
                      onClick={handleClose}
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              }
            </Typography>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default Banner;
