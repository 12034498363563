import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Paper } from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MobileFriendly from "@mui/icons-material/MobileFriendly";
import Web from "@mui/icons-material/Web";

import EditIcon from "@mui/icons-material/Edit";
import Radio from "@mui/material/Radio";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";

import CopyAll from "@mui/icons-material/CopyAll";
import Tooltip from "@mui/material/Tooltip";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import AxiosBase from "../../../networkRequest/axiosBase";
import "./index.css";

import SettlementList from "./settlement";
import TransactionList from "./transaction";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    padding: 9,
  },
});
const CssTextArea = styled(TextareaAutosize)({
  "input#comment-text": {
    width: "100%",
    height: "4.3rem",
  },
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function AcquirerDetail(props) {
  const { id } = useParams("id");
  const [data, setData] = React.useState({});
  const [settlementAmount, setSettlementAmount] = React.useState(0);
  const [enablemerchant, setenablemerchant] = React.useState(true);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = React.useState("panel1");
  const [commentmandatory, setcommentmandatory] = React.useState(false);
  const [update, setupdate] = useState(false);
  const CHARACTER_LIMIT = 50;
  const ZIP_LIMIT = 6;
  const mobile_LIMIT = 16;

  const handleChange = (panel) => (event) => {
    // setExpanded(panel);
    if (panel == expanded) {
      setExpanded("");
    } else {
      setExpanded(panel);
    }
  };

  const [firsterror, setFirstError] = useState({
    error: false,
    message: "",
  });

  const [numbererror, setNumberError] = useState({
    error: false,
    message: "",
  });

  const [BusinessEmailError, setBusinessEmailError] = useState({
    error: false,
    message: "",
  });

  useEffect(() => {
    AxiosBase.get(`/admin/acquirer/${id}`).then((response) => {
      setData(response.data.data);
    });
  }, []);

  const handleEdit = () => {
    setenablemerchant(false);
  };
  const handleCancel = () => {
    setenablemerchant(true);
  };
  const handleUpdate = async () => {
    if (data.name != "" && firsterror.error) {
      setupdate(!update);
      return (
        toast(firsterror.message.toString()),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    } else if (numbererror.error) {
      setupdate(!update);
      return (
        toast(numbererror.message.toString()),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    } else if (BusinessEmailError.error) {
      setupdate(!update);
      return (
        toast(BusinessEmailError.message.toString()),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    } else if (data.mobile_no == "" || data.email_id == "" || data.name == "") {
      setupdate(!update);
      return (
        toast("Please fill acquirer information"),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    }
    AxiosBase.put(`/admin/acquirer/${id}`, data)
      .then((response) => {
        setupdate(!update);
        return toast("Acquirer Data Updated Successfully.", {
          type: "success",
          autoClose: 2000,
        });
      })
      .catch((err) => {
        console.log(err);
        setupdate(!update);
        return toast(err?.response?.data?.message || "Something went wrong!", {
          type: "error",
          autoClose: 2000,
        });
      });
  };

  if (loading) {
    return (
      <div className="no_data_found loader_data_tbl">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <Box>
        <h1 className="merchant_title">Acquirer</h1>
      </Box>
      <div
        className="merchant_detail_tab_main_page"
        style={{ marginTop: "1rem" }}
      >
        <Accordion
          className="expanded_bar margintop"
          sx={{ mt: "20px" }}
          expanded={expanded === "panel1"}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography onClick={handleChange("panel1")}>
              Basic Information
            </Typography>

            <Button
              onClick={!loading ? handleEdit : () => {}}
              className="merchant_list_add_new_merchant branch_add_btn kycedit"
              variant="contained"
              startIcon={<EditIcon />}
            >
              Edit Acquirer Information
            </Button>
          </AccordionSummary>
          <AccordionDetails>
            {loading ? (
              <div className="no_data_found loader_data_tbl">
                <CircularProgress />
              </div>
            ) : (
              <Grid container spacing={2} className="merchant_main_grid">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Name</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="Name"
                        fullWidth
                        disabled={enablemerchant}
                        value={data?.name}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            name: e.target.value,
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className="create_merchn_inpt_icon">
                              <IconButton color="primary">
                                <PersonOutlinedIcon className="merchant_inpt_icon" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="merchant_grid_sec"
                >
                  <div className="sep_inpt_sec">
                    <p>Code</p>

                    <div className="inp_sep_div">
                      <CssTextField
                        id="new-menu"
                        className="create_merchant_input_feild"
                        variant="outlined"
                        placeholder="Code"
                        fullWidth
                        disabled={enablemerchant}
                        value={data?.code}
                        required
                        onChange={(e) => {
                          setData({
                            ...data,
                            code: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                {enablemerchant ? (
                  <></>
                ) : (
                  <div className="statusbar-acquirer">
                    <Grid container spacing={2} className="merchant_main_grid">
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="merchant_grid_sec"
                      >
                        <div className="sep_inpt_sec ">
                          <p>
                            Is Primary:<span>*</span>
                          </p>
                          <div className="inp_sep_div">
                            <FormControl>
                              <RadioGroup
                                row
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={
                                  !data.is_primary ? "InActive" : "Active"
                                }
                                name="row-radio-buttons-group"
                                onChange={(e) => {
                                  setData({
                                    ...data,
                                    is_primary:
                                      e.target.value == "InActive"
                                        ? false
                                        : true,
                                  });
                                }}
                              >
                                <Tooltip title="Only active acquirer will be able to Initiate payment.">
                                  <FormControlLabel
                                    disabled={enablemerchant}
                                    value="Active"
                                    control={<Radio />}
                                    label="Active"
                                  />
                                </Tooltip>
                                <Tooltip title="Only active acquirer will be able to Initiate payment.">
                                  <FormControlLabel
                                    disabled={enablemerchant}
                                    value="InActive"
                                    control={<Radio />}
                                    label="InActive"
                                  />
                                </Tooltip>
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Grid>
            )}

            {!enablemerchant && !loading ? (
              <div className="merchant_edit_button_dev">
                <Button
                  className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                  variant="contained"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                {props?.menu_permissions?.EDIT && (
                  <Button
                    className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                    variant="contained"
                    onClick={handleUpdate}
                  >
                    Update
                  </Button>
                )}
              </div>
            ) : (
              <></>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          className="expanded_bar"
          sx={{ mt: "20px" }}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography onClick={handleChange("panel3")}>
              Settlement (Settled Amount:- INR {settlementAmount.toFixed(2)})
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SettlementList
              acquirer_id={id}
              menu_permissions={props?.menu_permissions}
              setSettlementAmount={setSettlementAmount}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          className="expanded_bar"
          sx={{ mt: "20px" }}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography onClick={handleChange("panel4")}>
              Transaction
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TransactionList acquirer_id={id} />
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default AcquirerDetail;
