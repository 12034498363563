import React from "react";
import { useState, useEffect } from "react";
import "./index.css";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import AxiosBase from "../../../../networkRequest/axiosBase";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import UploadDoc from "./../../../upload-doc/UploadDoc";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "4px",
  },
});

const CssTextArea = styled(TextareaAutosize)({
  "input#comment-text": {
    width: "100%",
    height: "4.3rem",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Detail = (props) => {
  const { SelectedOffer } = props;
  const [offer, setOffer] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openUpload, setOpenUpload] = React.useState(false);
  const [uploadDoc, setUploadDoc] = React.useState(false);

  useEffect(() => {
    setOffer(SelectedOffer);
  }, [props.SelectedOffer]);

  const hiddenFileInput = React.useRef(null);

  const addOffer = async () => {
    if (!offer?.code || offer?.code == "") {
      return toast("code is required", {
        type: "error",
        autoClose: 2000,
      });
    }
    if (offer?.file) {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const formData = new FormData();
      formData.append("file", offer?.file);
      const res = await AxiosBase.post(`/admin/uploadImages`, formData, config);
      offer.file = null;
      offer.image_url = res?.data?.file_name;
    }
    props.onSubmitOffer(offer);
  };

  const onChangeInput = (e, key, type = "String") => {
    let val = "";
    if (key == "expire_at") {
      val = e;
    } else {
      val = e.target.value;
    }
    if (type == "Int") {
      val = val.toString().replaceAll("+", "");
      let val1 = val.match(/[0-9]/);
      if (val1) {
        val = isNaN(parseInt(val)) ? "" : parseInt(val);
      } else if (!val) {
        val = "";
      } else {
        return;
      }
    } else if (type == "String") {
      val = val.replace(/^\s+/, "").replace("  ", " ");
    }
    offer[key] = val;
    setOffer({
      ...offer,
    });
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const handleFileClick = (event) => {
    setUploadDoc(true);
    setOpenUpload(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenUpload(false);
  };

  const handleUploadDocClose = () => {
    setOpenUpload(false);
    setUploadDoc(false);
  };

  const handleData = (file) => {
    setOpenUpload(false);
    setUploadDoc(false);
    offer.file = file;
    offer.image_url = URL.createObjectURL(file);
    setOffer({
      ...offer,
    });
  };

  const isExpired = (first, last) => {
    if (!last || last == null || last == undefined) return false;
    var start = new Date(first);
    var end = new Date(last);
    end.setDate(end.getDate() + 1);
    return start.getTime() > end.getTime();
  };

  return (
    <>
      <div style={{ width: "100%" }} className="kyc_detail_ctrl">
        <div className="">
          <div>
            <div className="add_menu_role_star">
              <p>Code:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec offer-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"code_"}
                  placeholder="code"
                  variant="outlined"
                  aria-readonly
                  value={offer.code}
                  onChange={(e) => {
                    onChangeInput(e, "code");
                  }}
                />
              </Grid>
            </div>

            <div className="add_menu_role_star">
              <p>Limit(Kg):</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec offer-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"limit_"}
                  placeholder="limit"
                  variant="outlined"
                  aria-readonly
                  value={offer.limit}
                  onChange={(e) => {
                    onChangeInput(e, "limit", "Int");
                  }}
                />
              </Grid>
            </div>

            <div className="add_menu_role_star">
              <p>Single Order Limit(Kg):</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec offer-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"single_limit_"}
                  placeholder="single_limit"
                  variant="outlined"
                  aria-readonly
                  value={offer.single_limit}
                  onChange={(e) => {
                    onChangeInput(e, "single_limit", "Int");
                  }}
                />
              </Grid>
            </div>

            <div className="add_menu_role_star">
              <p>Min Limit(Kg):</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec offer-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"min_limit_"}
                  placeholder="min_limit"
                  variant="outlined"
                  aria-readonly
                  value={offer.min_limit}
                  onChange={(e) => {
                    onChangeInput(e, "min_limit", "Int");
                  }}
                />
              </Grid>
            </div>

            <div className="add_menu_role_star">
              <p>Description:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec offer-field"
              >
                <CssTextArea
                  id="text"
                  variant="outlined"
                  sx={{
                    width: "96%",
                    borderColor: "#c4c4c4",
                    p: "10px",
                    borderRadius: "5px",
                    fontSize: "14px",
                  }}
                  placeholder="description"
                  value={offer?.description}
                  onChange={(e) => onChangeInput(e, "description", "String")}
                  minRows={3}
                />
              </Grid>
            </div>

            <div className="Fees_Margin_input_field add_menu_role_star">
              <p>Expired At:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec date-picker-outer"
              >
                <LocalizationProvider
                  className="merchant_list_search_bar"
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    className="merchant_list_search_bar mui-date-picker"
                    inputFormat="MM/dd/yyyy"
                    value={offer?.expire_at}
                    onChange={(selectedDate) => {
                      if (!isExpired(new Date(), selectedDate)) {
                        onChangeInput(selectedDate, "expire_at", "Date");
                      } else {
                        return toast(
                          "Expired date must be grater then today date.",
                          {
                            type: "error",
                            autoClose: 5000,
                          }
                        );
                      }
                    }}
                    renderInput={(params) => (
                      <CssTextField
                        id={"expire_at_" + offer?._id}
                        name={"expire_at_" + offer?._id}
                        className="merchant_list_search_bar premium_detail_field"
                        variant="outlined"
                        placeholder="expire at"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </div>

            <div className="add_menu_role_star">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec offer-field"
              >
                <div className="">
                  <Button
                    className="offer_upload_btn"
                    onClick={handleFileClick}
                  >
                    <i className="fa fa-upload"></i>&nbsp;&nbsp; Upload Banner
                  </Button>
                </div>
                {offer.image_url && (
                  <img
                    src={
                      offer?.file
                        ? offer.image_url
                        : `${process.env.REACT_APP_API_URL}/api/image/show/${offer.image_url}`
                    }
                    style={{ width: "25%", "margin-top": "15px" }}
                  />
                )}
              </Grid>
            </div>
          </div>
        </div>
        <div>
          <div className="offer_edit_drawer_btn_sec">
            <Button
              className="drawer_merchant_cancel_btn"
              variant="contained"
              onClick={props.toggleDrawer("right", false)}
            >
              Cancel
            </Button>

            <Button
              className="drawer_merchant_save_btn"
              variant="contained"
              style={{ marginLeft: "5px" }}
              onClick={() => {
                addOffer();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      <div>
        <BootstrapDialog
          className="order_detail_refund_popup"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openUpload}
        >
          <DialogContent>
            <Typography
              className="order_detail_refund_popup_title"
              gutterBottom
            >
              {
                <>
                  <UploadDoc
                    open={true}
                    handleDocumentClose={handleUploadDocClose}
                    handleData={handleData}
                    type={offer.type}
                  />
                </>
              }
            </Typography>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default Detail;
