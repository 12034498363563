import React from "react";
import { useState, useEffect } from "react";
import "./index.css";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Switch from "@mui/material/Switch";
import AxiosBase from "../../../networkRequest/axiosBase";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Box, Paper } from "@material-ui/core";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import { unstable_styleFunctionSx } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

//side bar table code
const Div = styled("div")(unstable_styleFunctionSx);

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "5px",
    backgroundColor: "#FEFEFE",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1058ff",
    },
  },
});
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    value="checkedA"
    inputProps={{ "aria-label": "Switch A" }}
  />
))(({ theme }) => ({
  width: 45,
  height: 22,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(22px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#00C9A5",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 18,
    height: 18,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const MobileAppConfig = (props) => {
  const { rolePermission } = props;
  const [spinner, setSpinner] = useState(false);
  const [controlDisable, setControlDisable] = useState(true);

  const [formData, setFormData] = React.useState({
    version_no: "",
    is_force_update: false,
    full_Hold: false,
    order_hold: false,
  });

  Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const getConfigData = () => {
    setSpinner(true);
    setControlDisable(true);
    AxiosBase.get(`/admin/config`).then((res) => {
      if (props?.type == "customer") {
        setFormData({
          version_no: res?.data?.data?.customer_app?.version_no || "",
          is_force_update:
            res?.data?.data?.customer_app?.is_force_update || false,
          full_hold: res?.data?.data?.customer_app?.full_hold || false,
          order_hold: res?.data?.data?.customer_app?.order_hold || false,
        });
      } else {
        setFormData({
          version_no: res?.data?.data?.rider_app?.version_no || "",
          is_force_update: res?.data?.data?.rider_app?.is_force_update || false,
          full_hold: res?.data?.data?.rider_app?.full_hold || false,
          order_hold: res?.data?.data?.rider_app?.order_hold || false,
        });
      }
      setSpinner(false);
    });
  };

  useEffect(() => {
    getConfigData();
  }, []);

  const saveConfig = (e, _id, config) => {
    try {
      setSpinner(true);
      let obj = {};
      if (props.type == "customer") {
        obj.customer_app = formData;
      } else {
        obj.rider_app = formData;
      }
      AxiosBase.post("/admin/config", obj)
        .then((res) => {
          if (res?.status == 200) {
            if (res?.data?._id !== "") {
              setSpinner(false);
              return toast("Updated successfully.", {
                type: "success",
                autoClose: 2000,
              });
            }
          } else {
            setSpinner(false);
            return toast(JSON.stringify(res?.response?.data), {
              type: "error",
              autoClose: 2000,
            });
          }
        })
        .catch((error) => {
          setSpinner(false);
          return toast(
            error?.response?.data?.message || "Something went wrong!",
            {
              type: "error",
              autoClose: 2000,
            }
          );
        });
    } catch (err) {
      setSpinner(false);
      return toast(err?.response?.data?.message || "Something went wrong!", {
        type: "error",
        autoClose: 5000,
      });
    }
  };

  if (!props?.menu_permissions?.VIEW) {
    return (
      <>
        <div className="no-access-section">
          <div style={{ height: "25vh" }}></div>
          <img
            src="/images/icon/access-denied.jpg"
            alt=""
            style={{ width: "20%" }}
          />
          <br />
          <label className="gr">
            You currently does not have access to this page
          </label>
          <br />
          <label className="gr">Please try again latter</label>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="config_top_div">
        {spinner ? (
          <div className="no_data_found loader_data_tbl">
            <CircularProgress style={{ color: "#1058ff" }} />
          </div>
        ) : (
          <>
            <div className="master-config" style={{ position: "relative" }}>
              <p>
                {props?.type == "customer"
                  ? "Customer App Configuration"
                  : "Rider App Configuration"}
              </p>
            </div>
            <div className="config_div" style={{ width: "30%" }}>
              <div className="Fees_Margin_input_field">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  xl={3}
                  className="gatway_second_grid_sec"
                >
                  <p>Version No</p>
                  <CssTextField
                    className="settlement_field"
                    id="new-menu"
                    placeholder="00.0.0"
                    variant="outlined"
                    fullWidth
                    value={formData.version_no}
                    disabled={spinner}
                    onChange={(e) => {
                      formData.version_no = e.target.value;
                      console.log(formData);
                      setFormData({ ...formData });
                    }}
                  />
                  <p>Force Update: </p>
                  <Switch
                    disabled={spinner}
                    checked={formData.is_force_update || false}
                    onChange={(e) => {
                      formData.is_force_update = e.target.checked;
                      setFormData({ ...formData });
                    }}
                  />

                  <p>Force Stop App: </p>
                  <Switch
                    disabled={spinner}
                    checked={formData.full_hold || false}
                    onChange={(e) => {
                      formData.full_hold = e.target.checked;
                      setFormData({ ...formData });
                    }}
                  />

                  <p>Force Stop Order: </p>
                  <Switch
                    disabled={spinner}
                    checked={formData.order_hold || false}
                    onChange={(e) => {
                      formData.order_hold = e.target.checked;
                      setFormData({ ...formData });
                    }}
                  />
                </Grid>
              </div>
              {props?.menu_permissions?.EDIT && (
                <div className="merchant_edit_button_dev edit_button_set">
                  <span>
                    <Button
                      className="merchant_detail_tab_save-cancel_btn"
                      style={{ "background-color": "#00c9a5" }}
                      variant="contained"
                      disabled={spinner}
                      onClick={() => {
                        saveConfig();
                      }}
                    >
                      Save
                    </Button>
                  </span>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MobileAppConfig;
