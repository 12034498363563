import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./index.css";
import TablePagination from "@mui/material/TablePagination";
import AxiosBase from "../../../../networkRequest/axiosBase";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";

// main function

const SalaryList = (props) => {
  const [data, setData] = React.useState([]);
  const [spinner, setSpinner] = React.useState(false);

  useEffect(() => {
    setSpinner(true);
    AxiosBase.get(
      `/admin/salary/${props?.id}/${new Date(
        props?.selectedDate
      ).getFullYear()}`
    ).then((response) => {
      setData(response.data.data || []);
      setSpinner(false);
    });
  }, [props?.selectedDate, props?.spinner]);

  if (spinner) {
    return (
      <div className="no_data_found loader_data_tbl">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div style={{ width: "100%" }}>
        <div className="">
          <TableContainer className="table_container order-table">
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row">
                <TableRow style={{ background: "none" }}>
                  <TableCell>Salary Month</TableCell>
                  <TableCell>Total Salary</TableCell>
                  <TableCell>Bonus Amount</TableCell>
                  <TableCell>Total Leave</TableCell>
                  <TableCell>Payable Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Bull Generated At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="data_tbl_body_row">
                {data.map((row, index) => (
                  <TableRow key={row?.id} style={{ background: "none" }}>
                    <TableCell>{row.salary_month_year}</TableCell>
                    <TableCell>
                      <label>
                        Basic: {row?.salary_detail?.basic.toFixed(2) || 0}
                      </label>
                      <br />
                      <label>
                        HRA: {row?.salary_detail?.hra.toFixed(2) || 0}
                      </label>
                      <br />
                      <label>
                        PF: {row?.salary_detail?.pf.toFixed(2) || 0}
                      </label>
                      <br />
                      <label>
                        TDS: {row?.salary_detail?.tds.toFixed(2) || 0}
                      </label>
                    </TableCell>
                    <TableCell>{row.bonus_amount.toFixed(2) || 0}</TableCell>
                    <TableCell>
                      <label>Paid Leave: {row?.total_paid_leave || 0}</label>
                      <br />
                      <label>
                        UnPaid Leave: {row?.total_unpaid_leave || 0}
                      </label>
                    </TableCell>
                    <TableCell>
                      {(row.total_payable_amount || 0).toFixed(2)}
                    </TableCell>
                    <TableCell>{row.is_paid ? "Paid" : "Un-Paid"}</TableCell>
                    <TableCell>
                      {moment(row.created_at).format("DD MMM YYYY hh:mm A")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default SalaryList;
