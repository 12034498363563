import moment from "moment";

export const getDayLabel = () => {
  return [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ];
};

export const getWeeklyLabel = () => {
  const times = [];
  for (let i = 0; i < 7; i++) {
    times.push(moment().startOf("isoWeek").add(i, "day").format("YYYY-MM-DD"));
  }
  return times;
};

export const getMonthlyLabel = () => {
  const times = [];
  for (let i = 0; i < Number(moment().endOf("month").format("DD")); i++) {
    times.push(moment().startOf("month").add(i, "day").format("YYYY-MM-DD"));
  }
  return times;
};

export const getYearlyLabel = () => {
  return [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
};
