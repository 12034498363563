import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import "./index.css";
import TablePagination from "@mui/material/TablePagination";
import AxiosBase from "../../../../networkRequest/axiosBase";
import OrderDetails from "../../../orderDetail";
import moment from "moment";

// main function

const TransactionList = (props) => {
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [search, setSearch] = React.useState();
  const [state, setState] = React.useState({
    right: false,
    data: {},
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    AxiosBase.get(
      `/admin/transaction/customer/${props?.customer_id}?pageNo=` +
        page +
        `&rowsPerPage=` +
        rowsPerPage +
        `&sortBy=_id&sortOrder=desc`
    ).then((response) => {
      setData(response.data.data);
      setCount(response.data.count);
    });
  }, [page, rowsPerPage, count, search]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const toggleDrawer = (anchor, open, row) => (event) => {
    setState({ ...state, [anchor]: open, data: row });
  };

  const list = (anchor) => <OrderDetails anchor={anchor} data={state?.data} />;

  return (
    <>
      <div style={{ width: "100%" }}>
        <div className="">
          <TableContainer className="table_container order-table">
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row">
                <TableRow style={{ background: "none" }}>
                  <TableCell>Transaction ID</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="data_tbl_body_row">
                {data.map((row, index) => (
                  <TableRow key={row?._id} style={{ background: "none" }}>
                    <TableCell>{row.txn_id}</TableCell>
                    <TableCell>{row.payment_type || "NA"}</TableCell>
                    <TableCell>{(row.amount || 0).toFixed(2)}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>
                      {moment(row.createdAt).format("DD MMM YYYY hh:mm A")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className="table_pagination"
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default TransactionList;
