import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import MainLayout from "../main-layout/main-layout";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        !restOfProps.isAuthenticated ? (
          <Redirect to="/login" />
        ) : restOfProps.isAuthenticated && restOfProps?.isTemporaryPassword ? (
          <Redirect to="/reset" />
        ) : (
          <>
            <MainLayout>
              <Component {...props} />
            </MainLayout>
          </>
        )
      }
    />
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isTemporaryPassword: state.auth.isTemporaryPassword,
  };
};

export default connect(mapStateToProps)(ProtectedRoute);
