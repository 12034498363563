import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Box } from "@material-ui/core";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import "./index.css";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import moment from "moment";
import CopyAll from "@mui/icons-material/CopyAll";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import AxiosBase from "../../networkRequest/axiosBase";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1058ff",
    },
  },
});
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const OrderDetails = (props) => {
  const { anchor } = props;

  const [count, setCount] = React.useState(2);
  const [loadmore, setmore] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const [data, setData] = React.useState(props?.data);
  const [timeline, setTimeline] = React.useState([]);
  const [transaction, setTransaction] = React.useState(null);

  const [downloadLoader, setDownloadLoader] = useState(false);

  useEffect(() => {
    if (!props?.data?._id) return;
    setLoading(true);
    AxiosBase.get(`/admin/order/${props?.data?._id}`).then((response) => {
      setData(response.data.data);
      setTimeline(response.data.orderTimeline);
      setLoading(false);
    });

    AxiosBase.get(`/admin/transaction/one/${props?.data?._id}`).then(
      (response) => {
        setTransaction(response.data.data);
      }
    );
  }, [props?.data]);

  const showmore = (open) => (event) => {
    setmore(!open);
    if (open) {
      setCount(timeline?.length);
    } else {
      setCount(2);
    }
  };

  const markAsPaidClick = () => {
    try {
      setLoading(true);
      AxiosBase.get(`/admin/order/mark-paid/${props?.data?._id}`)
        .then((response) => {
          setData({
            ...data,
            payment_status: "PAID",
          });
          setLoading(false);
          return toast("Payment Updated successfully.", {
            type: "success",
            autoClose: 2000,
          });
        })
        .catch((error) => {
          setLoading(false);
          return toast(
            error?.response?.data?.message || "Something went wrong!",
            {
              type: "error",
              autoClose: 2000,
            }
          );
        });
    } catch (error) {
      setLoading(false);
      return toast(error?.response?.data?.message || "Something went wrong!", {
        type: "error",
        autoClose: 2000,
      });
    }
  };

  const handleDownload = async () => {
    try {
      if (downloadLoader) {
        return;
      }
      setDownloadLoader(true);
      AxiosBase.get(`/admin/order/${props?.data?._id}/download-invoice`)
        .then((response) => {
          const resultByte = response?.data?.pdf?.data;
          var bytes = new Uint8Array(resultByte);
          var blob = new Blob([bytes], { type: "application/pdf" });

          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `Order-Invoice-${data?.order_id}.pdf`;
          link.click();
          setDownloadLoader(false);
          return toast("Invoice downloaded!", {
            type: "success",
            autoClose: 2000,
          });
        })
        .catch((error) => {
          setDownloadLoader(false);
          return toast(
            error?.response?.data?.message || "Something went wrong!",
            {
              type: "error",
              autoClose: 2000,
            }
          );
        });
    } catch (err) {
      console.log(err);
      setDownloadLoader(false);
    }
  };

  return (
    <Box
      className="kyc_detail_box"
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 600,
        overflowX: "hidden",
      }}
      role="presentation"
    >
      {loading ? (
        <div
          style={{ marginTop: "500px" }}
          className="no_data_found loader_data_tbl"
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <List className="order_details_drawer_top_sec_list">
            <ListItem className="order_details_drawer_top_sec">
              <p>Order Details</p>
              <span>CLQ_{data?.order_id}</span>
            </ListItem>
          </List>
          <List className="order_details_drawer_top_sec_list">
            <ListItem className="order_details_drawer_top_sec refund_sec">
              <p className="ade_points_p">
                {data?.amount || 0}
                <span>{data?.currency || "INR"}</span>
              </p>
              {data?.status == "COMPLETED" && (
                <label
                  className={`download-invoice ${
                    downloadLoader ? "download-invoice-loader" : ""
                  }`}
                  onClick={handleDownload}
                >
                  {!downloadLoader ? "Download Invoice" : "Downloading..."}
                </label>
              )}
            </ListItem>
          </List>
          <List className="order_details_drawer_top_sec_list">
            {timeline?.map((data1, index) =>
              index < count ? (
                <ListItem className="order_details_drawer_top_sec Payment_partial_refund">
                  <div className="order_details_Payment_partial_refund">
                    <img src="/Back-01.svg" />
                    <div>
                      <p className="payment_partial_p">{data1?.status}</p>
                      <p className="payment_partial_date">{data1?.message}</p>
                      <p className="payment_partial_date">
                        {moment(data1?.createdAt).format("YYYY/MM/DD HH:mm")}
                      </p>
                    </div>
                  </div>
                </ListItem>
              ) : (
                <> </>
              )
            )}
          </List>
          <List className="order_details_drawer_top_sec_list">
            {loadmore && timeline.length > count ? (
              <ListItem className="order_details_drawer_top_sec Payment_partial_refund">
                <div>
                  <Button
                    className="order_drawer_btn"
                    variant="contained"
                    endIcon={<ArrowDownwardIcon />}
                    onClick={showmore(true)}
                  >
                    Show more
                  </Button>
                </div>
              </ListItem>
            ) : !loadmore ? (
              <>
                <ListItem className="order_details_drawer_top_sec Payment_partial_refund">
                  <div>
                    <Button
                      className="order_drawer_btn"
                      variant="contained"
                      endIcon={<ArrowUpwardIcon />}
                      onClick={showmore(false)}
                    >
                      Show Less
                    </Button>
                  </div>
                </ListItem>
              </>
            ) : (
              ""
            )}
          </List>

          <div className="order_details_drawer_cards_body_div">
            <List className="order_details_drawer_cards_sec">
              <ListItem>
                <div className="order_details_drawer_cards_title_div">
                  <p>
                    Basic Details (Payment Status:{" "}
                    {data?.payment_status || "UNPAID"})
                    {(!data?.payment_status ||
                      data?.payment_status == "UNPAID") &&
                      data?.amount &&
                      props?.menu_permissions?.EDIT && (
                        <Button
                          className="order_drawer_btn"
                          variant="contained"
                          onClick={markAsPaidClick}
                        >
                          mark as paid
                        </Button>
                      )}
                  </p>
                </div>
              </ListItem>
              <ListItem>
                <div className="order_details_drawer_cards_body_inner">
                  <div className="card_data">
                    <p>Pick Date</p>
                    <span>{moment(data?.pick_date).format("DD-MMM-YYYY")}</span>
                  </div>
                  <div className="card_data">
                    <p>Pick Time</p>
                    <span>{data?.pick_time_slot}</span>
                  </div>
                  <div className="card_data">
                    <p>Quantity</p>
                    <span>{data?.quantity || 0} Items</span>
                  </div>
                </div>
              </ListItem>
              <ListItem>
                <div className="order_details_drawer_cards_body_inner">
                  <div className="card_data">
                    <p>Drop Date</p>
                    <span>{moment(data?.drop_date).format("DD-MMM-YYYY")}</span>
                  </div>
                  <div className="card_data">
                    <p>Drop Time</p>
                    <span>{data?.drop_time_slot}</span>
                  </div>
                  <div className="card_data">
                    <p>Weight</p>
                    <span>{data?.weight || 0} Kg</span>
                  </div>
                </div>
              </ListItem>
              <ListItem>
                <div className="order_details_drawer_cards_body_inner">
                  <div className="card_data">
                    <p>Address</p>
                    <span>{data?.address || "NA"}</span>
                  </div>
                  <div className="card_data">
                    <p>Type</p>
                    <span>{data?.type}</span>
                  </div>
                  <div className="card_data">
                    <p>Tag</p>
                    <span>{data?.tag_no || "NA"}</span>
                  </div>
                </div>
              </ListItem>
              <ListItem>
                <div className="order_details_drawer_cards_body_inner">
                  <div className="card_data">
                    <p>Warehouse</p>
                    <span>{data?.warehouse?.name || "NA"}</span>
                  </div>
                  <div className="card_data detail-warehouser-order">
                    <p>Warehouse Address</p>
                    <span>{data?.warehouse?.address}</span>
                  </div>
                </div>
              </ListItem>
              {data?.comment && (
                <ListItem>
                  <div className="order_details_drawer_cards_body_inner">
                    <div className="card_data comment-order">
                      <p>Customer Message</p>
                      <span>{data.comment}</span>
                    </div>
                  </div>
                </ListItem>
              )}
              {transaction && (
                <ListItem>
                  <div className="order_details_drawer_cards_body_inner">
                    <div className="card_data comment-order">
                      <p>Transaction Id</p>
                      <span>{transaction.txn_id}</span>
                    </div>
                  </div>
                </ListItem>
              )}
            </List>
          </div>

          <div className="order_details_drawer_cards_body_div">
            <List className="order_details_drawer_cards_sec">
              <ListItem>
                <div className="order_details_drawer_cards_title_div">
                  <p>Service Details</p>
                </div>
              </ListItem>
              {data?.services &&
                data.services.map((item) => {
                  return (
                    <ListItem>
                      <div
                        className="order_details_drawer_cards_body_inner"
                        style={{ width: "100%" }}
                      >
                        <div className="card_data" style={{ width: "25%" }}>
                          <p>Name</p>
                          <span>{item?.name}</span>
                        </div>
                        <div className="card_data" style={{ width: "25%" }}>
                          <p>Quantity</p>
                          <span>{item?.quantity || 0} Items</span>
                        </div>
                        <div className="card_data" style={{ width: "25%" }}>
                          <p>Weight</p>
                          <span>
                            {Number(item?.weight || 0).toFixed(2) || 0} Kg
                          </span>
                        </div>
                        <div className="card_data" style={{ width: "25%" }}>
                          <p>Amount</p>
                          <span>INR {item?.amount || 0}</span>
                        </div>
                      </div>
                    </ListItem>
                  );
                })}
            </List>
          </div>

          <div className="order_details_drawer_cards_body_div">
            <List className="order_details_drawer_cards_sec">
              <ListItem>
                <div className="order_details_drawer_cards_title_div">
                  <p>Customer Details</p>
                </div>
              </ListItem>
              <ListItem>
                <div className="order_details_drawer_cards_body_inner">
                  <div className="card_data">
                    <p>Customer Name</p>
                    <span>{data?.customer?.name || "Not Assigned"}</span>
                  </div>
                  <div className="card_data">
                    <p>Email ID</p>
                    <span>{data?.customer?.email}</span>
                  </div>
                  <div className="card_data">
                    <p>Phone Number</p>
                    <span>
                      {data?.customer?.mobile_no
                        ? "+" + data?.customer?.mobile_no
                        : ""}
                    </span>
                  </div>
                </div>
              </ListItem>
            </List>
          </div>

          <div className="order_details_drawer_cards_body_div">
            <List className="order_details_drawer_cards_sec">
              <ListItem>
                <div className="order_details_drawer_cards_title_div">
                  <p>Rider Details</p>
                </div>
              </ListItem>
              <ListItem>
                <div className="order_details_drawer_cards_body_inner">
                  <div className="card_data">
                    <p>Rider Name</p>
                    <span>{data?.rider?.name || "NA"}</span>
                  </div>
                  <div className="card_data">
                    <p>Email ID</p>
                    <span>{data?.rider?.email_id}</span>
                  </div>
                  <div className="card_data">
                    <p>Phone Number</p>
                    <span>+{data?.rider?.mobile_no}</span>
                  </div>
                </div>
              </ListItem>
            </List>
          </div>
        </>
      )}
    </Box>
  );
};

export default OrderDetails;
